import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin-service';
import { SatDatabaseService } from '@shared/services/sat-database.service';

@Component({
  selector: 'app-crud-servtec',
  templateUrl: './crud-servtec.component.html',
  styleUrls: ['./crud-servtec.component.scss']
})
export class CrudServtecComponent implements OnInit {
  componentToLoad = 'app-crud-view-servtec'
  areasOriginal: any = [];
  areasArr: any = []
  filteredAreas: any = [];
  searchTema = '';
  spinner = true;
  spinnerVinculos = false;
  constructor(private adminService: AdminService, private satDatabaseService: SatDatabaseService) { }

  ngOnInit(): void {
    this.adminService.logadoIsAdmin$.subscribe(isAdmin => {
      console.log('isAdmin', isAdmin)
      // this.getAreasTemas()
    });


  }
  exportToPDF(): void {
    const dataToExport = this.areasArr; // Use your data source
    console.log(dataToExport)
    this.adminService.generatePDFWithoutArea(dataToExport);
  }

}

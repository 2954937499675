import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';  // Import HttpClient for server request
import { MatSnackBar } from '@angular/material/snack-bar';  // Optional for notifications
import { AdminDatabaseService } from '../services/admin-database.service';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin-service';


@Component({
  selector: 'app-crud-insert-documento',
  templateUrl: './crud-insert-documento.component.html',
  styleUrls: ['./crud-insert-documento.component.scss']
})
export class CrudInsertDocumentoComponent implements OnInit {
  pastedData: string = '';  // Holds the raw pasted data
  dataList: Array<any> = [];  // Holds the parsed data as array of objects
  spinner: any = false
  allDocumento: Array<any> = [];
  duplicatedDocumentos: Array<any> = [];
  constructor(private adminDatabaseService: AdminDatabaseService, private snackBar: MatSnackBar, private router: Router,
    private adminService: AdminService) { }

  ngOnInit(): void {
    this.getAllDocumentos()
  }

  voltar() {
    this.router.navigateByUrl('/admin');
  }




  limparLista() {
    this.dataList = []
    this.duplicatedDocumentos = []
    this.pastedData = ''
  }
  // Method to remove an item from the list
  removeItem(index: number): void {
    // Get the service name to be removed from dataList
    const serviceName = this.dataList[index].dostNmDocumento.trim().toLowerCase();

    // Remove from dataList
    this.dataList.splice(index, 1);

    // Split pastedData into rows, filter out empty rows
    const rows = this.pastedData.split('\n').filter(row => row.trim() !== '');

    // Find the index of the corresponding service in pastedData and remove it
    const rowIndex = rows.findIndex(row => row.trim().toLowerCase() === serviceName);
    if (rowIndex > -1) {
      rows.splice(rowIndex, 1); // Remove the corresponding line
    }

    // Update pastedData with the remaining rows
    this.pastedData = rows.join('\n');
  }


  onPasteData(): void {
    if (this.pastedData) {
      // Step 1: Define a mapping of special characters to their replacements
      const replaceSpecialCharacters = (text: string): string => {
        return text
          .replace(/–/g, '-')      // Replace en dash with hyphen
          .replace(/—/g, '-')      // Replace em dash with hyphen
          .replace(/‘|’|‚|‛/g, "'") // Replace various quotes with a single quote
          .replace(/“|”|„|‟/g, '"') // Replace various double quotes with standard double quote
          .replace(/•|●/g, '-')    // Replace bullet points with hyphen (or any preferred character)
          .replace(/…/g, '...');   // Replace ellipsis with three dots
      };

      // Step 2: Split by lines, clean, replace special characters, and filter
      let rows = this.pastedData.split('\n')
        .map(row => row.trim())
        .filter(row => row !== '"')                   // Remove lines that are exactly a double quote
        .map(row => row.replace(/^"/, ''))            // Remove leading quotes from the start of any line
        .filter(row => row !== '')                    // Remove any empty lines
        .map(row => replaceSpecialCharacters(row));   // Apply special character replacement

      // Step 3: Ensure uniqueness while preserving the original case
      const uniqueData = Array.from(new Set(rows));

      // Step 4: Update the text area with cleaned, unique data
      this.pastedData = uniqueData.join('\n');  // Join by newline to preserve separate lines

      // Step 5: Map the cleaned data back to the required format for dataList
      this.dataList = uniqueData.map(name => ({
        "dostDk": 0,
        "dostDsDocumento": name,  // Original name with replaced characters
        "dostNmDocumento": name,  // Original name with replaced characters
        "dtstDk": 0,
        "dtstInObrigatorio": "S"
      }));

      // Step 6: Filter duplicates after populating dataList
      this.filterDuplicatedDocumentos();
    }
  }




  filterDuplicatedDocumentos(): void {
    // Reset duplicatedDocumentos before filtering
    this.duplicatedDocumentos = [];

    // Normalize the document names by trimming, converting to lowercase, and removing spaces for comparison purposes
    const existingDocumentNames = new Set(
      this.allDocumento.map(documento => this.normalizeString(documento.dostNmDocumento))
    );

    // Filter out duplicates from dataList and separate them into duplicatedDocumentos
    this.dataList = this.dataList.filter(documento => {
      const normalizedDocumentName = this.normalizeString(documento.dostNmDocumento);  // Normalize for comparison

      if (existingDocumentNames.has(normalizedDocumentName)) {
        // If the normalized document name already exists, add to duplicatedDocumentos
        this.duplicatedDocumentos.push(documento);
        return false;  // Exclude from dataList (because it's a duplicate)
      }
      return true;  // Keep the document if it's not a duplicate
    });

    console.log('Filtered dataList (without duplicates):', this.dataList);
    console.log('Duplicated documents:', this.duplicatedDocumentos);
  }

  // Helper function to normalize string: trim, lowercase, and remove all spaces
  normalizeString(str: string): string {
    return str.replace(/\s+/g, '').toLowerCase();  // Remove spaces and convert to lowercase
  }




  getAllDocumentos() {
    this.spinner = true
    this.adminDatabaseService.getAllDocumento().subscribe(
      (response) => {
        this.allDocumento = response;  // Assign the response (array) to dataList
        console.log('allDocumento:', this.allDocumento);  // Debugging - Log the data to console

        this.spinner = false

      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false
      }

    );
  }





  // Method to send the data to the server
  createDocumentoList(action: any): void {
    let TMST_DK
    let PSTC_DK
    if (this.dataList.length > 0) {
      console.log(this.dataList)
      if (action === "INSERT") {
        PSTC_DK = 0
        TMST_DK = 0
      }

      this.adminDatabaseService.CruDocServTecList(this.dataList, TMST_DK, PSTC_DK, action).subscribe(
        (event: any) => {
          console.log('Documentos gravados:', event);
          if (action === 'INSERT') {
            console.log("event", event)
            this.snackBar.open('Documentos criados com sucesso.', '', { duration: 3000 });
            this.getAllDocumentos()
            //    this.router.navigateByUrl('/crud-view-documento');
            this.limparLista()
          }
        },
        (error) => {
          //   this.voltar()
          console.error('Error creating Documentos:', error);
          this.snackBar.open('Erro', error.error.errTxt, { duration: 3000 });
          //this.satState = { status: 'error', error: error.message, sateDk: null };
        }
      );
    } else {
      this.snackBar.open('No data to send!', 'Close', { duration: 3000 });
    }
  }
}


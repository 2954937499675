import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { AuthService, User, LoginService } from '@core/authentication';

@Component({
  selector: 'app-user',
  template: `
    <button
      class="matero-toolbar-button matero-avatar-button"
      mat-button
      style="cursor: text !important;"
      [matMenuTriggerFor]="null"
    >
      <img class="matero-avatar" [src]="this.avatarIMG" width="32" alt="avatar"  onError="this.src='./assets/images/generic_user.png';"  />
      <span class="matero-username" style=" text-transform: capitalize;" fxHide.lt-sm>{{ userName }}</span>
    </button>

    <!-- <mat-menu #menu="matMenu">
      <button routerLink="/profile/overview" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'user.profile' | translate }}</span>
      </button>
      <button routerLink="/profile/settings" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>{{ 'user.settings' | translate }}</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'user.logout' | translate }}</span>
      </button>
    </mat-menu> -->
  `,
})
export class UserComponent implements OnInit {
  user!: User;
  avatarIMG: any
  userName = ''
  constructor(private router: Router, private auth: AuthService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.auth.user().subscribe(user => {
      //   console.log("useruseruseruseruser", user.tok)
      this.user = user.tok
      this.userName = user.tok.name;
      this.avatar()
    }).unsubscribe();
  }

  avatar() {
    setTimeout(() => {

      this.loginService.getAvatar().subscribe((avt: any) => {
        //  console.log(avt)
        //  console.log('data:image/jpeg;base64,' + avt.data.foto)
        return this.avatarIMG = 'data:image/jpeg;base64,' + avt.data.foto
        //let avatarStrg = `Content Type:image/png ${contentType}`
      })
    }, 200);
    setTimeout(() => {
      this.loginService.getUser().subscribe((usr: any) => {
        console.log('usr', usr)

      })
    }, 200);

  }

  logout() {
    this.auth.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
  }
}

import { Component, OnInit, ViewChild, PipeTransform, Pipe, } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";





@Component({
  selector: 'app-duvidas',
  templateUrl: './duvidas.component.html',
  styleUrls: ['./duvidas.component.scss']
})





export class DuvidasComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: any = MatAccordion;
  @Pipe({ name: 'safe' })

  embedURL: string = "https://gate-portal-95b207ee53f20-155f4df9c1972.webflow.io/"
  faqArr = [
    { n: 1, grupo: "Sem categoria", pergunta: 'Ainda preciso usar o SEI para solicitar as análises do GATE?', resposta: 'Não. Todas as solicitações de análise técnica (SAT) elaboradas a partir do dia 29/04/2024 devem ser encaminhadas ao GATE através do novo Portal de Serviços. As solicitações via SEI serão inadmitidas.' },
    { n: 2, grupo: "Sem categoria", pergunta: 'O SEI ainda será usado para o atendimento das SATs?', resposta: 'Em parte. Até que haja o desenvolvimento completo do novo Portal de Serviços do GATE, o SEI ainda servirá para tramitação interna do procedimento entre admissibilidade e núcleo técnico, bem como para o envio da informação técnica (IT). Além disso, no caso de eventual inadmissibilidade da SAT, o procedimento volta a tramitar pelo SEI. Em outras palavras, por enquanto, o Portal de Serviços é apenas a nova porta de entrada das solicitações. A tendência é que, a médio prazo, o SEI seja abandonado de vez.' },
    { n: 3, grupo: "Sem categoria", pergunta: 'Como faço para logar no novo Portal de Serviços do GATE?', resposta: 'O login e senha são os mesmos utilizados para acessar o SCA, sistema que dá acesso, por exemplo, ao contracheque.' },
    { n: 4, grupo: "Sem categoria", pergunta: 'O procedimento para o qual eu gostaria de atendimento não aparece na lista. O que fazer?', resposta: 'É provável que você tenha digitado o número do procedimento de forma equivocada. O número do procedimento deve ser preenchido corretamente, segundo o padrão do MGP, SEI ou Integras. Para garantir, é interessante copiar e colar o número. ' },
    { n: 5, grupo: "Sem categoria", pergunta: 'O fato gera impacto regional e/ou atinge mais de um município sob minha atribuição. Qual deles eu devo selecionar?', resposta: 'No caso de impacto regional, todos os municípios atingidos devem ser selecionados. Em caso de impacto estadual, basta selecionar "Estado do Rio de Janeiro".' },
    { n: 6, grupo: "Sem categoria", pergunta: 'No caso de complementação de IT anterior, como faço para vincular o meu pedido ao documento que foi anteriormente produzido pelo GATE?', resposta: 'Nesse caso, basta selecionar "sim" para a pergunta e, após, digitar o número da IT. Esse passo só funciona para informações técnicas produzidas após outubro de 2018. Caso a IT a ser complementada seja de antes deste marco temporal, clique em "não" para continuar e informe o número da IT a ser complementada no objetivo da Solicitação.' },
    { n: 7, grupo: "Sem categoria", pergunta: 'Não encontro o serviço que preciso na página de temas e serviços. E agora?', resposta: 'Primeiro você deve identificar se o serviço desejado não está em outra área ou tema. Caso realmente não encontre, significa que este não é um serviço prestado pelo GATE. Isso pode se dar por duas razões: i) O GATE não tem capacidade técnica para prestá-lo; ii) o serviço possui um objeto que se caracteriza como genérico, excessivamente amplo e/ou com baixa relação de custo-efetividade (artigo 16, §1º, da OS nº 01/23 do GATE).' },
    { n: 8, grupo: "Sem categoria", pergunta: 'Existe algum serviço coringa?', resposta: 'Não. Todos os serviços que são prestados pelo GATE estão compilados na página correspondente ou no catálogo de serviços, onde também podem ser encontradas descrições de cada um deles, e a lista de documentos obrigatórios e adicionais. Em breve, este material também contemplará os quesitos sugeridos para cada serviço.' },
    { n: 9, grupo: "Sem categoria", pergunta: 'Na minha investigação eu preciso de dois ou mais serviços prestados pelo GATE. Posso selecionar mais de um?', resposta: 'Sim. Caso precise de mais de um serviço relacionado ao mesmo objeto de investigação (ex: superfaturamento e sobrepreço), você deve selecionar todos eles na página de temas e serviços. Isso porque para cada serviço há uma lista de documentos obrigatórios que deve ser preenchida para que o GATE possa atender a sua solicitação.' },
    { n: 10, grupo: "Sem categoria", pergunta: 'O que são documentos obrigatórios?', resposta: 'São aqueles documentos sem os quais a equipe técnica do GATE não consegue produzir a IT. Em outras palavras, eles são imprescindíveis para a correta e completa análise técnica.' },
    { n: 11, grupo: "Sem categoria", pergunta: 'Não possuo todos os documentos obrigatórios listados. Ainda assim posso enviar a SAT ao GATE?', resposta: 'Não. O envio de SATs sem a documentação obrigatória completa e em desacordo com a forma de envio prevista nos Protocolos de Gestão resultará no indeferimento da solicitação.' },
    { n: 12, grupo: "Sem categoria", pergunta: 'Mesmo após as requisições, o investigado e/ou ente federativo me informou que não possui o documento listado. O que fazer?', resposta: 'Nesse caso, a ausência do documento obrigatório deve estar devidamente justificada no objetivo da solicitação. Diante da justificativa apresentada, a equipe técnica do GATE avaliará caso a caso a possibilidade de se atender parcialmente - mas ainda com alguma utilidade para o solicitante - a SAT. Caso seja possível, a SAT será atendida conforme a negociação do escopo com o solicitante; caso contrário, a SAT será devolvida com a devida informação. De qualquer forma, para conseguir avançar na elaboração da SAT, é preciso fazer o upload ou indicar o index de pelo menos um documento obrigatório.' },
    { n: 13, grupo: "Sem categoria", pergunta: 'O que são documentos adicionais?', resposta: 'São aqueles documentos que, muito embora a análise técnica seja possível na sua ausência, o envio deles permitirá à equipe técnica do GATE produzir uma IT de forma mais célere, eficiente e completa. Em outras palavras, eles não são imprescindíveis para análise técnica, mas seu envio ajuda o trabalho dos técnicos periciais.' },
    { n: 14, grupo: "Sem categoria", pergunta: 'Não possuo todos os documentos adicionais listados. Ainda assim posso enviar a SAT ao GATE?', resposta: 'Sim. Caso o solicitante não possua todos os documentos adicionais listados, é possível o envio da SAT ao GATE sem qualquer prejuízo. Lembre-se apenas que o envio desta categoria de documentos pode resultar em uma IT mais completa e mais eficiente para sua investigação.' },
    { n: 15, grupo: "Sem categoria", pergunta: 'O que é complementaridade?', resposta: 'A natureza complementar das atividades do GATE está prevista nos artigos 1º e 9º, inciso II, da Resolução GPGJ nº 2.197/18. Significa dizer que o GATE não se substitui aos órgãos de execução; aos demais órgãos internos do MPRJ; aos órgãos técnicos e/ou com poder de polícia ou regulatório do Poder Executivo ou demais órgãos de controle (interno ou externo); ou aos profissionais cuja prestação de serviços técnicos for necessária para que os investigados cumpram seus deveres legais ou obrigações assumidas perante o MPRJ. Sendo assim, o GATE nunca será o primeiro a atuar no caso concreto. Sua análise sempre irá se basear na manifestação técnica do órgão dotado de poder de polícia, investigativo ou regulatório, inclusive órgãos de controle interno e externo; no laudo pericial; ou na manifestação técnica produzida pelo perito oficial do juízo.' },
    { n: 16, grupo: "Sem categoria", pergunta: 'O órgão com poder de polícia ou regulatório sobre os fatos submetidos à análise não atendeu às minhas requisições. Estou impedido de pedir ajuda ao GATE?', resposta: 'Em casos excepcionais, devidamente justificados e documentados pelo solicitante, e de alta prioridade segundo os critérios fixados pelo GATE em protocolos próprios, a Coordenação poderá dispensar o cumprimento do requisito da complementaridade. Contudo, em se tratando de omissão ou de negligência do órgão competente para realizar a inspeção, a vistoria, ou para apresentar ao MPRJ os demais elementos indispensáveis à investigação, a ausência de comprovação da atuação estruturante ou sancionatória por parte do órgão solicitante, voltada a superar o quadro de omissão, poderá gerar efeitos negativos na priorização da solicitação ou, em último caso, a inadmissibilidade de solicitações futuras, da mesma natureza.' },
    { n: 17, grupo: "Sem categoria", pergunta: 'Onde indico o prazo para atendimento para uma SAT que possui prazo processual, prazo para conclusão do inquérito ou data limite para cumprimento?', resposta: 'Na página "atendimento prioritário" existe as opções legais de prioridade; e, em seguida, as hipóteses de atendimento prioritário, tais como prazo processual, prazo prescricional, prazo para conclusão do inquérito ou situação excepcional que justifique a data limite. Aqui é o momento adequado para informar o prazo; ele NÃO deve ser informado no campo da dúvida técnica.' },
    { n: 18, grupo: "Sem categoria", pergunta: 'Meu processo/procedimento precisa de atendimento prioritário. Contudo, na página de indicação do prazo para o qual preciso da resposta do GATE, não consigo selecionar a data que desejo. O que fazer?', resposta: 'Não é possível selecionar data que represente um prazo inferior a 10 (dez) dias úteis. Isso porque entende-se que este é o prazo mínimo para que a equipe do GATE consiga atender à solicitação, sem prejuízo da inadmissibilidade em hipóteses com prazo superior que, a critério da Coordenação do GATE, se mostrem inexequíveis no tempo indicado pelo solicitante.' },
    { n: 19, grupo: "Sem categoria", pergunta: 'Ao concluir o preenchimento da SAT, verifiquei que há um equívoco. Posso alterá-la antes de enviá-la ao GATE?', resposta: 'Sim. Na última página de preenchimento há um resumo de tudo que foi solicitado. Caso se verifique algum equívoco, basta selecionar a seção correspondente e editá-la antes de enviar a SAT ao GATE.' },
    { n: 20, grupo: "Sem categoria", pergunta: 'Ao final da SAT preenchida pelo servidor, existe o botão "criar SAT". O que significa?', resposta: 'Quando o usuário logado for servidor, ao final da SAT é necessária enviá-la para aprovação e assinatura do Membro. Sendo assim, o botão "criar" significa que a SAT foi criada e está pronta para aprovação. Em breve, alteraremos o disposto no botão para facilitar o entendimento do usuário.' },
    { n: 21, grupo: "Sem categoria", pergunta: 'O Membro é avisado pelo sistema de que a SAT está pronta para assinatura?', resposta: 'Não. Por enquanto, cabe ao servidor que preencheu a SAT avisar, da forma que entender mais adequada, ao Membro que a SAT está disponível na "lista de SATs" e pronta para assinatura.' },
    { n: 22, grupo: "Sem categoria", pergunta: 'O que é a lista de SATs?', resposta: 'Lista de SATs é a página que reúne todas as SATs vinculadas de alguma forma ao usuário logado no sistema. No caso de Membro, é aqui que ele aprova e envia a SAT ao GATE, ou realiza as edições necessárias. Em breve, será aqui que haverá o acompanhamento do status de produção da informação técnica.' },
    { n: 23, grupo: "Sem categoria", pergunta: 'O que significa quando a SAT está "aguardando aprovação"?', resposta: 'Significa que, apesar de devidamente preenchida pelo servidor do órgão de execução, ainda não houve assinatura do Membro responsável. A autenticação pelo Membro é imprescindível para o atendimento da SAT, já que consta como requisito na Resolução GPGJ nº 2.197/18. Somente após a assinatura do Membro é que a SAT é enviada a este grupo especializado.' },
    { n: 24, grupo: "Sem categoria", pergunta: 'Se o próprio Membro preencher a SAT também é necessária a sua assinatura?', resposta: 'Não. Caso o login e senha de acesso reconhecerem que é um Membro logado, ao elaborar a SAT e clicar em "enviar SAT" ela é automaticamente enviada a este órgão sem a necessidade de nenhuma outra ação.' },
    { n: 25, grupo: "Sem categoria", pergunta: 'É possível que haja um Membro solicitante, mas outro assine a SAT?', resposta: 'Sim. Mesmo que a determinação de elaboração da SAT no procedimento seja feita por um Membro e este conste como Membro solicitante, é possível que a assinatura e autenticação da SAT seja feita por outro. Nesse caso, quem assinou a SAT figurará como Membro solicitante/responsável pela solicitação.' },
    { n: 26, grupo: "Sem categoria", pergunta: 'Como posso consultar o andamento da SAT?', resposta: 'O andamento da SAT ainda não está automatizado. Portanto, caso o solicitante deseje consultar o andamento da SAT, é necessário entrar em contato com a secretaria do GATE por e-mail, a fim de obter as informações desejadas.' },
  ]

  filteredFAQArr = this.faqArr;
  searchTerm: string = '';


  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
  }
  transform(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  filterFAQ(): void {
    if (this.searchTerm.trim() !== '') {
      const searchTermLC = this.searchTerm.toLowerCase().trim();
      this.filteredFAQArr = this.faqArr.filter(item =>
        item.pergunta.toLowerCase().includes(searchTermLC) ||
        item.resposta.toLowerCase().includes(searchTermLC)
      );
    } else {
      this.filteredFAQArr = this.faqArr;
    }
  }

  clearSearch(): void {
    this.searchTerm = '';
    this.filterFAQ();
  }

}

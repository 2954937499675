<div>


  <div class="matero-page-header-inner" style="    margin-top: 30px;">
    <h3 class="matero-page-title criterios-title">Critérios de prioridade Tipo A</h3>
  </div>

  <p>
    O tipo de priorização A será utilizado para a classificação das solicitações de análise
    destinadas a todos os temas, exceto os temas do tipo B e C.
  </p>

  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>news</mat-icon></span>
    <div>Nota Geral de Prioridade (NG) Tipo A.</div>
  </div>

  <p>
    Será definida pela divisão entre os índices de impacto e de custo.
  </p>

  <div class="formula">
    <i> NG<sub>A</sub></i> = (<i>índicedeimpacto<sub>A</sub></i>) /
    (<i>índicedecusto<sub>A</sub></i>)


  </div>
  <p>A nota assumirá um número entre 21 e 100, onde a maior nota indica a maior prioridade.</p>


  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>groups</mat-icon></span>
    <div>Índice de Custo Tipo A</div>
  </div>
  <p>O Índice de Custo é formado pela soma dos indicadores de custo de análise.</p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">Para calcular o índice de custo das análises
      no âmbito dos temas Tipo A, usa-se como indicadores a estimativa do tempo de análise e a
      equipe prevista. A nota do Índice de Custo será calculada pela soma simples das notas dos
      indicadores.
    </span>
  </div>

  <p>O Índice de Custo terá uma nota final que variará entre 1 a 1,30.</p>




  <p><strong>Indicadores de Custo de Análise</strong></p>

  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoADialog('tipo_A_tempo_analise')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Tempo de análise</span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoADialog('tipo_A_equipe_analise')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Equipe de análise</span>
  </div>









  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>campaign</mat-icon></span>
    <div>Índice de Impacto Tipo A</div>
  </div>
  <p>O Índice de Impacto é formado pela soma de três indicadores de impacto de análise. </p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">Os indicadores buscam traduzir a extensão do
      impacto do objeto de análise, a magnitude desse impacto e a urgência da análise. As notas de
      cada indicador foram definidas em alinhamento à missão, à visão e aos valores da instituição,
      estampados no seu planejamento estratégico para o período de 2020 a 2027.</span>
  </div>

  <p>O Índice de Impacto terá uma nota final que variará entre 28 e 100.</p>




  <p><strong>Indicadores de Impacto</strong></p>

  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoADialog('tipo_A_extensao_risco_dano')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Extensão do risco/dano</span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoADialog('tipo_A_magnitude_risco_dano')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Magnitude do risco/dano</span>
  </div>

  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoADialog('tipo_A_urgencia_analise')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Urgência da análise</span>
  </div>



</div>
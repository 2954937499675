<div class="page-container criterios-prioridade" style="max-width: 920px;">
  <div class="matero-page-header-inner">
    <h1 class="matero-page-title ">Critérios de prioridade </h1>
  </div>

  <mat-tab-group color="accent" mat-align-tabs="start" selectedIndex="0"
                 style="margin-bottom: 80px;">

    <mat-tab label="Visão geral">
      <!--INICIO VISAO GERAL-->
      <div style=" margin-top:34px">
        <div class="barra-destaque">
          <mat-icon class="material-icons-outlined"
                    style="font-size: 20px; color:#1F2022;    margin-right: 5px;height: 20px;"
                    mat-list-icon>info</mat-icon>
          <div>Os critérios de prioridade para as solicitações mudaram, entenda abaixo como estão
            funcionando.</div>
        </div>

        <div class="matero-page-header-inner" style="    margin-top: 26px;">
          <h3 class="matero-page-title criterios-title">Como funciona agora?</h3>
        </div>

        <div class="blocos">
          <div class="bloco">
            <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                        style="font-size: 24px; color:#5D899D; position: absolute; top: 18px; "
                        mat-list-icon>news</mat-icon></span>
            <span style="font-size: 15px;   padding-top: 6px;">Ao ser admitida, cada SAT recebe uma
              nota de prioridade.</span>
          </div>
          <div class="bloco">
            <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                        style="font-size: 24px; color:#AA4139; position: absolute; top: 18px; "
                        mat-list-icon>view_array</mat-icon></span>
            <span style="font-size: 14px;   padding-top: 6px;">A SAT entra na fila de atendimento,
              conforme sua nota.</span>
          </div>
          <div class="bloco">
            <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                        style="font-size: 24px; color:#946F2E; position: absolute; top: 18px; "
                        mat-list-icon>content_paste</mat-icon></span>
            <span style="font-size: 14px;   padding-top: 6px;">A equipe técnica prioriza o
              atendimento para o de maior nota.</span>
          </div>
        </div>
        <div style="clear: both;"></div>

        <div class="matero-page-header-inner" style="    margin-top: 46px;">
          <h3 class="matero-page-title criterios-title">Como é calculada a prioridade?</h3>
        </div>


        <p>
          O método usado para calcular a nota de prioridade depende do tema relacionado à SAT:
        </p>

        <div class="tipo">
          <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                      style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                      top: 2px;"
                      mat-list-icon>news</mat-icon></span>
          <div><strong>Tipo A</strong></div>
        </div>
        <div style="clear: both;"></div>

        <div style="margin-left: 33px;">
          <p style="margin: 0px; font-size: 13px;">
            Todas as solicitações, exceto as relacionadas aos temas Economicidade e Medicina Legal.
          </p>
          <button
                  (click)="detalhesDialog('prioridade_tipo_A')"
                  mat-stroked-button
                  color="accent"
                  style="max-width: 250px;margin-left:0px; margin-top: 4px; background: #ffffff; font-size: 12px;">
            <mat-icon aria-label="Buscar">search</mat-icon>
            Ver detalhes
          </button>
        </div>




        <div class="tipo">
          <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                      style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                      top: 2px;"
                      mat-list-icon>news</mat-icon></span>
          <div><strong>Tipo B</strong></div>
        </div>
        <div style="clear: both;"></div>

        <div style="margin-left: 33px;">
          <p style="margin: 0px; font-size: 13px;">
            Economicidades de Contratos Públicos.
          </p>
          <button
                  (click)="detalhesDialog('prioridade_tipo_B')"
                  mat-stroked-button
                  color="accent"
                  style="max-width: 250px;margin-left:0px; margin-top: 4px; background: #ffffff; font-size: 12px;">
            <mat-icon aria-label="Buscar">search</mat-icon>
            Ver detalhes
          </button>
        </div>


        <div class="tipo">
          <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                      style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                      top: 2px;"
                      mat-list-icon>news</mat-icon></span>
          <div><strong>Tipo C</strong></div>
        </div>
        <div style="clear: both;"></div>

        <div style="margin-left: 33px; margin-bottom: 40px;">
          <p style="margin: 0px; font-size: 13px;">
            Medicina Legal.
          </p>
          <button
                  (click)="detalhesDialog('prioridade_tipo_C')"
                  mat-stroked-button
                  color="accent"
                  style="max-width: 250px;margin-left:0px; margin-top: 4px; background: #ffffff; font-size: 12px;          ">
            <mat-icon aria-label="Buscar">search</mat-icon>
            Ver detalhes
          </button>
        </div>

        <p>
          Para formar a <strong>Nota Geral de Prioridade (NG)</strong>, as metodologias consideram*:
        </p>

        <div class="quadro-destaque">
          <mat-icon class="material-icons-outlined"
                    style="font-size: 25px; color:#1F2022;    margin-right: 5px;height: 26px; width: 30px;"
                    mat-list-icon>groups</mat-icon>
          <div>Custo de análise (NC)</div>
        </div>
        <div class="quadro-destaque">
          <mat-icon class="material-icons-outlined"
                    style="font-size: 25px; color:#1F2022;    margin-right: 5px;height: 26px; width: 30px;"
                    mat-list-icon>campaign</mat-icon>
          <div>Índice de impacto (NI)</div>
        </div>

        <div style="clear: both;"></div>
        <p>A partir desses índices e os respectivos cálculos de cada metodologia, a nota é formada e
          a ordem de atendimento é gerada.
          <span style="display: block;
          margin-top: 5px;" class="obs-pq">*Exceto o tema Medicina Legal que utiliza outros
            índices.</span>
        </p>

        <div class="matero-page-header-inner" style="    margin-top: 46px;">
          <h3 class="matero-page-title criterios-title">Diretrizes</h3>
        </div>
        <div class="diretrizes">
          <div class="diretrizes-img-container">
            <img src="../../../../assets/criterios-prioridade/diagrama-gate.svg"
                 alt="Diagrama diretrizes">
          </div>
          <div class="diretrizes-texto">
            <p>A equipe do GATE é dividida em temas de acordo com as especialidades dos técnicos
              periciais. </p>
            <p>A fila de Solicitações de Análises Técnicas (SATs) é organizada em 28 temas. </p>
            <p>Enquanto aguardam atendimento, as SATs permanecem sem atribuição a um técnico
              pericial. </p>
            <p>Com os novos critérios definidos, vai ser possível controlar a produtividade dos
              técnicos periciais, estabelecer prazos e calcular o tempo de espera para cada
              solicitação. </p>
          </div>



        </div>


      </div>
    </mat-tab>
    <!--FIM VISAO GERAL-->











    <!--INICIO CRITERIOS-->
    <mat-tab label="Critérios">
      <div style=" margin-top:34px">

        <div>
          <span style="font-weight: 600;">Você está vendo </span>
          <mat-chip-list style="display: inline-block;">
            <mat-chip
                      *ngFor="let tipo of tipos"
                      [selected]="selectedTipo === tipo"
                      (click)="selectTipo(tipo)"
                      selectable="true">
              {{tipo}}
            </mat-chip>
          </mat-chip-list>

          <div *ngIf="selectedTipo === 'Tipo A'">
            <criterios-tipo-A> </criterios-tipo-A>
          </div>




          <div *ngIf="selectedTipo === 'Tipo B'">
            <criterios-tipo-B> </criterios-tipo-B>
          </div>
          <div *ngIf="selectedTipo === 'Tipo C'">
            <criterios-tipo-C></criterios-tipo-C>
          </div>
          <div *ngIf="selectedTipo === 'Acelerador'">
            <acelerador-comp></acelerador-comp>
          </div>
        </div>


      </div>
    </mat-tab>
    <mat-tab label="FAQ">
      <criterios-FAQ></criterios-FAQ>
    </mat-tab>
  </mat-tab-group>










</div>
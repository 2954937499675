import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SatDetalhesService {
  private selectedSat: any; // This will hold the selected SAT data
  private satFormEditar: any
  public editarStepToLoad = 'solicitante'
  teste = "teste"
  constructor() { }

  // Method to set the selected SAT data
  setSelectedSat(sat: any) {
    console.log("setSelectedSat ---->>>>>", sat)
    this.selectedSat = sat;
  }
  setSatFormEditar(satFormEditar: any) {
    console.log("setSatFormEditar ---->>>>>", satFormEditar)

    this.satFormEditar = satFormEditar;
  }

  generateDocNumber(sat: any) {
    //  console.log(sat)
    const SATE_PSSO_DK = sat.satePssoDk;
    let nuberToReturn = 0
    if (SATE_PSSO_DK === 1) {
      nuberToReturn = sat.sateDocuDk
    } else if (SATE_PSSO_DK === 2) {
      nuberToReturn = sat.sateIdProtocolo
    } else if (SATE_PSSO_DK === 3) {
      nuberToReturn = sat.sateNumCnmpIc
    } else if (SATE_PSSO_DK === 4) {
      nuberToReturn = sat.sateTjraDk
    } else if (SATE_PSSO_DK === 5) {
      nuberToReturn = sat.sateNumIp
    }

    return nuberToReturn
  }

  // Method to get the selected SAT data
  getSelectedSat(): any {
    return this.selectedSat;
  }
  getSatFormEditar(): any {
    return this.satFormEditar;
  }
}

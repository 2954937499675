
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminDatabaseService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*'

    })
  }

  baseURL = "prodata/"


  constructor(private http: HttpClient) { }


  getAllTemas() {
    return this.http.get<any>(this.baseURL + 'ListController/ListaTemaAnaliseTec', { responseType: 'json' });
  }


  getAllServTec(satDk: any) {
    return this.http.get<any>(this.baseURL + 'ListController/ListaServicTec', { responseType: 'json' });
  }

  getOrphanServTec(satDk: any) {
    return this.http.get<any>(this.baseURL + 'admin/ListServTecOrphan', { responseType: 'json' });
  }
  getDeleteableServTec(satDk: any) {
    return this.http.get<any>(this.baseURL + 'admin/ListServTecNotUse', { responseType: 'json' });
  }

  getAllDocumento() {
    return this.http.get<any>(this.baseURL + 'ListController/ListaDocServTec', { responseType: 'json' });
  }

  getOrphanDocumento() {
    return this.http.get<any>(this.baseURL + 'admin/ListDocOrphan', { responseType: 'json' });
  }
  getDeleteableDocumento() {
    return this.http.get<any>(this.baseURL + 'admin/ListDocNotUse', { responseType: 'json' });
  }
  createServico(data: any, action: string) {
    console.log("base url ServTec", this.baseURL + 'admin/CreateServicoTec?TMAN_DK=' + data.tmanDk)
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.post(this.baseURL + 'admin/CreateServicoTec?TMAN_DK=' + data.tmanDk + '&ACTION=' + action, data, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );

  }

  createServicoTecList(data: any, action: string, tmanDk: number) {
    console.log("data", data)
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.post(this.baseURL + 'admin/CreateServicoTecList?TMAN_DK=' + tmanDk + '&ACTION=' + action, data, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );

  }


  CruDocServTec(data: any, action: string) {
    console.log("data", data)
    console.log("base url", this.baseURL + 'admin/CruDocServTec?TMST_DK=' + data.TMST_DK + '&PSTC_DK=' + data.PSTC_DK + '&ACTION=' + action)
    return this.http.put(this.baseURL + 'admin/CruDocServTec?TMST_DK=' + data.TMST_DK + '&PSTC_DK=' + data.PSTC_DK + '&ACTION=' + action, data).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  CruDocServTecList(data: any, TMST_DK: any, PSTC_DK: any, action: string) {
    console.log("data", data)
    console.log("base url", this.baseURL + 'admin/CruDocServTecList?TMST_DK=' + TMST_DK + '&PSTC_DK=' + PSTC_DK + '&ACTION=' + action)
    return this.http.put(this.baseURL + 'admin/CruDocServTecList?TMST_DK=' + TMST_DK + '&PSTC_DK=' + PSTC_DK + '&ACTION=' + action, data).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }
  getVinculosTemaServ(PSTC_DK: any): Observable<any> {
    return this.http.get<any>(`${this.baseURL}admin/GetTemaServico?PSTC_DK=${PSTC_DK}`, { responseType: 'json' })
      .pipe(
        catchError(error => {
          console.error('Error occurred:', error);
          // Option 1: Return an empty array or some default value
          // return of([]);
          // Option 2: Propagate the error
          return throwError(() => new Error('Error fetching data'));
        })
      );
  }


  getVinculosDocServ(TMST_DK: any, TMAN_DK: any): Observable<any> {
    return this.http.get<any>(`${this.baseURL}ListController/ListDoctByPstDkTmantDk?TMAN_DK=${TMAN_DK}&PSTC_DK=${TMST_DK}`, { responseType: 'json' })
      .pipe(
        catchError(error => {
          console.error('Error occurred:', error);
          // Option 1: Return an empty array or some default value
          // return of([]);
          // Option 2: Propagate the error
          return throwError(() => new Error('Error fetching data'));
        })
      );
  }


  VinculaServicoTec(PSTC_DK: any, data: any) {
    console.log(data, PSTC_DK)
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.post(this.baseURL + 'admin/VinculaServicoTec?PSTC_DK=' + PSTC_DK, data, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );

  }

  DellVinculaServicoTec(PSTC_DK: any, TMAN_DK: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.delete(this.baseURL + 'admin/DellVinculaServicoTec?TMAN_DK=' + TMAN_DK + '&PSTC_DK=' + PSTC_DK, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );

  }

  DeleteServTec(PSTC_DK: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.delete(this.baseURL + 'admin/DeleteServTec?PSTC_DK=' + PSTC_DK, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }
  DeleteDoc(DOST_DK: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.delete(this.baseURL + 'admin/DeleteDoc?DOST_DK=' + DOST_DK, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  VinculaDocServTec(data: any) {
    console.log("data", data)
    return this.http.put(this.baseURL + 'admin/VinculaDocServTec', data).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }
  DellVinculoDocServTec(DTST_DK: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.delete(this.baseURL + 'admin/DellVincul0DocServTec?DTST_DK=' + DTST_DK, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );

  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-criterios-prioridades',
  templateUrl: './criterios-prioridades.component.html',
  styleUrls: ['./criterios-prioridades.component.scss']
})
export class CriteriosPrioridadesComponent implements OnInit {
  tipos: string[] = ['Tipo A', 'Tipo B', 'Tipo C', 'Acelerador'];
  // selectedTipo: string = 'Tipo A';
  selectedTipo: string = 'Tipo A';
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  selectTipo(tipo: string): void {
    this.selectedTipo = tipo;
  }

  detalhesDialog(content: string): void {
    this.dialog.open(DetalhesPrioridadeDialog, {
      data: { content: content }
    });
  }

}


@Component({
  selector: 'detalhes-prioridades',
  templateUrl: './detalhes-prioridades.html',
  styleUrls: ['./criterios-prioridades.component.scss']
})
export class DetalhesPrioridadeDialog {
  constructor(
    public dialogRef: MatDialogRef<DetalhesPrioridadeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'criterios-tipo-A',
  templateUrl: './criterios-tipo-A.html',
  styleUrls: ['./criterios-prioridades.component.scss']
})
export class CriteriosTipoA {
  constructor(public dialog: MatDialog) { }
  tipoADialog(content: string): void {
    this.dialog.open(DetalhesPrioridadeDialog, {
      data: { content: content }
    });
  }
}


@Component({
  selector: 'criterios-tipo-B',
  templateUrl: './criterios-tipo-B.html',
  styleUrls: ['./criterios-prioridades.component.scss']
})
export class CriteriosTipoB {
  constructor(public dialog: MatDialog) { }
  tipoBDialog(content: string): void {
    this.dialog.open(DetalhesPrioridadeDialog, {
      data: { content: content }
    });
  }
}


@Component({
  selector: 'criterios-tipo-C',
  templateUrl: './criterios-tipo-C.html',
  styleUrls: ['./criterios-prioridades.component.scss']
})
export class CriteriosTipoC {
  constructor(public dialog: MatDialog) { }
  tipoCDialog(content: string): void {
    this.dialog.open(DetalhesPrioridadeDialog, {
      data: { content: content }
    });
  }
}


@Component({
  selector: 'acelerador-comp',
  templateUrl: './acelerador.html',
  styleUrls: ['./criterios-prioridades.component.scss']
})
export class Acelerador {
  constructor() { }

  abrirLinkExterno(link: any) {
    window.open(link, '_blank');
  }

}

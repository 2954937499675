import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
@Component({
  selector: 'app-documentos-integra',
  templateUrl: './documentos-integra.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class DocumentosIntegraComponent implements OnInit {
  documentosObrigatoriosArr: any = [];
  documentosDesejaveisArr: any = [];
  documentosArr: any = [];

  obrigatoriosArrFinal: any = [];
  desejaveisArrFinal: any = [];

  preTemasArr: any = []

  areasArr: any = []

  constructor(public satFormService: SatFormService) { }

  ngOnInit(): void {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    let uniqueTemas = this.removeDuplicates([...this.satFormService.preForm.areasArr])
    this.areasArr = uniqueTemas


    // console.log("this.satFormService.preForm.temasArr", this.satFormService.preForm.temasArr)
    // Now, requiredDocumentosArr contains all required documents, and desirableDocumentosArr contains all desirable documents

    const obgrUnique = this.documentosObrigatoriosArr.filter((item: any, index: any, self: any) => {
      return index === self.findIndex((i: any) => i.dostDk === item.dostDk);
    });
    const desejUnique = this.documentosDesejaveisArr.filter((item: any, index: any, self: any) => {
      return index === self.findIndex((i: any) => i.dostDk === item.dostDk);
    });

    this.obrigatoriosArrFinal = obgrUnique;
    this.desejaveisArrFinal = desejUnique;

  }
  trackByFn(index: any, item: any) {
    return index;
  }
  addId(documento: any) {
    documento.idsArr.push('')
  }
  checkIsdArr(documento: any) {


    if (documento.idsArr.length > 1) {
      documento.idsArr = documento.idsArr.filter((item: any) => item !== "");
    }
  }

  removeDuplicates(data: any) {
    const seen = new Set();
    const result = [];

    data.forEach((area: any) => {
      area.temas.forEach((tema: any) => {
        tema.servicos = tema.servicos.filter((servico: any) => {
          if (seen.has(servico.tmsDk)) {
            return false; // Duplicate found, filter out
          }
          seen.add(servico.tmsDk);
          return true; // Unique, keep it
        });
      });
      // Optionally remove empty temas if needed
      area.temas = area.temas.filter((tema: any) => tema.servicos.length > 0);
    });

    return data;
  }

}

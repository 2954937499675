import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';

@Component({
  selector: 'app-complementaridade',
  templateUrl: './complementaridade.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplementaridadeComponent implements OnInit {

  currentTpaxDk = 2
  hasTpaxDk5 = false
  constructor(public satFormService: SatFormService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  changeTpaxDk(dk: any) {
    this.currentTpaxDk = dk
    setTimeout(() => {
      this.cd.detectChanges();
    }, 1000);
  }

  removeFiles() {
    this.satFormService.preForm.excepcionalidadeObj.inManifTecAnterior = 'N';
    this.satFormService.preForm.excepcionalidadeObj.dsJustifAusenciaCompl = null;
    this.satFormService.checkPreFormToNavigate()
    setTimeout(() => {
      this.cd.detectChanges();
    }, 1000);
  }
}

<!--  DEFINIÇÃO  DO(S) TEMAS(S) ************************************************* -->
<!-- servico-list.component.html -->
<div style="min-height: 165px; display: block">
  <div *ngIf="spinner">
    <div style="margin-bottom: 15px;"><strong>Carregando lista de serviços técnicos</strong></div>
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!spinner" >
    <div style="margin-bottom: 15px; padding: 15px; left: 25px;display: inline-block; "><strong></strong>

          <button type="button" style="margin: 5px;"
            mat-flat-button
            (click)="changeStep('Editar')"
            color="accent">
            Editar
            <mat-icon>edit</mat-icon>

          </button>
          <span></span>
        <button type="button" style="margin: 5px;"
          mat-flat-button
          (click)="changeStep('Incluir')"
          color="accent">
          Incluir
          <mat-icon>add</mat-icon>
        </button>
        <span></span>
        <button style="margin: 5px;"
         mat-flat-button
         (click)="changeStep('Excluir')"
         color="accent">
         Excluir
         <mat-icon>delete</mat-icon>
      </button>

    </div>

    <div >

      <mat-accordion class="mat-elevation-z0">
        <mat-expansion-panel multi="false" class="expand-area mat-elevation-z0"
                             *ngFor="let area of filteredAreas; trackBy: trackByArea">
          <mat-expansion-panel-header>
            <mat-panel-title class="expand-area-title" >
              <mat-icon *ngIf="area.selected" matPrefix>check</mat-icon>
              {{ area.ArstNmArea | lowercaseAndTitlecase }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-accordion class="mat-elevation-z0" multi>
            <mat-expansion-panel multi="false" class="expand-tema mat-elevation-z0"
                                 *ngFor="let tema of area.Temas; trackBy: trackByTema">
              <mat-expansion-panel-header class="temas">
                <mat-panel-title (click)="setTema(tema[0])" >
                  <mat-icon *ngIf="tema.selected"
                            style="font-size: 20px; color:#5D899D; font-weight: bold;"
                            matPrefix>check</mat-icon>
                  <mat-icon *ngIf="!tema.selected" class="icon-lista"
                            mat-list-icon >fiber_manual_record</mat-icon>
                  {{ tema[0].tmanNmTema | lowercaseAndTitlecase }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="buscar" *ngIf="isEdicao" >
                <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
                  <mat-icon matPrefix>search</mat-icon>
                  <input type="text" placeholder="Buscar" matInput
                         [(ngModel)]="tema[0].searchServico"
                         (input)="filterServicos(tema[0].searchServico, tema[0])" />
                </mat-form-field>
              </div>

              <div style="max-height: 350px; overflow: auto; overflow-x: hidden"  >
                <mat-list role="list"  *ngIf="isEdicao || isDelete" >
                  <mat-list-item

                                 *ngFor="let servico of tema[0].servTec;  let i = index"
                                 class="lista "

                                 [ngClass]="{ selected: servico.selected }"
                                 role="listitem">

                    <section>

                    <mat-checkbox  (click)="selectedServico(servico[0].pstcNmServTec)"

                                  style="word-wrap: break-word;"
                                  [(ngModel)]="servico.selected"
                                  color="accent"
                                  [disabled]="servico.disabled === true">

                        {{ servico[0].pstcNmServTec }}
                    </mat-checkbox>
                    <!--
                    <span>
                      <ul>
                        <li style="color: #7E8990;  font-size: 14px;"
                            *ngFor="let documento of servico[0].docsObrigatorios">
                          {{ documento.dostNmDocumento }}
                        </li>
                      </ul>
                    </span>
                  -->
                  </section>

                  </mat-list-item>

                </mat-list>
              </div>
              <div class="buscar" *ngIf="!isDelete" [formGroup]="formCadastro">
                <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
                  <input type="text" placeholder="Nome do Serciço Tecnico" matInput
                         formControlName="FcNmServico" />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
                  <input type="text" placeholder="Descrição do Serciço Tecnico" matInput
                  formControlName="FcDsServico" />
                </mat-form-field>
                <section class="example-section" >

                  <p><mat-checkbox formControlName="FcInduvida">Duvida Tecnica</mat-checkbox></p>
                  <p><mat-checkbox formControlName="FcInInfotec">Informação Tecnica</mat-checkbox></p>

                </section>


              </div>
            </mat-expansion-panel>
            <button type="button" style="margin: 5px;"
            mat-flat-button
            (click)="SalvarServTec()"
            color="accent">
            Salvar
            <mat-icon>edit</mat-icon>

          </button>
          </mat-accordion>

        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</div>

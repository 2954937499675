<div *ngIf="spinner === false">
  <P>Copie uma lista do Excel (apenas uma coluna) e cole no campo abaixo. <strong>Não copie o
      cabeçalho, se houver.</strong></P>
  <mat-card>
    <mat-card-content>
      <!-- Textarea to paste the data from Excel -->
      <textarea
                matInput
                [(ngModel)]="pastedData"
                (ngModelChange)="onPasteData()"
                placeholder="Cole a lista aqui"
                rows="6"
                style="width: 100%; border: none;
                  border-bottom: 1px #9c9c9c dotted;"></textarea>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="accent" [disabled]="dataList.length < 1"
              (click)="createDocumentoList('INSERT')">
        Salvar documentos
      </button>
      <button mat-stroked-button color="accent"
              style="float: right;"
              (click)="limparLista()">Limpar lista</button>
    </mat-card-actions>
  </mat-card>

  <!-- Display the parsed array of objects -->
  <div *ngIf="dataList.length">
    <h3>Documentos a serem inseridos:</h3>
    <ul>
      <li *ngFor="let item of dataList; let i = index"
          style="display: flex; justify-content: space-between; align-items: center; border-bottom: 1px #9c9c9c dotted;">
        <span> <span style="width: 30px"><strong>{{i +1}}. </strong></span>{{ item.dostNmDocumento
          }}</span>
        <!-- Icon Button for Remove -->
        <button mat-icon-button color="warn" (click)="removeItem(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </li>
    </ul>
  </div>

  <!-- Display the parsed array of objects -->
  <div *ngIf="duplicatedDocumentos.length" style="margin-top:35px">
    <p><strong>Documentos duplicados</strong> (já retirados da lista):</p>
    <ul>
      <li *ngFor="let item of duplicatedDocumentos; let i = index"
          style="display: flex; justify-content: space-between; align-items: center; border-bottom: 1px #9c9c9c dotted;">
        <span> <span style="width: 30px"><strong>{{i +1}}. </strong></span>{{ item.dostNmDocumento
          }}</span>
        <!-- Icon Button for Remove -->

      </li>
    </ul>
  </div>
</div> <!-- Fim spinner === false -->
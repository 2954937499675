<div>


  <div class="matero-page-header-inner" style="    margin-top: 30px;">
    <h3 class="matero-page-title criterios-title">Critérios de prioridade Tipo B</h3>
  </div>

  <p> Economicidade de contratos públicos.</p>
  <p>

    A portaria n. <a style="color: #Ad8847; text-decoration: underline;"
       href="https://www.tcesc.tc.br/node/56759" target="_blank">TC-0156/2021</a> do
    Tribunal de Contas de Santa Catarina, que estabelece critérios e
    pesos para seleção de atuação do tribunal, serviu de referência para os critérios de priorização
    das análises de economicidades.
  </p>

  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>news</mat-icon></span>
    <div>Nota Geral de Prioridade (NG) Tipo B</div>
  </div>

  <p>
    Será definida pela divisão entre os índices de impacto e custo.
  </p>

  <div class="formula">
    <i> NG<sub>B</sub></i> = (<i>índicedeimpacto<sub>B</sub></i>) /
    (<i>índicedecusto<sub>B</sub></i>)
  </div>
  <p>A nota assumirá um número entre 21 e 100, onde a maior nota indica a maior prioridade.</p>


  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>groups</mat-icon></span>
    <div>Índice de Custo Tipo B</div>
  </div>
  <p>O Índice de Custo é formado pela média ponderada dos indicadores de custo de análise.</p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">Para calcular o custo da análise de
      economicidade de contratos de obras e serviços de engenharia, usa-se como indicadores o número
      de itens, o número de medições e o número de folhas do processo. Para calcular o custo da
      análise de todos os outros tipos de economicidade de contratos, usa-se como indicadores o
      número de itens, o objeto contratado e o número de folhas do procedimento.</span>
  </div>

  <p>O Índice de Custo terá uma nota final que variará de 1,00 (custo muito baixo) a 1,30 (muito
    alto).</p>




  <p><strong>Indicadores de Custo de Análise</strong></p>

  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoBDialog('tipo_B_numero_itens')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Número de Itens</span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoBDialog('tipo_B_numero_medicoes')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Número de Medições (exclusivo economicidade
      de obras e serviços de engenharia) </span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoBDialog('tipo_B_objeto_analise')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Objeto da Análise (menos economicidade de
      obras e serviços de engenharia)</span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoBDialog('tipo_B_numero_folhas')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Número de Folhas </span>
  </div>






  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>campaign</mat-icon></span>
    <div>Índice de Impacto Tipo B</div>
  </div>
  <p>O Índice de Impacto é formado pela média ponderada de treze indicadores de impacto da atuação
    do GATE.</p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">Os pesos, graduações e notas de cada
      indicador foram definidos alinhados à missão, à visão e aos valores da instituição,
      apresentados no planejamento estratégico para o período de 2020 a 2027. Os pesos foram
      determinados a partir do Método AHP, com base em questionários aplicados entre os
      especialistas do GATE.</span>
  </div>

  <p>O índice de Impacto terá uma nota final que variará entre 28 e 100.</p>




  <p><strong>Indicadores de Impacto</strong></p>


  <mat-accordion class="criterios-expansions">

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Indicadores de Relevância (Peso de 21,5% no Índice de Impacto)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I1')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I1) - Valor do objeto investigado em
          relação ao orçamento</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I2')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I2) - Quartil da população atingida pela
          irregularidade narrada</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I3')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I3) - Área do objeto - Função de
          Governo</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I4')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I4) - Origem da informação</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I5')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I5) - IDH [Índice de Desenvolvimento
          Humano]</span>
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Indicadores de Risco (Peso de 11,6% no Índice de Impacto)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I6')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I6) - Resultado da apreciação da
          Prestação de Contas de Governo</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I7')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I7) - Histórico de dano apurado pelo
          GATE em relação ao contratado</span>
      </div>

    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Indicador de Oportunidade (Peso de 17% no Índice de Impacto)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I8')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I8) - Indicador de Oportunidade</span>
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Indicador de Materialidade (Peso de 16,5% no Índice de Impacto)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I9')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I9) - Indicador de Materialidade</span>
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Indicadores de Urgência (Peso de 22,4% no Índice de Impacto)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I10')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I10) - Existência de prazo judicial ou
          legal</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I11')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I11) - Solicitação oriunda de Grupo de
          Atuação Especializada</span>
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Indicadores de Benefício Potencial (Peso de 11,0% no Índice de Impacto)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I12')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I12) - Apuração de sobrepreço ou
          superfaturamento</span>
      </div>
      <div class="destaque-accent" style="cursor: pointer;"
           (click)="tipoBDialog('tipo_B_I13')">
        <span style="min-width: 26px;"> <mat-icon
                    class="destaque-accent-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">(I13) - Atribuição de Origem</span>
      </div>
    </mat-expansion-panel>

  </mat-accordion>















</div>
import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  HostBinding,
  TemplateRef,
} from '@angular/core';
import { AppSettings, SettingsService } from '@core';
import screenfull from 'screenfull';
import { AuthService } from '@core/authentication';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  //  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Output() optionsChange = new EventEmitter<AppSettings>();

  options = this.settings.getOptions();

  @HostBinding('class') class = 'matero-header';

  @Input() showToggle = true;
  @Input() showBranding = false;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();

  constructor(private settings: SettingsService, private auth: AuthService, private router: Router) {
    // Initialize the instance
  }
  sendOptions() {
    this.optionsChange.emit(this.options);
    console.log(this.options);
  }

  toggleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  logout() {
    this.auth.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temas-criticos',
  templateUrl: './temas-criticos.component.html',
  styleUrls: ['./temas-criticos.component.scss']
})
export class TemasCriticosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  abrirProtocGestao(): void {
    const externalLink = 'https://intranet.mprj.mp.br/documents/10227/82319385/protocolo04_controledopassivo1_1.pdf'; // Replace this with your actual external link
    window.open(externalLink, '_blank');
  }
}

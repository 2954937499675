<div style="min-height: 165px; display: block; ">


  <div *ngIf="satFormService.logadoIsMembro === false" class="step-subtitle"> Busque o membro
    solicitante pela número da matrícula</div>
  <div>
    <!-- <div style="margin-top: 12px" *ngIf="satFormService.preForm.solicitanteObj === null "> -->
    <div *ngIf="satFormService.logadoIsMembro === false" style="margin-bottom: 12px">
      <div class="buscar">
        <mat-form-field appearance="fill" style="width: 100%; max-width: 350px;">
          <mat-icon matPrefix>search</mat-icon>
          <input
                 type="number"
                 [(ngModel)]="satFormService.solicitanteMat"
                 matInput
                 maxlength="8"
                 placeholder="Ex. 94105"
                 value="{{ satFormService.solicitanteMat }}" />
          <button color="accent"
                  *ngIf="satFormService.solicitanteMat"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="satFormService.solicitanteMat = ''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div style="height: 12px; max-width: 350px;">
        <mat-progress-bar *ngIf="satFormService.preForm.solicitanteObj === 'carregando'"
                          color="accent" mode="indeterminate"></mat-progress-bar>
      </div>
      <div
           *ngIf="satFormService.membroNaoEncontrado === true">
        <p
           style="color: #AA4139;">
          Membro não
          encontrado

        </p>

        <p> Confira a matrícula do Membro e faça nova busca </p>
      </div>
      <button
              [disabled]="satFormService.solicitanteMat?.length < 4"
              mat-flat-button
              color="accent"
              style="margin-top: 10px;"
              (click)="satFormService.getMembroByMatricula(satFormService.solicitanteMat); satFormService.preForm.solicitanteObj = 'carregando'">
        Buscar
        <mat-icon aria-label="Buscar">search</mat-icon>
      </button>

    </div>


    <div *ngIf="satFormService.preForm.solicitanteObj !== null">
      <p class="subtitle" style="    margin-top: 50px !important;">Membro Solicitante</p>
      <div>
        <span class="destaque">{{ satFormService.preForm.solicitanteObj?.nome }}</span>
      </div>
      <div style="color: #4B555D;">{{ satFormService.preForm.solicitanteObj?.matricula | slice:4 }}
      </div>
    </div>




  </div>
  <mat-card-footer> </mat-card-footer>
</div>
<mat-dialog-content class="modal-content">
  <ng-container *ngIf="data.content === 'prioridade_tipo_A'">
    <div class="modal-titulo">
      Critério de prioridade - Tipo A
    </div>
    <p>Todas as solicitações, exceto as relacionadas aos temas Economicidade e Medicina Legal.</p>

    <div class="modal-subtitulo">
      Definição Geral
    </div>

    <p>
      A nota geral de prioridade (NG) de cada solicitação será definida pela relação entre os
      índices de IMPACTO e CUSTO.
    </p>
    <div class="modal-subtitulo">
      Índice de Custo
    </div>
    <p>
      O Índice de Custo é formado pela soma dos indicadores de custo de análise, que são:
    </p>
    <div class="modal-destaques">
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Tempo de análise</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Equipe de análise</span>
      </div>
      <div style="clear: both;"></div>
    </div>

    <div class="modal-subtitulo">
      Índice de Impacto
    </div>
    <p>
      O Índice de Impacto é formado pela soma de três indicadores de custo de análise, que são:
    </p>
    <div class="modal-destaques">
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Extensão do risco/dano</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Magnitude do risco/dano</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Urgência da análise</span>
      </div>
      <div style="clear: both;"></div>


    </div>
    <p style="margin-top:35px">
      Para mais detalhes, acesse a aba Critérios.
    </p>

  </ng-container>
  <ng-container *ngIf="data.content === 'prioridade_tipo_B'">
    <div class="modal-titulo">
      Critério de prioridade - Tipo B
    </div>
    <p>A portaria n. TC-0156/2021 do Tribunal de Contas de Santa Catarina, que estabelece critérios
      e pesos para seleção de atuação do tribunal, serviu de referência para os critérios de
      priorização das solicitações de análises de economicidade.</p>

    <div class="modal-subtitulo">
      Definição Geral
    </div>

    <p>
      A nota geral de prioridade de cada solicitação de análise de economicidade será definida pela
      relação entre os índices de IMPACTO e CUSTO.
    </p>
    <div class="modal-subtitulo">
      Índice de Custo
    </div>
    <p>
      O Índice de custo é formado pela média ponderada dos indicadores de custo de análise, que são:
    </p>
    <div class="modal-destaques">
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Número de Itens</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Número de Medições</span>
      </div>
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Objeto da Análise</span>
      </div>
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Número de Folhas</span>
      </div>
      <div style="clear: both;"></div>
    </div>

    <div class="modal-subtitulo">
      Índice de Impacto
    </div>
    <p>
      O Índice de Impacto é formado pela média ponderada de treze indicadores de impacto da atuação
      do GATE. Os indicadores estão agrupados por:
    </p>
    <div class="modal-destaques">
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>workspaces</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Indicadores de Relevância</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>workspaces</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Indicadores de Risco</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>workspaces</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Indicador de Oportunidade</span>
      </div>
      <div style="clear: both;"></div>
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>workspaces</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Indicador de Materialidade </span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>workspaces</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Indicadores de Urgência</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>workspaces</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Indicadores de Benefício Potencial</span>
      </div>
      <div style="clear: both;"></div>


    </div>
    <p style="margin-top:35px">
      Para mais detalhes, acesse a aba Critérios.
    </p>
  </ng-container>
  <ng-container *ngIf="data.content === 'prioridade_tipo_C'">
    <div class="modal-titulo">
      Critério de prioridade - Tipo C
    </div>
    <p>Medicina Legal.</p>

    <div class="modal-subtitulo">
      Definição Geral
    </div>

    <p>
      A nota geral de prioridade (NG) de cada solicitação será definida pela relação entre os
      índices de gravidade do crime e um índice de contemporaneidade.
    </p>
    <div class="modal-subtitulo">
      Índice de Gravidade do Crime </div>
    <p>
      O Índice de gravidade de um crime é formado pela soma dos indicadores, que
      são:
    </p>
    <div class="modal-destaques">
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Tipo penal em investigação</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Quantidade de vítimas</span>
      </div>

      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">“Qualificador” da vítima</span>
      </div>

      <div style="clear: both;"></div>
    </div>

    <div class="modal-subtitulo">
      Índice de Contemporaneidade
    </div>
    <p>
      O Índice de contemporaneidade é formado pelo indicador de contemporaneidade.
    </p>
    <div class="modal-destaques">
      <div class="modal-destaque">
        <span style="min-width: 26px;"> <mat-icon
                    class="modal-destaque-icon material-symbols-outlined"
                    mat-list-icon>label</mat-icon></span>
        <span style="font-size: 14px;   padding-top: 6px;">Contemporaneidade</span>
      </div>
      <div style="clear: both;"></div>
    </div>
    <p style="margin-top:35px">
      Para mais detalhes, acesse a aba Critérios.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_A_tempo_analise'">
    <div class="modal-titulo">
      Tempo de análise - Tipo A
    </div>
    <p>Tempo estimado pelos Técnicos Periciais para conclusão da análise, refletindo a sua
      complexidade. As notas variam conforme a tabela abaixo:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Tempo de análise</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">até 1 semana</div>
        <div class="cell right-column">0,5</div>
      </div>
      <div class="row">
        <div class="cell left-column">de 1 a 2 semanas</div>
        <div class="cell right-column">0,55</div>
      </div>
      <div class="row">
        <div class="cell left-column">de 2 a 4 semanas</div>
        <div class="cell right-column">0,6</div>
      </div>
      <div class="row">
        <div class="cell left-column">mais de 4 semanas</div>
        <div class="cell right-column">0,65</div>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_A_equipe_analise'">
    <div class="modal-titulo">
      Equipe de análise - Tipo A
    </div>
    <p>Quantidade de Técnicos Periciais envolvidos na análise, refletindo sua complexidade, seja
      pela quantidade de Temas envolvidos, seja pela própria complexidade que demanda mais de um
      Técnico Pericial envolvido na análise. As notas variam conforme a tabela abaixo:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Equipe de análise</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">1 TP</div>
        <div class="cell right-column">0,5</div>
      </div>
      <div class="row">
        <div class="cell left-column">2 TPs</div>
        <div class="cell right-column">0,55</div>
      </div>
      <div class="row">
        <div class="cell left-column">3 TPs</div>
        <div class="cell right-column">0,6</div>
      </div>
      <div class="row">
        <div class="cell left-column">4+ TPs</div>
        <div class="cell right-column">0,65</div>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_A_extensao_risco_dano'">
    <div class="modal-titulo">
      Extensão do risco/dano - Tipo A
    </div>
    <p>Reflete o alcance da análise, incluindo a escala da população atingida e, consequentemente, o
      caráter estrutural da atuação. As notas variam conforme a tabela abaixo:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Extensão do risco/dano</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">local</div>
        <div class="cell right-column">9,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">municipal</div>
        <div class="cell right-column">17,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">regional</div>
        <div class="cell right-column">25,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">estadual</div>
        <div class="cell right-column">33,33</div>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_A_magnitude_risco_dano'">
    <div class="modal-titulo">
      Magnitude do risco/dano - Tipo A
    </div>
    <p>Reflete importância da atuação, que pressupõe um julgamento qualitativo de quão ruim está o
      problema e quão sérias são suas consequências para interesses sensíveis. Considerar aqui,
      também, a tendência de o problema piorar. As notas variam conforme a tabela abaixo:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Magnitude do risco/dano</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">baixa</div>
        <div class="cell right-column">9,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">média</div>
        <div class="cell right-column">17,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">alta</div>
        <div class="cell right-column">25,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">muito alta</div>
        <div class="cell right-column">33,33</div>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_A_urgencia_analise'">
    <div class="modal-titulo">
      Urgência da análise - Tipo A
    </div>
    <p>Reflete o potencial de atuação preventiva e, consequentemente, a capacidade de eliminação do
      risco ou, se já houver dano, a possibilidade de sua reversão do dano/risco. As notas variam
      conforme a tabela abaixo:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Urgência da análise</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">1 ano ou mais do fato</div>
        <div class="cell right-column">9,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">6 meses a 1 ano do fato</div>
        <div class="cell right-column">17,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">chance de atuação preventiva ou de interromper o risco/dano
        </div>
        <div class="cell right-column">25,33</div>
      </div>
      <div class="row">
        <div class="cell left-column">está acontecendo, para tudo e vai</div>
        <div class="cell right-column">33,33</div>
      </div>

    </div>
  </ng-container>

  <!--TIPO B-------------------------------------------------------------------- -->
  <ng-container *ngIf="data.content === 'tipo_B_numero_itens'">
    <div class="modal-titulo">
      Número de Itens - Tipo B
    </div>
    <p>Mostra o número de itens que estão incluídos nos contratos que serão analisados. Para obras e
      serviços de engenharia, é o número de componentes da obra ou serviço que foram contratados e
      que precisam ser analisados.
    </p>
    <p>
      O indicador possui peso de 0,2667 no índice e pode assumir os seguintes valores:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Número de itens</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">menos que 20 itens</div>
        <div class="cell right-column">1,00</div>
      </div>
      <div class="row">
        <div class="cell left-column">entre 21 e 90 itens</div>
        <div class="cell right-column">1,15</div>
      </div>
      <div class="row">
        <div class="cell left-column">mais que 91 itens
        </div>
        <div class="cell right-column">1,30</div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="data.content === 'tipo_B_numero_medicoes'">
    <div class="modal-titulo">
      Número de Medições - Tipo B
    </div>
    <p>Representa a quantidade de medições que ocorreram ao longo do acompanhamento do contrato
      objeto da análise. Devem ser computadas as medições de todos os contratos objeto da análise do
      procedimento SEI.
    </p>
    <p>
      O indicador possui peso de 0,3067 no índice e pode assumir os seguintes valores:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Número de medições</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">menos que 10 medições</div>
        <div class="cell right-column">1,00</div>
      </div>
      <div class="row">
        <div class="cell left-column">entre 11 e 40 medições</div>
        <div class="cell right-column">1,15</div>
      </div>
      <div class="row">
        <div class="cell left-column">mais que 41 medições
        </div>
        <div class="cell right-column">1,30</div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="data.content === 'tipo_B_objeto_analise'">
    <div class="modal-titulo">
      Objeto da Análise - Tipo B
    </div>
    <p>Para todos os outros objetos de análise de economicidade. Indica o objeto da contratação. São
      utilizados 92 objetos, que podem receber notas de 1 a 1,30. O indicador possui peso de
      0,3067 no índice. </p>
  </ng-container>


  <ng-container *ngIf="data.content === 'tipo_B_numero_folhas'">
    <div class="modal-titulo">
      Número de Folhas - Tipo B
    </div>
    <p>Indica o total de folhas que foram anexadas ao procedimento SEI. </p>
    <p>

      O indicador possui peso de 0,42 no índice e pode assumir os seguintes valores:</p>
    <div class="table">
      <div class="row header">
        <div class="cell left-column">Número de folhas</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">menos que 1.000 folhas</div>
        <div class="cell right-column">1,00</div>
      </div>
      <div class="row">
        <div class="cell left-column">entre 1.001 e 2.000 folhas</div>
        <div class="cell right-column">1,075</div>
      </div>
      <div class="row">
        <div class="cell left-column">entre 2.001 e 3.000 folhas
        </div>
        <div class="cell right-column">1,15</div>
      </div>
      <div class="row">
        <div class="cell left-column">entre 3.001 e 5.000 folhas</div>
        <div class="cell right-column">1,225</div>
      </div>
      <div class="row">
        <div class="cell left-column">mais que 5.001 folhas</div>
        <div class="cell right-column">1,30</div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="data.content === 'tipo_B_I1'">
    <div class="modal-titulo">
      I1 - Valor do objeto - Tipo B
    </div>
    <p>Representa a importância do valor do objeto investigado em relação ao orçamento do exercício
      de assinatura do ato/contrato investigado do município (LOA) ou da unidade gestora estadual,
      no caso de contratos da esfera estadual. Será atribuída maior prioridade às solicitações que
      tenham o valor sob análise mais significativo em relação ao orçamento.
    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 9,8%. Já a nota varia de
      1 a 10. </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I2'">
    <div class="modal-titulo">
      I2 - Quartil da população atingida - Tipo B
    </div>
    <p>É uma proxy para indicar o tamanho da população afetada pelo contrato. Será atribuída maior
      prioridade às solicitações que afetem a maior parcela da população, utilizando como referência
      os dados populacionais mais atualizados de cada município contratante, divulgados pelo
      Instituto Brasileiro de Geografia e Estatística.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 2,6%. Já a nota varia de
      1 a 10. </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I3'">
    <div class="modal-titulo">
      I3 - Área do objeto - Tipo B
    </div>
    <p>
      É atribuída maior prioridade às solicitações que versem sobre as funções de governo
      favorecidas por tratamento prioritário ou munidas das garantias fundamentais atribuídas pela
      Constituição e demais normas.
    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 5,2%. Já a nota varia de
      4 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I4'">
    <div class="modal-titulo">
      I4 - Origem da informação - Tipo B
    </div>
    <p>
      É atribuída maior prioridade às solicitações de iniciativa dos órgãos de execução do MPRJ ou
      baseados em denúncias externas identificadas, em detrimento daqueles pautadas em denúncias
      anônimas.

    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 2,0%. Já a nota varia de
      1 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I5'">
    <div class="modal-titulo">
      I5 - IDH - Tipo B
    </div>
    <p>
      É dada maior prioridade às solicitações que tratem de municípios com a pior classificação de
      IDH (Índice de Desenvolvimento Humano), portanto, com maior carência de serviços públicos, em
      tese.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 1,9%. Já a nota varia de
      1 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I6'">
    <div class="modal-titulo">
      I6 - Resultado da apreciação - Tipo B
    </div>
    <p>
      Será atribuída maior prioridade às solicitações que tratem de entes federativos (Governo do
      Estado e municípios) que apresentaram contas de governo com sugestão contrária à aprovação
      pelos tribunais de contas, relativas ao exercício de realização do ato objeto da investigação.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 3,5%. Já a nota varia de
      5 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I7'">
    <div class="modal-titulo">
      I7 - Histórico de dano - Tipo B
    </div>
    <p>
      Será atribuída maior prioridade às solicitações cujo(s) contratado(s) apresente(m) CNPJ
      envolvido em processos nos quais já tenham sido apurados danos pelo GATE, mesmo que com
      contratante diverso e a qualquer tempo.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 8,1%. Já a nota varia de
      1 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I8'">
    <div class="modal-titulo">
      I8 - Indicador de Oportunidade - Tipo B
    </div>
    <p>
      Será atribuida prioridade às solicitações cujos atos e contratos investigados ainda estejam em
      andamento ou encerrados há pelo menos 05 (cinco) anos da data da solicitação ao GATE.
      Na eventualidade da solicitação de análise contemplar mais de um termo contratual (contrato
      e/ou aditivos), será considerada para o indicador de Oportunidade a data do termo mais antigo
      sob análise.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 17,0%. Já a nota varia de
      1 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I9'">
    <div class="modal-titulo">
      I9 - Indicador de Materialidade - Tipo B
    </div>
    <p>
      Utiliza como referência o valor do ato/contrato investigado para atribuir prioridade às
      solicitações que tenham maior valor sob análise.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é 16,5%. Já a nota varia de 1
      a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I10'">
    <div class="modal-titulo">
      I10 - Existência de prazo - Tipo B
    </div>
    <p>
      Será atribuída maior prioridade às solicitações para as quais tenham sido determinados prazos
      legais para conclusão ou andamento.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 18,7%. Já a nota varia de
      4 a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I11'">
    <div class="modal-titulo">
      I11 - Solicitação oriunda - Tipo B
    </div>
    <p>
      Será atribuída maior prioridade às solicitações oriundas dos Grupos Temáticos Temporários e
      demais atuações especializadas do MPRJ, em atendimento às respectivas resoluções de criação de
      tais grupos.


    </p>
    <p>
      O peso desse indicador na composição final na nota de impacto é de 3,7%. Já a nota varia de 8
      a 10.
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I12'">
    <div class="modal-titulo">
      I12 - Apuração - Tipo B
    </div>
    <p>
      Será atribuída maior prioridade às solicitações nas quais já tenham sido inseridas as
      informações relativas aos pagamentos. Em que pese o GATE admitir processos que tenham por
      finalidade exclusiva a apuração do sobrepreço, será dada prioridade àqueles em que a aferição
      do superfaturamento (dano) é possível, portanto, selecionando os expedientes em que o GATE
      conseguirá municiar de forma mais efetiva a promotoria na caracterização da improbidade
      administrativa.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 9,2%. Já a nota varia de
      5 a 10;
    </p>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_B_I13'">
    <div class="modal-titulo">
      I13 - Atribuição de Origem - Tipo B
    </div>
    <p>
      Será atribuída maior prioridade às solicitações oriundas das promotorias penais. A proposta é
      de favorecer a estratégia de persecução pela via penal, em alternativa à via da improbidade
      (cível), pois embora a ação penal não proporcione maior urgência à análise (em tese), envolve
      penalidades mais contundentes quando comparada à ação civil pública.


    </p>
    <p>
      O peso desse indicador na composição final do índice de impacto é de 1,8%. Já a nota varia de
      8 a 10.
    </p>
  </ng-container>



  <!--TIPO C-------------------------------------------------------------------- -->
  <ng-container *ngIf="data.content === 'tipo_C_tipo_penal'">
    <div class="modal-titulo">
      Tipo penal em investigação - Tipo C
    </div>
    <p>Considera qual crime foi cometido e está em investigação. Foram utilizadas as penas máximas
      cominadas no Código Penal para cada tipo penal, incluindo eventuais qualificadoras ou causas
      de aumento de pena.
    </p>
    <p>
      Este critério representa 12,8% no índice de gravidade do crime e varia de 1 (lesão
      corporal leve e culposa) a 20 (homicídio qualificado e estupro com resultado morte).
    </p>

  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_C_quantidade_vitimas'">
    <div class="modal-titulo">
      Quantidade de vítimas - Tipo C
    </div>
    <p>É um critério utilizado para diferenciar crimes da mesma espécie. Logo, quanto maior o número
      de vítimas, maior a nota de prioridade recebida, pois mais grave o(s) crime(s) cometido(s).
      Este critério representa 5,2% do índice de gravidade do crime. As notas variam conforme a
      tabela abaixo:
    </p>

    <div class="table">
      <div class="row header">
        <div class="cell left-column">Número de vítimas</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">4 ou mais</div>
        <div class="cell right-column">20</div>
      </div>
      <div class="row">
        <div class="cell left-column">3</div>
        <div class="cell right-column">18</div>
      </div>
      <div class="row">
        <div class="cell left-column">2 </div>
        <div class="cell right-column">16</div>
      </div>
      <div class="row">
        <div class="cell left-column">1</div>
        <div class="cell right-column">12</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_C_qualificador_vitimas'">
    <div class="modal-titulo">
      “Qualificador” da vítima - Tipo C
    </div>
    <p>A lei penal confere maior gravidade a crimes cometidos contra alguns grupos de pessoas em
      razão das suas características. Este critério compõe 26% no índice de gravidade do crime. Para
      fins de cálculo de prioridade, foram considerados os seguintes segmentos:
    </p>

    <div class="table">
      <div class="row header">
        <div class="cell left-column">“Qualificador” da vítima</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">Criança, Idoso, Mulher e PCD</div>
        <div class="cell right-column">20</div>
      </div>
      <div class="row">
        <div class="cell left-column">Agente de Segurança Pública (ou parentes no exercício de
          função)</div>
        <div class="cell right-column">16</div>
      </div>
      <div class="row">
        <div class="cell left-column">Outros</div>
        <div class="cell right-column">12</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.content === 'tipo_C_contemporaneidade'">
    <div class="modal-titulo">
      Contemporaneidade - Tipo C
    </div>
    <p>As notas variam conforme a tabela abaixo:</p>

    <div class="table">
      <div class="row header">
        <div class="cell left-column">Tempo transcorrido desde o fato</div>
        <div class="cell right-column">Nota</div>
      </div>
      <div class="row">
        <div class="cell left-column">Até 6 meses</div>
        <div class="cell right-column">20</div>
      </div>
      <div class="row">
        <div class="cell left-column">Entre 6 meses e 1 ano</div>
        <div class="cell right-column">18</div>
      </div>
      <div class="row">
        <div class="cell left-column">Entre 1 e 2 anos</div>
        <div class="cell right-column">16</div>
      </div>
      <div class="row">
        <div class="cell left-column">Entre 2 e 4 anos</div>
        <div class="cell right-column">12</div>
      </div>
      <div class="row">
        <div class="cell left-column">Mais de 4 anos</div>
        <div class="cell right-column">10</div>
      </div>
    </div>
  </ng-container>

  <!-- Add more content blocks as needed -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" (click)="close()">Fechar</button>
</mat-dialog-actions>
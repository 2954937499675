import { Component, OnInit, Inject } from '@angular/core';

import { SatDetalhesService } from './sat-detalhes.service';
import { SatDatabaseService } from '../../../shared/services/sat-database.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import { Router } from '@angular/router';
import { ListFilesComponent } from '../list-files/list-files.component';




@Component({
  selector: 'app-sat-detalhes',
  templateUrl: './sat-detalhes.component.html',
  styleUrls: ['./sat-detalhes.component.scss']
})
export class SatDetalhesComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    public satDetalhesService: SatDetalhesService,
    private satDatabaseService: SatDatabaseService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SatDetalhesComponent>,
    public dialog: MatDialog,
    public router: Router
  ) { }
  spinnerAnexos = false
  spinner = true
  sat: any = {};
  sateDk: any;
  solAnaliseTecnicaAnexoList: any;
  logadoIsSolicitante: any = false;
  showAtualizar: any = false;

  ngOnInit(): void {
    this.spinner = true
    this.sateDk = this.satDetalhesService.getSelectedSat()
    this.logadoIsSolicitante = this.sateDk.logadoIsSolicitante
    this.getFormEditar()
    // this.getAnexos()
  }



  getFormEditar() {
    this.spinner = true
    this.satDatabaseService.getSatDetalhes(this.sateDk.sateDk).subscribe(sateDB => {
      //  this.downloadAllFile()
      this.satDetalhesService.setSatFormEditar(sateDB)
      this.sat = sateDB
      this.spinner = false
    });
  }

  openExternalUrl(url: any) {
    window.open(url, '_blank');
  }

  downloadAllFile() {
    this.satDatabaseService.downloadAllFile(this.sateDk.sateDk).subscribe(url => {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank'; // Open the file in a new tab
      link.download = ''; // Optional: specify a default filename for the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  abreBase64(url: any, extension: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open the file in a new tab
    link.download = ''; // Optional: specify a default filename for the downloaded file
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    this.openSnackBar("Seu arquivo será enviado para a pasta de Downloads do seu computador")
    //    let contentType: string;

    /*   switch (extension.toLowerCase()) {
        case 'pdf':
          contentType = 'application/pdf';
          break;
        case 'png':
          contentType = 'image/png';
          break;
        case 'jpg':
        case 'jpeg':
          contentType = 'image/jpeg';
          break;
        case 'gif':
          contentType = 'image/gif';
          break;
        case 'zip':
          contentType = 'application/zip';
          break;
        case 'doc':
          contentType = 'application/msword';
          break;
        case 'docx':
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'xls':
          contentType = 'application/vnd.ms-excel';
          break;
        case 'xlsx':
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        default:
          console.error(`Unsupported file extension: ${extension}`);
          return;
      } */



    /*  if (contentType) {
       console.log(`Content Type: ${contentType}`);
     } else {
       console.error(`Unsupported file extension: ${extension}`);
     }

     var byteCharacters = atob(arquivo);
     var byteNumbers = new Array(byteCharacters.length);
     for (var i = 0; i < byteCharacters.length; i++) {
       byteNumbers[i] = byteCharacters.charCodeAt(i);
     }
     var byteArray = new Uint8Array(byteNumbers);
     var file = new Blob([byteArray], { type: contentType + ';base64' });
     var fileURL = URL.createObjectURL(file);
     window.open(fileURL); */
  }
  openSnackBar(msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 20000,
    });
  }

  getAnexos() {
    this.spinnerAnexos = true
    if (this.sat) {
      this.satDatabaseService.getAnexosSatsByDk(this.sat.sateDk).subscribe(json => {
        this.solAnaliseTecnicaAnexoList = json;
        this.spinnerAnexos = false
      });
    }
  }

  openDialog() {

    const dialogRef = this.dialog.open(UploadFilesComponent, {
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',//you can adjust the value as per your view
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if (result !== undefined && result.event === 'reload') {
        if (result.solAnaliseTecnicaAnexoList !== null) {
          this.getFormEditar()
        } else {
          this.spinner = true
          this.openSnackBar("Alguns arquivos ainda estão sendo processados.  Por favor, caso não os encontre na lista neste momento, tente carregar a SAT novamente em alguns minutos.  Não repita o upload antes dessa ação.")
          setTimeout(() => {
            this.showAtualizar = true
            this.getFormEditar()
          }, 20000);
        }
      }
    });
    dialogRef.backdropClick().subscribe((result: any) => {
      console.log(result)
      dialogRef.close();
    })
  }

  openListFilesDialog() {

    const dialogRef = this.dialog.open(ListFilesComponent, {
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',//you can adjust the value as per your view
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if (result !== undefined && result.event === 'reload') {
        if (result.solAnaliseTecnicaAnexoList !== null) {
          this.getFormEditar()
        } else {
          this.spinner = true
          setTimeout(() => {
            this.showAtualizar = true
            this.getFormEditar()
          }, 20000);
        }
      }
    });
    dialogRef.backdropClick().subscribe((result: any) => {
      console.log(result)
      dialogRef.close();
    })
  }



  excluirSATDialog() {
    const dialogRef = this.dialog.open(ExcluirDialog, {
      disableClose: true,
      autoFocus: false,

      data: {},
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if (result === true) {
        this.excluirSAT()
      }
    });
    dialogRef.backdropClick().subscribe((result: any) => {
      console.log(result)
      dialogRef.close();
    })
  }



  editarSAT(step: any) {

    console.log(step)
    let stepToLoad = step
    if (step === null) {
      stepToLoad = 'todos'
    }
    this.satDetalhesService.editarStepToLoad = stepToLoad
    this.dialogRef.close({ event: 'editar', sate: this.sat });
    this.router.navigateByUrl('/sat-editar');
  }

  excluirSAT() {
    this.satDatabaseService.excluirSat(this.sat.sateDk).subscribe({
      next: data => {
        console.log(data)
        this.dialogRef.close({ event: 'delete', sateDk: this.sat.sateDk });
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }


  submeterSat() {
    this.satDatabaseService.submeterSat(this.sat.sateDk).subscribe({
      next: data => {
        this.dialogRef.close({ event: 'reload', sateDk: this.sat.sateDk, novaSAT: data });
      },
      error: error => {

        console.error('There was an error!', error);
      }
    });

  }
}

@Component({
  selector: 'excluir-dialog',
  templateUrl: 'excluir-dialog.html',
})
export class ExcluirDialog { }

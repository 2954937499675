import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
@Component({
  selector: 'app-prioridade',
  templateUrl: './prioridade.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class PrioridadeComponent implements OnInit {
  prioridades: any = [];
  showPrioridadeExcep = false;
  showDataLimiteAtend = false;

  optPrioridadesArr: any = [];
  optPrefLegaisArr: any = [];

  spinnerPrioridades = false;
  spinnerPrefLegais = false;
  minDate: any = Date;


  constructor(public satFormService: SatFormService) {


    // Example usage:
    const today = new Date();
    this.minDate = this.addWeekdays(today, 10);
    const minDate20Weekdays = this.addWeekdays(today, 20);

  }

  addWeekdays(startDate: any, numWeekdays: any) {
    let weekdaysToAdd = numWeekdays - 1; // Subtracting 1 because the start date is also considered

    let currentDate = new Date(startDate);

    while (weekdaysToAdd > 0) {
      currentDate.setDate(currentDate.getDate() + 1);

      // Check if the current day is not a weekend (Saturday or Sunday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        weekdaysToAdd--;
      }
    }

    return currentDate;
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loadPrioridades();
    this.loadPrefLegais();
    this.satFormService.checkPreFormToNavigate();
  }

  loadPrioridades(): void {
    if (this.satFormService.prioridadesArr.length === 0) {
      this.spinnerPrioridades = true;
    }
    this.satFormService.prioridadesLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.optPrioridadesArr = this.satFormService.prioridadesArr.sort((a: any, b: any) => a.tpplNrOrdem - b.tpplNrOrdem);

        setTimeout(() => {
          this.spinnerPrioridades = false;
          this.checkSelectedPrioridade();
          scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000);
      }
    });
  }

  loadPrefLegais(): void {
    if (this.satFormService.prefLegaisArr.length === 0) {
      this.spinnerPrefLegais = true;
    }

    this.satFormService.prefLegaisLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.optPrefLegaisArr = this.satFormService.prefLegaisArr
          .sort((a: any, b: any) => b.plegNrOrdem - a.plegNrOrdem)
          .filter((item: any) => item.plegDk !== 1);

        setTimeout(() => {
          this.checkSelectedPrefLegalObj();
          this.spinnerPrefLegais = false;
        }, 1000);
      }
    });
  }

  checkSelectedPrefLegalObj(): void {
    const selectedPref = this.optPrefLegaisArr.find((pref: any) => pref.plegDk === this.satFormService.preForm.prefLegalObj.plegDk);
    if (selectedPref) {
      this.satFormService.preForm.prefLegalObj = selectedPref;
    }
  }


  prefChange(event: any) {
    if (
      this.satFormService.preForm.prefLegalObj !== null &&
      event === this.satFormService.preForm.prefLegalObj.plegDk

    ) {
      setTimeout(() => {
        this.satFormService.preForm.prefLegalObj = { plegDk: 1 };
      }, 300);
    }


    this.satFormService.checkPreFormToNavigate()
  }
  resetPrior() {
    this.satFormService.preForm.atendPrioritarioObj.exceptPrioridade = null
    this.satFormService.preForm.atendPrioritarioObj.dtPrazoPrior = null
    this.satFormService.preForm.atendPrioritarioObj.tipoPrazo = null
    this.satFormService.checkPreFormToNavigate()
  }
  priorChange(event: any) {

    if (
      this.satFormService.preForm.atendPrioritarioObj.tipoPrazo !== null &&
      event === this.satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplDk
    ) {
      setTimeout(() => {
        this.satFormService.preForm.atendPrioritarioObj.tipoPrazo = null;
        this.satFormService.checkPreFormToNavigate()
      }, 300);
    }
    if (this.satFormService.preForm.atendPrioritarioObj.tipoPrazo !== null && this.satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplDk !== 4) {
      this.satFormService.preForm.atendPrioritarioObj.exceptPrioridade = null
    }
    setTimeout(() => {
      this.satFormService.checkPreFormToNavigate()
    }, 600);

  }

  checkSelectedPrioridade(): void {
    if (this.satFormService.preForm.atendPrioritarioObj.tipoPrazo !== null && this.satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplDk !== null) {
      const selectedPrioridade = this.optPrioridadesArr.find((prioridade: any) => prioridade.tpplDk === this.satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplDk);
      if (selectedPrioridade) {
        this.satFormService.preForm.atendPrioritarioObj.tipoPrazo = selectedPrioridade;
      }
    }
  }

  /* setPrior(optPrioridadesArr: []) {
    this.showPrioridadeExcep = false;
    this.showDataLimiteAtend = false;
    for (const item of optPrioridadesArr) {
      if (item['tpplDk'] === 7) {
        this.showPrioridadeExcep = true;
      }
      if (
        item['tpplDk'] === 4 ||
        item['tpplDk'] === 5 ||
        item['tpplDk'] === 6 ||
        item['tpplDk'] === 7
      ) {
        this.showDataLimiteAtend = true;
      } else {
        this.satFormService.preForm.atendPrioritarioObj.dtPrazoPrior = null;
      }
    }
    this.satFormService.checkPreFormToNavigate()
  } */
}

<h1 mat-dialog-title>Editar documento</h1>

<div mat-dialog-content>
  <!--     EDITAR/CRIAR SERVICO TECNICO   -----------------------------------------------------------------    -->
  <div
       style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px;">
    <!-- First button aligned to the start (left) -->
    <button mat-stroked-button color="accent" (click)="cancelDialog()">
      <mat-icon>arrow_back</mat-icon>
      Voltar
    </button>

    <!-- Second button aligned to the end (right) -->
    <button [disabled]="editingDocumento.dostDsDocumento === '' ||  editingDocumento.dostNmDocumento === '' || editingDocumento.dostDsDocumento === null ||  editingDocumento.dostNmDocumento === null || nomeDuplicado === true"
            mat-flat-button color="accent"
            style="margin-right: 20px;"
            (click)="saveEditDocumento('UPDATE')">Salvar alterações</button>

  </div>
  <div class="borda">


    <div style="margin-bottom: 30px; margin-top: 20px;">
      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label>Nome do Documento</mat-label>

        <textarea rows="3" matInput (ngModelChange)="compareObjectsByName()"
                  [(ngModel)]="editingDocumento.dostNmDocumento">       </textarea>
      </mat-form-field>
    </div>
    <div style="margin-bottom: 30px;">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Descrição do Documento</mat-label>
        <textarea matInput (ngModelChange)="compareObjectsByName()"
                  [(ngModel)]="editingDocumento.dostDsDocumento"
                  rows="5"></textarea>
      </mat-form-field>
    </div>


  </div>


  <!-- Add other fields as needed, e.g., description, status, etc. -->
</div>
export interface User {
  [prop: string]: any;
  activeRole?: string;
  appId?: string;
  codigoOrgao?: number;
  cpf?: string;
  exp?: number;
  features?: any;
  iat?: number;
  jti?: string;
  matricula?: string;
  name?: string;
  nomeOrgao?: string;
  roles?: any;
  sub?: string;
  ticket?: string;
  avatar?: string;
}

export interface Token {
  [prop: string]: any;

  access_token: string;
  token_type?: string;
  expires_in?: number;
  exp?: number;
  refresh_token?: string;
}

<div>


  <div class="matero-page-header-inner" style="    margin-top: 30px;">
    <h3 class="matero-page-title criterios-title">Critérios de prioridade Tipo C</h3>
  </div>

  <p>
    O tipo de priorização C será utilizado para atender à ordem de atendimento das solicitações
    referentes ao tema Medicina Legal.
  </p>

  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>news</mat-icon></span>
    <div>Nota Geral de Prioridade (NG) Tipo C</div>
  </div>

  <p>
    Será definida pela relação entre os índices de gravidade do crime e de contemporaneidade da
    solicitação.
  </p>

  <div class="formula">
    <i> NG<sub>C</sub></i> = 100 +
    <div class="frac">
      <span>72</span>
      <span class="symbol">/</span>
      <span class="bottom">10.528</span>

    </div>


    [(<i>índicedegravidadecrime<sub>C</sub></i>) +
    (<i>índicedecontemporaneidade<sub>C</sub></i>) - 20]
  </div>

  <p>A fórmula acima permite que as notas mínimas e máximas do Tipo C apresentem intervalo
    equivalente aos das notas das priorizações Tipo A e Tipo B.</p>
  <p>A nota assumirá um número entre 28 a 100, onde a maior nota indica a maior prioridade.</p>


  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>warning</mat-icon></span>
    <div>Índice de Gravidade de Crime - Tipo C</div>
  </div>
  <p>O Índice de Gravidade de Crime é formado pela soma dos indicadores de custo de análise.</p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">Para determinar a gravidade de um crime
      diversos critérios podem ser utilizados. Para fins de priorização, o GATE considerou o tipo
      penal em investigação, a quantidade de vítimas do fato e as características destas
      vítimas.</span>
  </div>

  <p>O Índice de Gravidade de Crime corresponde a 44% da nota de prioridade.</p>




  <p><strong>Indicadores de Gravidade de Crime</strong></p>

  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoCDialog('tipo_C_tipo_penal')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Tipo penal em investigação</span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoCDialog('tipo_C_quantidade_vitimas')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Quantidade de vítimas</span>
  </div>
  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoCDialog('tipo_C_qualificador_vitimas')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">“Qualificador” da vítima</span>
  </div>



  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1F2022;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>hourglass_empty</mat-icon></span>
    <div>Índice de Contemporaneidade - Tipo C</div>
  </div>
  <p>O Índice de Contemporaneidade é formado por uma nota baseada no tempo transcorrido desde o
    fato. </p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">“O tempo que passa é a verdade que foge na
      apuração de um crime”. A frase de Edmond Locard retrata uma verdade nas investigações
      criminais: quanto mais tempo se passou desde a ocorrência do fato, menores são as chances de
      sucesso na investigação; logo, menor as chances de impacto.</span>
  </div>

  <p>O Índice de Contemporaneidade corresponde a 56% da nota de prioridade.</p>




  <p><strong>Indicador de Contemporaneidade</strong></p>

  <div class="destaque-accent" style="cursor: pointer;"
       (click)="tipoCDialog('tipo_C_contemporaneidade')">
    <span style="min-width: 26px;"> <mat-icon
                class="destaque-accent-icon material-symbols-outlined"
                mat-list-icon>label</mat-icon></span>
    <span style="font-size: 14px;   padding-top: 6px;">Contemporaneidade</span>
  </div>






</div>
<!--  DEFINIÇÃO  DUVIDA TECNICA************************************************* -->
<div style="min-height: 165px; display: block">


  <div class="mat-form-box">

    <mat-form-field style="width: 100%;" appearance="fill">
      <textarea
                [(ngModel)]="satFormService.preForm.objetivoSolicitacaoStr"
                matInput
                rows="7"
                (input)="checkTextareaContent()"
                rows="7" maxlength="4000"
                placeholder="Descreva de forma clara e suficiente o objetivo da sua solicitação e/ou as dúvidas a serem esclarecidas pelo técnico do GATE"></textarea>
      <mat-hint
                align="end">{{
        satFormService.preForm?.objetivoSolicitacaoStr === null ? 0 :
        satFormService.preForm?.objetivoSolicitacaoStr.length}}
        / 4000</mat-hint>
    </mat-form-field>
  </div>


</div>
import { User } from './interface';

export const admin: User = {
  id: 1,
  name: 'Charles Robbs',
  email: '',
  avatar: './assets/images/avatar.PNG',
};

export const guest: User = {
  name: 'unknown',
  email: 'unknown',
  avatar: './assets/images/avatar-default.jpg',
};

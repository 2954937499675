<div class="page-container">


  <p>Em caso de problemas técnicos ou dúvidas no preenchimento, entre em contato através do
    e-mail: <a href="mailto:gate.novasat.suporte@mprj.mp.br">gate.novasat.suporte@mprj.mp.br</a> .
  </p>




  <div class="matero-page-header-inner">
    <h3 class="matero-page-title  criterios-title">Perguntas frequentes</h3>

  </div>



  <div class="search-container">
    <div class="buscar">
      <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
        <input matInput [(ngModel)]="searchTerm" placeholder="Buscar" (ngModelChange)="filterFAQ()">
        <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-button matSuffix mat-icon-button aria-label="Search">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let faq of filteredFAQArr; let i = index" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="numero">{{i+1}}</span><span>{{faq.pergunta}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider></mat-divider>
          <p style="padding-left: 16px;"> {{faq.resposta}}</p>
        </mat-expansion-panel>
      </mat-accordion>

    </div>



  </div>
<!--  DEFINIÇÃO  Complementariedade************************************************* -->
<div style="min-height: 165px; display: block">

  <div style="  margin-bottom: 20px;">
    Existe manifestação técnica de órgão com poder de polícia ou regulatório sobre os fatos
    submetidos à análise (ou perito, no caso de processo judicial)? <mat-icon class="info"
              (click)="satFormService.abrirProtocGestao()"
              matTooltipClass="tooltip-info"
              matTooltip="Art. 9º, inciso II, da Resolução GPGJ nº 2.197/18 c/c art. 3º e 16, inciso II, da OS nº 01/23.  Clique no ícone para abrir a OS."
              aria-label="Info">info</mat-icon>
  </div>

  <div>
    <button mat-stroked-button class="bt-opcoes"
            (click)="removeFiles()"

            [ngClass]="{'selected-button': satFormService.preForm.excepcionalidadeObj.inManifTecAnterior === 'N'}">
      Não
    </button>

    <button mat-stroked-button class="bt-opcoes"
            (click)="satFormService.preForm.excepcionalidadeObj.inManifTecAnterior = 'S'; satFormService.checkPreFormToNavigate()"
            [ngClass]="{'selected-button': satFormService.preForm.excepcionalidadeObj.inManifTecAnterior === 'S'}">
      Sim
    </button>
  </div>
  <div *ngIf="satFormService.preForm.excepcionalidadeObj.inManifTecAnterior === 'S'">
    <p>Manifestação técnica ou laudo pericial a ser complementado</p>
    <app-file-dnd [multipleFiles]="true" [saaxInObrigatorio]="false" [isSATFile]="true"
                  (newItemEvent)="satFormService.checkPreFormToNavigate()"
                  [tpaxDk]="2"></app-file-dnd>
  </div>
  <div *ngIf="satFormService.preForm.excepcionalidadeObj.inManifTecAnterior === 'N'"
       class="mat-form-box">
    <p style="font-size: 12px;">
      Conforme artigos 9º, inciso II, e 10 da Resolução GPGJ nº 2.197/18 e artigo 3º e 16, inciso
      II, da OS nº 01/23, o GATE só atua de forma complementar aos demais órgãos com poder
      regulatório ou de polícia. Isso significa que é imprescindível a prévia manifestação técnica
      do órgão competente (ou do perito, no caso de processo judicial) para que o GATE possa
      elaborar a sua informação técnica. Em não havendo tal documento, este órgão de apoio só atuará
      em casos excepcionais devidamente justificados.
    </p>

    <p>Realize o upload do despacho E/OU informe a excepcionalidade que justifica a análise do GATE
      diante da ausência de manifestação técnica prévia do órgão responsável </p>

    <app-file-dnd [multipleFiles]="true" (newItemEvent)="satFormService.checkPreFormToNavigate();"
                  [saaxInObrigatorio]="false" [tpaxDk]="5"
                  [isSATFile]="true"
                  saaxDsObservacao="Justificativa da excepcionalidade">
    </app-file-dnd>

    <div style="padding: 6px;padding-top:0px; text-align: center;">
      <h3>E/OU</h3>
    </div>
    <mat-form-field style="width: 100%;" appearance="fill">


      <textarea
                [(ngModel)]="satFormService.preForm.excepcionalidadeObj.dsJustifAusenciaCompl"
                matInput
                (input)="satFormService.checkPreFormToNavigate()"
                rows="7" maxlength="600"
                placeholder="Informe a excepcionalidade que justifica a análise do GATE diante da ausência de manifestação técnica prévia do órgão responsável"></textarea>
      <mat-hint
                align="end">{{
        satFormService.preForm.excepcionalidadeObj?.dsJustifAusenciaCompl === null ? 0 :
        satFormService.preForm.excepcionalidadeObj?.dsJustifAusenciaCompl.length
        }}
        / 600</mat-hint>
    </mat-form-field>
  </div>
</div>
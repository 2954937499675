import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';

@Component({
  selector: 'app-documentos-upload',
  templateUrl: './documentos-upload.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class DocumentosUploadComponent implements OnInit {
  documentosObrigatoriosArr: any = [];
  documentosDesejaveisArr: any = [];
  documentosArr: any = [];

  obrigatoriosArrFinal: any = [];
  desejaveisArrFinal: any = [];

  localAreasArr: any = []

  constructor(public satFormService: SatFormService) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.satFormService.checkPreFormToNavigate()

    let uniqueTemas = this.removeDuplicates([...this.satFormService.preForm.areasArr])

    console.info(this.removeDuplicates(uniqueTemas))
    console.info(this.satFormService.preForm.areasArr)

    for (const area of uniqueTemas) {
      for (const tema of area.temas) {
        for (const servico of tema.servicos) {
          for (const documento of servico.docsObrigatorios) {
            this.documentosObrigatoriosArr.push(documento)
          }
          for (const documento of servico.docsDesejaveis) {
            this.documentosDesejaveisArr.push(documento)
          }
        }
      }
    }
    // Now, requiredDocumentosArr contains all required documents, and desirableDocumentosArr contains all desirable documents

    const obgrUnique = this.documentosObrigatoriosArr.filter((item: any, index: any, self: any) => {
      return index === self.findIndex((i: any) => i.dostDk === item.dostDk);
    });
    const desejUnique = this.documentosDesejaveisArr.filter((item: any, index: any, self: any) => {
      return index === self.findIndex((i: any) => i.dostDk === item.dostDk);
    });

    this.obrigatoriosArrFinal = obgrUnique;
    this.desejaveisArrFinal = desejUnique;

  }

  removeDuplicates(data: any) {
    const seen = new Set();
    const result = [];

    data.forEach((area: any) => {
      area.temas.forEach((tema: any) => {
        tema.servicos = tema.servicos.filter((servico: any) => {
          if (seen.has(servico.tmsDk)) {
            return false; // Duplicate found, filter out
          }
          seen.add(servico.tmsDk);
          return true; // Unique, keep it
        });
      });
      // Optionally remove empty temas if needed
      area.temas = area.temas.filter((tema: any) => tema.servicos.length > 0);
    });

    return data;
  }


  checkForm(event: any) {
    this.satFormService.checkPreFormToNavigate()
  }

  onFileChange(event: any, documento: any) {

    const selectedFile = event.target.files[0];
    if (selectedFile) {
      documento.fileName = selectedFile.name;
      this.satFormService.checkPreFormToNavigate()
      // You might want to handle the file upload logic here
    }
  }
}

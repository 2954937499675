import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { SatDetalhesService } from '../sat-detalhes/sat-detalhes.service';
import { SatFormService } from 'app/routes/services/sat-form.service';
import { SatValidarService } from 'app/routes/services/sat-validar.service';

import * as moment from 'moment';
import { SatDatabaseService } from '@shared/services/sat-database.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sat-editar',
  templateUrl: './sat-editar.component.html',
  styleUrls: ['./sat-editar.component.scss']
})
export class SatEditarComponent implements OnInit, OnDestroy {
  public sat: any
  public spinner = true
  private tmsDkArray: any = []
  private idsIntegraEditar: any = []
  private temasServicosLoadedSubscription: any = Subscription;
  constructor(
    private satDetalhesService: SatDetalhesService,
    private satFormService: SatFormService,
    private satValidarService: SatValidarService,
    private satDatabaseService: SatDatabaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sat = this.satDetalhesService.getSatFormEditar()
    console.log(this.sat)
    if (this.sat !== undefined) {
      this.satFormService.resetPreForm('all');
      this.satFormService.isEdicao = true
      this.sat = this.satDetalhesService.getSatFormEditar()
      console.log(this.sat)
      this.satFormService.editarSateDk = this.sat.sateSateDk
      this.preparaForm()
    } else {

      console.log(this.sat, '/sat-lista')
      this.router.navigateByUrl('/sat-lista');
    }
  }

  ngOnDestroy(): void {
    this.satFormService.currentStepIndex = 0
    this.satFormService.isEdicao = false
    this.satFormService.tmsDkArray = []
    this.satFormService.selectedServico()
    this.satFormService.resetPreForm('all');
    this.satDetalhesService.editarStepToLoad = ''
    console.log(this.satFormService.currentStepIndex, this.satFormService.preForm)
  }

  preparaForm() {

    this.satFormService.preForm = {
      satFiles: [],
      solicitanteObj: {
        nome: this.sat.usumatriculaSolicit[0].NOME,
        matricula: this.sat.usumatriculaSolicit[0].MATRICULA
      },

      procedimentoObj: {
        orgao: this.sat.sateOrgiDkList[0].NOME,
        num_DOC: this.sat.sateNumDocumento,
        tipo_DOCUMENTO: this.sat.sateTpDocumento,
        assunto_PROCESSO: this.sat.sateAssuntoProcesso,
        orgi_DK: this.sat.sateOrgiDkList[0].orgiDk,
        sate_PSSO_DK: this.sat.satePssoDkList.pssoDk,
        origem: this.sat.satePssoDkList.pssoNmSistema,
        email: this.sat.sateEmail,
        sate_CIDA_DK: null,
        cidade: null
      },
      municipioObj: this.sat.cidadeDAOList[0],
      municipiosArr: this.sat.cidadeDAOList,
      complementacaoObj: { isComplementacao: this.sat.sateInComplAnalise, docTecAnteriorObj: null },
      areasArr: [],
      areasArrDks: [],
      servicosArr: [],
      temasArrDks: [],
      excepcionalidadeObj: { inManifTecAnterior: this.sat.sateInManifTecAnterior, dsJustifAusenciaCompl: null },
      prefLegalObj: this.sat.satePlegDkList[0],
      atendPrioritarioObj: {
        exceptPrioridade: '',
        isPrioritario: this.sat.sateInPrioridade,
        tipoPrazo: null,
        dtPrazoPrescr: null,
        dtPrazoPrior: null,
      },
      objetivoSolicitacaoStr: '',
    };
    this.satFormService.allValid(true)
    this.defineCamposCondicionados()
    console.log('preparaForm', this.satFormService.preForm)


  }







  defineCamposCondicionados() {



    // DEFINE DOC
    const SATE_PSSO_DK = this.sat.satePssoDkList.pssoDk;
    if (SATE_PSSO_DK === 1) {
      this.satFormService.isIntegra = false
      this.satFormService.preForm.procedimentoObj.proc_DK = this.sat.sateDocuDk
    } else if (SATE_PSSO_DK === 2) {
      this.satFormService.isIntegra = false
      this.satFormService.preForm.procedimentoObj.proc_DK = this.sat.sateIdProtocolo
    } else if (SATE_PSSO_DK === 3) {
      this.satFormService.isIntegra = true
      this.satFormService.preForm.procedimentoObj.num_DOC = this.sat.sateNumCnmpIc
    } else if (SATE_PSSO_DK === 4) {
      this.satFormService.isIntegra = true
      this.satFormService.preForm.procedimentoObj.proc_DK = this.sat.sateTjraDk
    } else if (SATE_PSSO_DK === 5) {
      this.satFormService.isIntegra = true
      this.satFormService.preForm.procedimentoObj.num_DOC = this.sat.sateNumIp
    }
    this.satFormService.buildSteps()

    // DEFINE MUNICIPIO
    this.sat.cidadeDAOList.forEach((cidade: any) => {
      cidade.selected = true
      cidade.CIDA_DK = Number(cidade.CIDA_DK)
    });
    this.satFormService.preForm.municipiosArr = this.sat.cidadeDAOList
    if (this.satFormService.preForm.municipiosArr.length > 0) {
      this.satFormService.preForm.municipioObj = this.sat.cidadeDAOList[0]
    } else {

      let municipioObj = {
        "CIDA_NM_CIDADE": "ESTADO DO RIO DE JANEIRO",
        "CIDA_DK": 0,
        "selected": true
      }
      this.satFormService.preForm.municipioObj = municipioObj
      this.satFormService.preForm.municipiosArr.push(municipioObj)

    }

    // DEFINE COMPLEMENTACAO
    if (this.sat.sateInComplAnalise === 'S') {
      this.sat.sateItcnDkList[0].num_IT = this.sat.sateItcnDkList[0].NUM_IT
      this.sat.sateItcnDkList[0].mprj = this.sat.sateItcnDkList[0].MPRJ
      this.satFormService.preForm.complementacaoObj.docTecAnteriorObj = this.sat.sateItcnDkList[0]
    }


    // DEFINE AREA _ SERVTEC _ DOCS

    const extractTmsDk = (data: any) => {
      const tmsDkArray: any = [];

      const traverse = (obj: any) => {
        if (Array.isArray(obj)) {
          obj.forEach(item => traverse(item));
        } else if (typeof obj === 'object' && obj !== null) {
          if (obj.hasOwnProperty('tmsDk')) {
            tmsDkArray.push(obj.tmsDk);
          }
          Object.values(obj).forEach(value => traverse(value));
        }
      };

      traverse(data);

      return tmsDkArray;
    };


    //DEFINE IDs Integra
    if (this.satFormService.isIntegra === true) {
      this.idsIntegraEditar = this.sat.docTemaServTecSatList
    }

    const input = this.sat.ListTemas
    console.log(input)
    this.tmsDkArray = extractTmsDk(input);



    //DEFINE COMPLEMENTARIDADE
    console.log(this.sat.sateInManifTecAnterior);
    console.log(this.sat.sateDsJustifAusenciaCompl);
    if (this.sat.sateInManifTecAnterior === "N" && this.sat.sateDsJustifAusenciaCompl !== undefined) {
      this.satFormService.preForm.excepcionalidadeObj.dsJustifAusenciaCompl = this.sat.sateDsJustifAusenciaCompl
    }

    //DEFINE PRIORIDADE
    if (this.sat.sateInPrioridade === "S") {
      this.satFormService.preForm.atendPrioritarioObj.isPrioritario = "S"
      this.satFormService.preForm.atendPrioritarioObj.tipoPrazo = this.sat.sateTpplDk


      if (this.sat.tpPrioridadeAnalise[0] !== undefined) {
        this.satFormService.preForm.atendPrioritarioObj.tipoPrazo = this.sat.tpPrioridadeAnalise[0]
      }

      if (this.sat.sateDtLimite && this.sat.sateDtLimite !== null) {
        this.satFormService.preForm.atendPrioritarioObj.dtPrazoPrior = moment(this.sat.sateDtLimite)
      }

      if (this.satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplDk === 4 && this.sat.sateDsJustPrioridade) {
        this.satFormService.preForm.atendPrioritarioObj.exceptPrioridade = this.sat.sateDsJustPrioridade
      }

    }

    //DEFINE OBJETIVO DA SOLICITACAO

    if (this.sat.sateDsDuvida) {
      this.satFormService.preForm.objetivoSolicitacaoStr = this.sat.sateDsDuvida
    }

    //DEFINE ORDEM DOS ARQUIVOS
    if (this.sat.solAnaliseTecnicaAnexoList && this.sat.solAnaliseTecnicaAnexoList.length > 0) {

      this.satFormService.preForm.satFilesInEdicao = this.sat.solAnaliseTecnicaAnexoList
      const highestSaaxNrOrdem = this.sat.solAnaliseTecnicaAnexoList.reduce((max: any, item: any) => item.saaxNrOrdem > max ? item.saaxNrOrdem : max, this.sat.solAnaliseTecnicaAnexoList[0].saaxNrOrdem);

      this.satFormService.editarSatAnexoNextOrder = highestSaaxNrOrdem + 1
      console.log(highestSaaxNrOrdem); // Output: 101

    } else {
      this.satFormService.preForm.satFilesInEdicao = []
    }

    this.getAreasTemas()
    // this.spinner = false;



  }

  getAreasTemas() {
    this.satFormService.areasArr = []
    this.satDatabaseService.areasLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        let areasArr: any = this.satDatabaseService.getOriginalAreasTemas()
        console.log(areasArr)
        this.satFormService.areasArr = areasArr;
        this.selectedServico(this.satFormService.areasArr);
      }
    });

    /* this.satDatabaseService.getAreasTemas().pipe(
      take(1)  // Automatically unsubscribes after one emission
    ).subscribe((json: any) => {
      this.satFormService.areasArr = json.AreaServTec;
      console.log("carregou --- editar");
      this.selectedServico(this.satFormService.areasArr);
    });
 */
  }
  selectedServico(areasArr: any) {
    console.log("comecou")
    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let pushArea
    for (const area of areasArr) {
      //  console.log(area)
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: []
        }
        for (const servico of tema[0].servTec) {

          let preServico: any = {
            servico: servico[0].pstcNmServTec,
            DK_PARA_GRAVAR: servico[0].tmsDk,
            tmsDk: servico[0].tmsDk,

            docsObrigatorios: [],
            docsDesejaveis: []
          }

          servico['docObrigatorios'] = []
          servico['docDesejaveis'] = []


          let isInEditar = false
          isInEditar = this.checkItemInArray(servico[0].tmsDk)
          if (isInEditar === true) {
            servico.selected = true
          }
          if (servico.selected) {
            areasArrDks.push(area.ArstDk)
            temasArrDks.push(tema[0].tmanDk)
            area['selected'] = true
            tema['selected'] = true
            pushArea = true
            pushTema = true

            for (const documento of servico[0].doc) {

              let idsArrFromEditar: any = []
              for (const idIntegra of this.idsIntegraEditar) {
                if (documento.dtstDk === idIntegra.dssaDtstDk) {
                  //   console.log("igual obrgatorio")
                  idsArrFromEditar.push(idIntegra.dssaIdDocIntegra)
                }
              }

              if (idsArrFromEditar.length > 0) {
                documento['idsArr'] = idsArrFromEditar
              } else {
                documento['idsArr'] = ['']
              }

              if (documento.dtstInObrigatorio === "S") {
                preServico.docsObrigatorios.push(documento)
                servico['docObrigatorios'].push(documento);
              }
              if (documento.dtstInObrigatorio === "N") {
                preServico.docsDesejaveis.push(documento)
                servico['docDesejaveis'].push(documento);
              }
            }
            preTema.servicos.push(preServico)
          }


        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

      }

      if (pushArea === true) {
        areaTotal.push(preArea)
      }

    }
    let idsAreasArr = [...new Set(areasArrDks)];
    let idsTemasArr = [...new Set(temasArrDks)];
    this.satFormService.preForm.areasArrDks = idsAreasArr
    this.satFormService.preForm.temasArrDks = idsTemasArr
    this.satFormService.preForm.areasArr = areaTotal
    this.satFormService.temasServicosLoaded.next(true)
    this.satFormService.checkPreFormToNavigate()
    console.log("acabou ---- editar", this.satFormService.preForm.areasArr)
    this.spinner = false;
    if (this.satDetalhesService.editarStepToLoad !== 'todos') {
      this.satFormService.navigateToStepByStepName(this.satDetalhesService.editarStepToLoad)
    }


  }

  checkItemInArray = (item: any) => {
    const index = this.tmsDkArray.indexOf(item);
    if (index !== -1) {
      this.tmsDkArray.splice(index, 1); // Remove the item from the array
      return true;
    }
    return false;
  };


}

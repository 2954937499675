<div fxLayout="row wrap" style="height: 100vh;">

  <div fxFlex="100" style="background-color: #1F2022;" [fxFlex.gt-sm]="'40'">
    <div class="w-full h-full" fxLayout="row wrap">
      <div class=" m-auto">

        <img src="./assets/images/logo.png" class="matero-branding-logo-expanded"
             style="bottom: 5px;" alt="logo" />
        <span><span class="titulo-portal">PORTAL DE
            SERVIÇOS </span> <span class="titulo-gate"> DO GATE</span></span>

      </div>
    </div>

  </div>

  <div fxFlex="100" fxFlex.gt-sm="60" style="background-color: rgb(255, 255, 255);">

    <div class="w-full h-full" fxLayout="row wrap">
      <div class=" m-auto" style="max-width: 480px;">
        <div class=" login">Login</div>

        <form class="form-field-full " [formGroup]="loginForm">
          <mat-label>Login</mat-label>
          <mat-form-field color="accent" appearance="outline" style="margin-bottom: 15px;">

            <input matInput placeholder="Login" formControlName="username" required>
            <mat-error *ngIf="username.invalid">
              <span *ngIf="username.errors?.required">
                Insira um login válido
              </span>

              <span *ngIf="username.errors?.remote">
                {{ username.errors?.remote }}
              </span>
            </mat-error>
          </mat-form-field>
          <mat-label>
            <div>
              Senha
              <mat-icon class="info" matTooltipClass="tooltip-info"
                        matTooltip="SCA - Mesma senha utilizada para acesso ao contracheque"
                        aria-label="Info">info</mat-icon>
            </div>
          </mat-label>
          <mat-form-field color="accent" appearance="outline" style="margin-bottom: 15px;">
            <input matInput placeholder="Senha" type="{{ hide ? 'password' : 'text' }}"
                   formControlName="password" required>
            <button [color]="hide ? 'primary' : 'accent'" mat-icon-button matSuffix
                    (click)="togglePasswordVisibility()"
                    aria-label="Toggle password visibility">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="password.invalid">
              <span *ngIf="password.errors?.required">
                Insira uma senha válida
              </span>
              <span *ngIf="password.errors?.remote">
                {{ password.errors?.remote }}
              </span>
            </mat-error>
          </mat-form-field>

          <!--  <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-checkbox formControlName="rememberMe">{{'login.remember_me' }}
            </mat-checkbox>
          </div>
     -->
          <button class="w-full" mat-flat-button
                  color="accent"
                  [disabled]="!!loginForm.invalid" [loading]="isSubmitting"
                  (click)="login()">Entrar</button>

          <mat-error *ngIf="auth.errorLogin !== null">

            <span>
              {{ auth.errorLogin}}
            </span>
          </mat-error>


        </form>
        <!--  <div style="margin-top: 120px; font-size: 12px;">
          <p>"username":"murilo.silva","password":"w4mwh4"</p>

          <p>
            "username":"heleno.nunes","password":"gjz1ts"</p>
        </div> -->
      </div>
    </div>

  </div>

</div>
import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SatDatabaseService } from '@shared/services/sat-database.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { take } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AdminDatabaseService } from '../services/admin-database.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lista-servicos',
  templateUrl: './lista-servicos.component.html',
  styleUrls: ['./lista-servicos.component.scss']
})
export class ListaServicosComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: any = MatAccordion;
  @ViewChildren(MatExpansionPanel) expansionPanel: any = QueryList<MatExpansionPanel>;
  @ViewChildren(MatExpansionPanel) panels: any = QueryList<MatExpansionPanel>;
  areasOriginal: any = [];
  areasArr: any = []
  filteredAreas: any = [];
  searchTema = '';
  spinner = true;
  spinnerVinculos = false;

  searchGlobal = '';
  allServicosOriginal: any = []
  filteredServicos: any = [];
  multiAreas = false
  timeout: any = null;
  timeoutOpenPanel: any = null;
  panelSpinner = false

  actionType: any = null // insertServTec, updateServTec, deleteServTec, insertDoc, updateDoc, deleteDoc, vinculoTemaServ, vinculoServDoc
  viewDetails = false;
  reloadArr = false;
  selectedServTec: any = null
  editingServicoTecnico: any = null
  selectedDoc: any = null;
  editingDoc: any = null

  selectedTemasArr: any = []
  selectedServicosArr: any = []

  vinculosDkArr: any = []
  allDocumentosArr: any = []
  filteredDocuments: any = []
  searchDocumentoQuery: any = ''

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private router: Router, private satDatabaseService: SatDatabaseService, public dialog: MatDialog, private _snackBar: MatSnackBar, private adminDatabaseService: AdminDatabaseService,) { }

  ngOnInit(): void {
    this.getAreasTemas();

  }
  openSnackBar(msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
    });
  }

  crudAddServtec() {
    this.router.navigateByUrl('/crud-insert-servtec');
  }
  crudViewServicos() {
    this.router.navigateByUrl('/crud-view-servtec');
  }
  crudAddDocumentos() {

  }

  getAreasTemas() {
    this.spinner = true;
    this.satDatabaseService.areasLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        this.areasArr = this.satDatabaseService.areasArr;
        this.selectedServico();
      }
    });
  }
  selectedServico() {
    console.log("comecou")
    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let preAllDocumentosArr = []
    let pushArea
    for (const area of this.areasArr) {
      //  console.log(area)
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: [],
          checked: false,
        }
        for (const servico of tema[0].servTec) {

          let preServico: any = {
            servico: servico[0].pstcNmServTec,
            DK_PARA_GRAVAR: servico[0].tmsDk,
            tmsDk: servico[0].tmsDk,
            checked: false,
            docsObrigatorios: [],
            docsDesejaveis: []
          }

          servico['docObrigatorios'] = []
          servico['docDesejaveis'] = []


          areasArrDks.push(area.ArstDk)
          temasArrDks.push(tema[0].tmanDk)
          area['selected'] = true
          tema['selected'] = true
          pushArea = true
          pushTema = true

          for (const documento of servico[0].doc) {
            documento.checked = false
            documento.disabled = false
            preAllDocumentosArr.push(documento)
            if (documento.dtstInObrigatorio === "S") {
              preServico.docsObrigatorios.push(documento)
              servico['docObrigatorios'].push(documento);
            }
            if (documento.dtstInObrigatorio === "N") {
              preServico.docsDesejaveis.push(documento)
              servico['docDesejaveis'].push(documento);
            }
          }
          preTema.servicos.push(preServico)



        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

      }

      if (pushArea === true) {
        areaTotal.push(preArea)
      }

    }

    this.getTemas()
    console.log("acabou")
    this.allDocumentosArr = this.removeDuplicates(preAllDocumentosArr);
    this.filteredDocuments = [...this.allDocumentosArr];
    console.log("this.allDocumentosArr", this.allDocumentosArr)
  }
  removeDuplicates(array: any) {
    const uniqueObjects = new Map();

    array.forEach((item: any) => {
      const key = `${item.dostDk}`;
      if (!uniqueObjects.has(key)) {
        uniqueObjects.set(key, item);
      }
    });

    return Array.from(uniqueObjects.values()).sort((a, b) => {
      return a.dostNmDocumento.localeCompare(b.dostNmDocumento);
    });
  }

  getTemas() {
    this.spinner = true;
    let original = this.areasArr;
    console.log(original)
    for (const area of original) {
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      for (const tema of area.Temas) {
        let preTema: any = {
          checked: false,
          disabled: false,
          tema: tema[0].tmanNmTema,
          tmanDk: tema[0].tmanDk,
          servicos: []
        }

        for (const servTec of tema[0].servTec) {
          servTec[0].tmanDk = tema[0].tmanDk
          servTec[0].checked = false,
            servTec[0].disabled = false,
            this.allServicosOriginal.push(servTec[0])
          preTema.servicos.push(servTec[0])

        }

        preArea.temas.push(preTema)
      }
      //  this.sortByPstcNmServTec(preArea.temas[0].servicos)
      this.areasOriginal.push(preArea)
    }
    if (this.filteredServicos.length === 0) {
      this.filteredServicos = this.allServicosOriginal;
    }
    console.log("this.areasOriginalOriginal", this.areasOriginal)

    if (this.filteredAreas.length === 0) {
      this.filteredAreas = [...this.areasOriginal];
    }
    this.reloadArr = false
    this.spinner = false;
  }

  sortByPstcNmServTec(array: any) {
    console.log(array)
    return array.sort((a: any, b: any) => {
      if (a.pstcNmServTec < b.pstcNmServTec) {
        return -1;
      }
      if (a.pstcNmServTec > b.pstcNmServTec) {
        return 1;
      }
      return 0;
    });
  }
  filterServicosGlobal(event: any): void {
    this.panelSpinner = true
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutOpenPanel);
    const filterValue = this.searchGlobal.toLowerCase();

    const searchTerm = this.searchGlobal.toLowerCase();
    let data = this.areasOriginal;

    let _self = this
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        // Filter areas with temas containing servicos matching the search term
        const filteredAreas = data.map((area: any) => {
          const temasWithFilteredServicos = area.temas.map((tema: any) => {
            const filteredServicos = tema.servicos.filter((servico: any) =>
              servico.pstcNmServTec.toLowerCase().includes(searchTerm)
            );
            return { ...tema, servicos: filteredServicos };
          }).filter((tema: any) => tema.servicos.length > 0); // Exclude temas with empty servicos
          return { ...area, temas: temasWithFilteredServicos };
        }).filter((area: any) => area.temas.length > 0); // Exclude areas with empty temas

        _self.filteredAreas = filteredAreas;
        _self.panelSpinner = false
        _self.multiAreas = true
        _self.timeout = setTimeout(function () {
          if (searchTerm !== '') {

            _self.openAll();
          } else {
            _self.multiAreas = false
          }
        }, 1000);
      }
    }, 1000);


  }

  filterDocuments() {
    if (this.searchDocumentoQuery.trim() === '') {
      this.filteredDocuments = [...this.allDocumentosArr]; // If search is empty, show all documents
    } else {
      this.filteredDocuments = this.allDocumentosArr.filter((doc: any) =>
        doc.dostNmDocumento.toLowerCase().includes(this.searchDocumentoQuery.toLowerCase())
      );
    }
  }







  filterGlobal() {
    const filterValue = this.searchGlobal.toLowerCase();
    const filteredAreasCopy = JSON.parse(JSON.stringify(this.areasOriginal)); // Deep copy
    console.log("filterValue", filterValue)
    filteredAreasCopy.forEach((area: any) => {
      area.Temas.forEach((tema: any) => {
        //  console.log(" tema[0].servTec", tema[0].servTec)
        tema[0].servTec = tema[0].servTec.filter((serv: any) =>
          this.removeDiacritics(serv[0].pstcNmServTec.toLowerCase()).includes(filterValue)
        );
      });
    });

    setTimeout(() => {
      this.openAll()
      console.log("this.openAll()")
    }, 1000);

    this.filteredAreas = filteredAreasCopy

  }

  removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  openAll() {
    console.log("this.openAll()")
    this.panels.forEach((panel: any) => panel.open())
    this.expansionPanel.toArray().forEach((panel: any) => {
      panel.open();
    })
  }









  viewDetailsSelect(servico: any, area: any, tema: any) {
    this.actionType = null
    this.viewDetails = true
    this.selectServTec(servico, area, tema)
  }





  selectServTec(servico: any, area: any, tema: any): void {
    let docsObrigatorios: any = []
    let docsDesejaveis: any = []
    for (const doc of servico.doc) {
      if (doc.dtstInObrigatorio === 'S') {
        docsObrigatorios.push(doc)
      }
      if (doc.dtstInObrigatorio === 'N') {
        docsDesejaveis.push(doc)
      }
    }
    servico.docsObrigatorios = docsObrigatorios
    servico.docsDesejaveis = docsDesejaveis
    servico.area = area
    servico.tema = tema
    console.log(servico)

    this.selectedServTec = servico
    this.editingServicoTecnico = { ...servico }
  }




  // SERVICO TECNICO

  createServicoTecnico(area: any, tema: any) {
    this.actionType = 'insertServTec'
    this.selectedServTec = {
      area: area.area,
      tema: tema.tema,
      tmanDk: tema.tmanDk,
      "pstcDk": 0,
      "pstcDsServTec": null,
      "pstcNmServTec": null,
      "pstcInDuvida": "S",
      "pstcInInfoTec": "S"

    }
    this.editingServicoTecnico = { ...this.selectedServTec }
    console.log('selectedServTec', this.selectedServTec)
  }

  editServicoTecnico(selectedServTec: any) {
    this.actionType = 'updateServTec'
    this.viewDetails = false
    this.editingServicoTecnico = { ...this.selectedServTec }
    console.log('selectedServTec', selectedServTec)

  }
  saveEditServicoTecnico(action: any) {
    console.log('this.selectedServTec', this.selectedServTec)
    let area = this.selectedServTec.area
    let tema = this.selectedServTec.tema
    if (action === 'DELETE') {
      this.actionType = 'deleteServTec'
    }
    this.adminDatabaseService.createServico(this.editingServicoTecnico, action).subscribe(
      (event: any) => {
        console.log('Servico gravado:', event);
        if (action === 'UPDATE') {

          this.selectedServTec = { ... this.editingServicoTecnico }

          this.openSnackBar('Serviço editado com sucesso.')
        }

        if (action === 'INSERT') {
          console.log("event", event)
          for (const key in event) {
            if (event.hasOwnProperty(key)) {
              this.selectedServTec[key] = event[key]; // Update json1 with values from json2
            }
          }
          console.log('this.selectedServTec', this.selectedServTec)
          this.openSnackBar('Serviço criado com sucesso.')
        }
        if (action === 'DELETE') {
          this.selectedServTec = null
          this.openSnackBar('Serviço excluído com sucesso.')
        }

        this.reloadArr = true
        this.editingServicoTecnico = null
        this.voltar()

      },
      (error) => {
        this.editingServicoTecnico = null
        this.reloadArr = false
        this.voltar()
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );

  }


  cancelEditServicoTecnico() {

    this.editingServicoTecnico = null
    this.voltar()

  }
  deleteEditServicoTecnico() {

  }



  // DOCUMENTOS


  createDocumento(area: any, tema: any) {
    this.actionType = 'insertDoc'
    this.viewDetails = false
    this.selectedDoc = {
      "dostDk": 0,
      "dostNmDocumento": "",
      "dostDsDocumento": "",
      "dtstDk": 0,
      "dtstInObrigatorio": "S",
      TMST_DK: this.selectedServTec.tmanDk,
      PSTC_DK: this.selectedServTec.pstcDk,
    }
    this.editingDoc = { ...this.selectedDoc }
    console.log('editingDoc', this.editingDoc)
  }

  editDocumento(selectedServTec: any, doc: any) {
    this.actionType = 'updateDoc'
    this.viewDetails = false
    this.selectedDoc = doc

    this.selectedDoc.TMST_DK = this.selectedServTec.tmanDk
    this.selectedDoc.PSTC_DK = this.selectedServTec.pstcDk
    console.log('selectedServTec', selectedServTec)

    this.editingDoc = { ... this.selectedDoc }
    console.log('editingDoc', this.editingDoc)
  }




  updateObrigatorio(event: any): void {
    this.editingDoc.dtstInObrigatorio = event.checked ? 'S' : 'N';
  }
  updateObrigatorioVinculo(event: any, doc: any): void {

    doc.dtstInObrigatorio = event.checked ? 'S' : 'N';
    console.log(this.filteredDocuments)
  }


  cancelEditDocumento() {
    this.voltar()

  }
  saveEditDocumento(action: any) {
    console.log('this.editingDoc', this.editingDoc)
    console.log('this.selectedServTec', this.selectedServTec)
    if (action === 'DELETE') {
      this.actionType = 'deleteDoc'
    }
    this.adminDatabaseService.CruDocServTec(this.editingDoc, action).subscribe(
      (event: any) => {
        console.log('Documento gravado:', event);
        if (action === 'UPDATE') {
          console.log(this.editingDoc)
          this.openSnackBar('Documento editado com sucesso.')
        }
        if (action === 'INSERT') {
          this.editingDoc = event
          //  this.selectedServTec = { ... this.editingServicoTecnico }
          this.openSnackBar('Documento criado com sucesso.')
        }
        if (action === 'DELETE') {

          this.openSnackBar('Documento excluído com sucesso.')
        }
        this.updateDocInServTec()
        this.reloadArr = true
        this.editingDoc = null
        this.voltar()

      },
      (error) => {
        this.reloadArr = false
        this.voltar()
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );





    this.openSnackBar('Documento editado com sucesso.')
  }

  updateDocInServTec() {
    console.log(this.actionType)
    console.log(this.editingDoc)
    console.log(this.selectedServTec)

    if (this.actionType === 'updateDoc') {
      this.selectedServTec.doc = this.selectedServTec.doc.filter((doc: any) => doc.dostDk !== this.editingDoc.dostDk);
      this.selectedServTec.doc.push(this.editingDoc)
    }
    if (this.actionType === 'insertDoc') {
      if (this.selectedServTec.doc === undefined) {
        this.selectedServTec.doc = []
      }
      this.selectedServTec.doc.push(this.editingDoc)
    }
    if (this.actionType === 'deleteDoc') {
      this.selectedServTec.doc = this.selectedServTec.doc.filter((doc: any) => doc.dostDk !== this.editingDoc.dostDk);
    }
    console.log("this.selectedServTec.doc", this.selectedServTec.doc)
    let docsObrigatorios: any = []
    let docsDesejaveis: any = []
    this.selectedServTec.doc = this.sortDocumentsByName(this.selectedServTec.doc);
    for (const doc of this.selectedServTec.doc) {
      if (doc.dtstInObrigatorio === 'S') {
        docsObrigatorios.push(doc)
      }
      if (doc.dtstInObrigatorio === 'N') {
        docsDesejaveis.push(doc)
      }
    }

    this.selectedServTec.docsObrigatorios = docsObrigatorios
    this.selectedServTec.docsDesejaveis = docsDesejaveis

  }

  sortDocumentsByName(documents: any) {
    return documents.sort((a: any, b: any) => {
      return a.dostNmDocumento.localeCompare(b.dostNmDocumento);
    });
  }

  // VINCULOS TEMA SERVTEC

  vinculoTemaServ() {
    this.viewDetails = false
    this.actionType = 'vinculoTemaServ'
    this.getVinculosTema()
  }

  voltarVinculo() {
    this.viewDetails = true
    this.actionType = null
    this.uncheckAllDocsArr()
  }

  uncheckAllDocsArr() {
    this.searchDocumentoQuery = ''
    this.allDocumentosArr.forEach((doc: any) => {
      doc.checked = false
    });
    this.filteredDocuments = [...this.allDocumentosArr]
  }
  getVinculosTema(): void {
    const PSTC_DK = this.selectedServTec.pstcDk
    this.spinnerVinculos = true
    this.adminDatabaseService.getVinculosTemaServ(PSTC_DK)
      .subscribe({
        next: data => {
          if (data.length === 0) {
            console.log('No data available or an error occurred');
            this.openSnackBar('No data available or an error occurred.')
            this.spinnerVinculos = false
          } else {
            this.spinnerVinculos = true
            this.vinculosDkArr = data;
            console.log(' this.vinculosDkArr', this.vinculosDkArr);

            this.checkedVinculosTemaServ()
          }

        },
        error: error => {
          console.error('Failed to load data', error);
          this.openSnackBar('Failed to load data.')
          this.spinnerVinculos = false
        }

      });
  }

  checkedVinculosTemaServ() {
    this.filteredAreas = [...this.areasOriginal]
    console.log('this.filteredAreas', this.filteredAreas)
    console.log('this.vinculosDkArr', this.vinculosDkArr)
    for (const area of this.filteredAreas) {
      for (const tema of area.temas) {
        let hasVinculo = this.hasCorrespondingTmanDk(tema.tmanDk, this.vinculosDkArr);
        //  console.log(tema.tmanDk, 'this.hasCorrespondingTmanDk', this.hasCorrespondingTmanDk(tema.tmanDk, this.vinculosDkArr))
        if (hasVinculo === true) {
          tema.disabled = true
        } else {
          tema.disabled = false
        }
      }
    }
    this.spinnerVinculos = false
  }
  hasCorrespondingTmanDk(value: number, list: any[]): boolean {
    return list.some(item => item.tmanDk === value);
  }

  saveEditVinculoTemaServ() {
    let vinculosToSaveArr: any = []
    for (const area of [...this.filteredAreas]) {
      for (const tema of area.temas) {
        if (tema.checked === true) {
          let objToPush =
          {
            "tmanDk": tema.tmanDk,
            "tmanNmTema": "",
            "tmanDsTema": ""
          }
          vinculosToSaveArr.push(objToPush)
        }
      }
    }
    console.log('vinculosToSaveArr', vinculosToSaveArr)

    let PSTC_DK = this.selectedServTec.pstcDk
    this.adminDatabaseService.VinculaServicoTec(PSTC_DK, vinculosToSaveArr).subscribe(
      (event: any) => {
        console.log('Documento gravado:', event);
        this.openSnackBar('Vínculo gravado com sucesso.')
        for (const area of [...this.filteredAreas]) {
          for (const tema of area.temas) {
            tema.checked = false
          }
        }
        this.reloadArr = true
        this.voltarVinculo()

      },
      (error) => {
        this.reloadArr = false
        this.voltarVinculo()
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );

  }
  deleteVinculoTemaServ(tmanDk: any) {
    this.adminDatabaseService.DellVinculaServicoTec(this.selectedServTec.pstcDk, tmanDk).subscribe(
      (event: any) => {
        console.log('Vínculo excluído:', event);

        this.openSnackBar('Vínculo excluído com sucesso.')
        this.filteredAreas = [...this.areasOriginal]
        this.reloadArr = true
        this.getVinculosTema()

      },
      (error) => {
        this.reloadArr = false
        this.voltarVinculo()
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );

  }



  // VINCULO SERVICO TECNICO - DOCUMENTO

  vinculoServDoc() {
    this.viewDetails = false
    this.actionType = 'vinculoServDoc'
    this.findVinculoServDoc()
  }

  findVinculoServDoc() {
    // Create a set of dostDk from the second array for quick lookup
    const dostDkSet = new Set(this.selectedServTec.doc.map((doc: any) => doc.dostDk));

    // Iterate over the first array and update the properties
    this.filteredDocuments.forEach((doc: any) => {
      if (dostDkSet.has(doc.dostDk)) {
        doc.disabled = true;
      } else {
        doc.disabled = false;
      }
      doc.checked = false; // Set checked to false for all objects in the first array
      doc.dtstInObrigatorio = 'N'
    });

  }

  saveEditVinculoServDoc() {
    let arrToSave: any = []
    let arrToPushInServ: any = []

    for (const doc of this.filteredDocuments) {

      if (doc.checked === true) {
        let objToPush: any = {}
        objToPush.dostDk = doc.dostDk
        objToPush.tmanDk = this.selectedServTec.tmanDk
        objToPush.pstcDk = this.selectedServTec.pstcDk
        objToPush.dtstInObrigatorio = doc.dtstInObrigatorio
        arrToPushInServ.push(doc)
        arrToSave.push(objToPush)
      }

    }
    console.log("arrToSave", arrToSave)
    this.adminDatabaseService.VinculaDocServTec(arrToSave).subscribe(
      (event: any) => {
        console.log('Documento gravado:', event);
        for (const doc of arrToPushInServ) {
          this.selectedServTec.doc.push(doc)
        }

        this.updateDocInServTec()
        this.reloadArr = true
        this.editingDoc = null
        this.voltarVinculo()

      },
      (error) => {
        this.reloadArr = false
        this.voltarVinculo()
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );


  }









  voltar() {

    console.log(this.selectedServTec)
    console.log(this.actionType)
    console.log('this.reloadArr', this.reloadArr)


    if (this.viewDetails === true && this.actionType === null || this.actionType === 'deleteServTec' || (this.actionType === 'insertServTec' && this.selectedServTec.pstcNmServTec === null)) {
      this.actionType = null
      //   this.selectedServTec = null
      this.viewDetails = false
    }



    if (this.actionType !== null) {
      this.actionType = null
      this.viewDetails = true
    }


    if (this.reloadArr === true) {
      this.satDatabaseService.areasLoaded.next(false)
      this.satDatabaseService.getAreasTemas()
      this.areasArr = []
      this.areasOriginal = []
      this.filteredAreas = []
      this.getAreasTemas()
      this.reloadArr = false
    }
    this.editingDoc = null
    this.selectedDoc = null
    this.editingServicoTecnico = null
  }


}

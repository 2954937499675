<div style="width: 560px; padding: 24px;">
  <div class="sticky">
    <!-- <div class="voltar">
      voltar
    </div> -->
    <div class="title-bar" *ngIf="sat.sateNrSat !== undefined || sat.sateInAprovada === 'N' "
         [style.background-color]="sat.sateInAprovada === 'S' ? '#5D899D' : '#AA4139'">
      <span style="margin-right: 20px;">

        {{sat.sateInAprovada === 'S' ? 'SAT:' : ''}} <span
              *ngIf="sat.sateInAprovada === 'S'"> {{sat?.sateNrSat}}</span>
      </span>
      <strong>
        <span style="font-size: 18px">
          Upload de novos arquivos
        </span>
      </strong>
    </div>




  </div>
  <div [class.disabled]="spinner===true">

    <mat-card *ngIf="sat.satePssoDk === 1 || sat.satePssoDk === 2     ">
      <h4 style="
    font-size: 15px;
">Arquivos relacionados aos serviços selecionados para esta SAT</h4>
      <p style="
    font-size: 15px;
"><strong> Documentos obrigatórios</strong>

      </p>
      <div>
        <mat-accordion>
          <mat-expansion-panel style="box-shadow: none; background-color: transparent;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Veja a lista de documentos
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let tema of allTemasArr">
              <div class="subtitle">Tema</div>
              <div
                   style="  font-size: 18px;             font-weight: bold;">
                {{tema.tema|
                lowercaseAndTitlecase}}
              </div>

              <div *ngFor="let servico of tema.servicos">
                <div style="font-size: 14px; ">
                  {{servico.servico|
                  lowercaseAndTitlecase}}
                </div>
                <div>
                  <ul>
                    <li style="color: #7E8990;  font-size: 14px;"
                        *ngFor="let documento of servico.docsObrigatorios">
                      {{ documento.dostNmDocumento }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <app-file-dnd
                    [saaxInObrigatorio]="true" [tpaxDk]="3"
                    [isSATFile]="true"
                    (newItemEvent)="onNewFileEvent($event)">
        saaxDsObservacao="Documento obrigatório"></app-file-dnd>

      <p style="
    font-size: 15px;
"><strong> Documentos Adicionais</strong>

      </p>
      <div>
        <mat-accordion>
          <mat-expansion-panel style="box-shadow: none; background-color: transparent;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Veja a lista de documentos
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let tema of allTemasArr">
              <div class="subtitle">Tema</div>
              <div
                   style="  font-size: 18px;             font-weight: bold;">
                {{tema.tema|
                lowercaseAndTitlecase}}
              </div>

              <div *ngFor="let servico of tema.servicos">
                <div style="font-size: 14px; ">
                  {{servico.servico|
                  lowercaseAndTitlecase}}
                </div>
                <div>
                  <ul *ngIf="servico.docsDesejaveis.length === 0">
                    <li style="color: #7E8990;  font-size: 14px;">
                      Nenhum documento adicional
                      encontrado</li>
                  </ul>
                  <ul *ngIf="servico.docsDesejaveis.length > 0">
                    <li style="color: #7E8990;  font-size: 14px;"
                        *ngFor="let documento of servico.docsDesejaveis">
                      {{ documento.dostNmDocumento }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="documentos">
        <app-file-dnd [saaxInObrigatorio]="false" [isSATFile]="true" [tpaxDk]="3" [isSATFile]="true"
                      (newItemEvent)="onNewFileEvent($event)"
                      saaxDsObservacao="Documento desejável">
        </app-file-dnd>
      </div>
    </mat-card>


    <mat-card *ngIf="sat.sateInManifTecAnterior=== 'N'">
      <h4 style="
    font-size: 15px;
">Documentos que justifiquem a análise do GATE
        diante da ausência de manifestação técnica prévia do órgão responsável </h4>
      <app-file-dnd [multipleFiles]="true"
                    [saaxInObrigatorio]="false" [tpaxDk]="5"
                    [isSATFile]="true"
                    (newItemEvent)="onNewFileEvent($event)"
                    saaxDsObservacao="Justificativa da excepcionalidade">
      </app-file-dnd>
    </mat-card>

    <mat-card *ngIf="sat.sateInManifTecAnterior=== 'S'">
      <h4 style="
    font-size: 15px;
">Manifestação técnica ou laudo pericial a ser complementado</h4>
      <app-file-dnd [multipleFiles]="true" [saaxInObrigatorio]="false" [isSATFile]="true"
                    (newItemEvent)="onNewFileEvent($event)"
                    [tpaxDk]="2"></app-file-dnd>
    </mat-card>



    <mat-card *ngIf="sat.sateInPrioridade === 'S'">
      <h4 style="
    font-size: 15px;
">Documentos relacionados ao atendimento prioritário</h4>
      <app-file-dnd [saaxInObrigatorio]="false" [tpaxDk]="1"
                    [isSATFile]="true"
                    (newItemEvent)="onNewFileEvent($event)"
                    saaxDsObservacao="Prioridade"></app-file-dnd>

    </mat-card>

  </div>



  <div style=" padding: 12px; margin-bottom: 50px;">

    <button
            [disabled]="spinner == true"
            (click)="enviarArquivos()"
            mat-flat-button
            color="accent"
            style="flex-grow: 1; margin-left: 15px;">
      Enviar arquivos
    </button>
    <span *ngIf="enviando === true"
          style="font-size: 20px; color: #818181; text-align: center; margin-left: 13px; margin-bottom: 20px;">
      {{
      uploadProgress }}%</span>

    <div style="font-size: 13px; margin-top: 20px;" *ngIf="totalMb > 10 && uploadProgress < 100">
      Você está enviando um
      total
      de {{totalMb}}MB em arquivos anexados.
      Esta operação pode
      levar alguns minutos. Por favor, não feche esta página.</div>

    <div style="margin-top: 20px;" *ngIf="uploadProgress >= 100">
      <mat-spinner [diameter]="30"
                   style="width: 30px; height: 30px; margin-right: 10px; margin-left: 10px; float: left; "
                   color="accent"></mat-spinner>
      <span style="font-size: 13px;">Finalizando envio. Por favor, não feche esta página.</span>
    </div>

  </div>
</div>
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { ThemeModule } from '@theme/theme.module';
import { SharedModule } from '@shared/shared.module';
import { RoutesModule } from './routes/routes.module';
//import { RoutesModule } from './routes/routes.module';
/* import { FormlyConfigModule } from './formly-config.module'; */
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
/* import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; */
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomizerComponent } from '@theme/customizer/customizer.component';

import { environment } from '@env/environment';
//import { BASE_URL, httpInterceptorProviders, appInitializerProviders } from '@core';
import { httpInterceptorProviders, appInitializerProviders } from '@core';

import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

import { NgSelectModule } from '@ng-select/ng-select';


// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
/* const firebaseConfig = {
  apiKey: 'AIzaSyAJmRiltrgUEvQwQtiVchg44q01OCWaqhg',
  authDomain: 'prodata-painel.firebaseapp.com',
  projectId: 'prodata-painel',
  storageBucket: 'prodata-painel.appspot.com',
  messagingSenderId: '535647111669',
  appId: '1:535647111669:web:f40d0d45bbee6dae6ff8bc',
};
 */
// Initialize Firebase
//const app = initializeApp(firebaseConfig);

// Required for AOT compilation
/* export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
} */

import { LoginService } from '@core/authentication/login.service';
//import { FakeLoginService } from './fake-login.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    ThemeModule,
    RoutesModule,
    NgSelectModule,
    SharedModule,
    /*   FormlyConfigModule.forRoot(), */
    NgxPermissionsModule.forRoot(),
    NgxMaskModule.forRoot({
      showMaskTyped: true,
      dropSpecialCharacters: true
      // clearIfNotMatch : true
    }),
    ToastrModule.forRoot(),
    /*  TranslateModule.forRoot({
       loader: {
         provide: TranslateLoader,
         useFactory: TranslateHttpLoaderFactory,
         deps: [HttpClient],
       },
     }), */
    BrowserAnimationsModule,
  ],
  providers: [
    CustomizerComponent,
    { provide: LoginService }, // <= Remove it in the real APP
    httpInterceptorProviders,
    appInitializerProviders,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },


  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

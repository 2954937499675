<div>
  <!-- <div class="voltar">
    voltar
  </div> -->
  <div class="title-bar"
       *ngIf="sat !== undefined && (sat.sateNrSat !== undefined || sat.sateInAprovada === 'N' )"
       [style.background-color]="sat.sateInAprovada === 'S' ? '#5D899D' : '#AA4139'">
    <strong>
      <span style="font-size: 18px">
        Edição de SAT
      </span>
    </strong>
  </div>
  <div style="height: 12px; ">
    <mat-progress-bar *ngIf="spinner === true"
                      color="accent" mode="indeterminate"></mat-progress-bar>
  </div>


</div>



<ng-container *ngIf="spinner === false">
  <app-sat-sat-nova [isEdicao]="true"></app-sat-sat-nova>
</ng-container>
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { Error403Component } from './sessions/403.component';
import { Error404Component } from './sessions/404.component';
import { Error500Component } from './sessions/500.component';
import { ServicosTecnicosComponent } from './admin/servicos-tecnicos/servicos-tecnicos.component';
import { ListaServicosComponent } from './admin/lista-servicos/lista-servicos.component';
import { CrudInsertServtecComponent } from './admin/crud-insert-servtec/crud-insert-servtec.component';
import { CrudViewServtecComponent } from './admin/crud-view-servtec/crud-view-servtec.component';
import { CrudUpdateServtecComponent } from './admin/crud-update-servtec/crud-update-servtec.component';
import { CrudServtecComponent } from './admin/crud-servtec/crud-servtec.component';
import { ConfirmationDialogComponent } from './admin/confirmation-dialog/confirmation-dialog.component';
import { CrudViewDocumentoComponent } from './admin/crud-view-documento/crud-view-documento.component';
import { CrudInsertDocumentoComponent } from './admin/crud-insert-documento/crud-insert-documento.component';
import { CrudUpdateDocumentoComponent } from './admin/crud-update-documento/crud-update-documento.component';

const COMPONENTS: any[] = [
  DashboardComponent,
  LoginComponent,
  RegisterComponent,
  Error403Component,
  Error404Component,
  Error500Component,
];
const COMPONENTS_DYNAMIC: any[] = [];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ServicosTecnicosComponent, CrudUpdateDocumentoComponent, ListaServicosComponent, CrudInsertDocumentoComponent, CrudInsertServtecComponent, CrudViewServtecComponent, CrudViewDocumentoComponent, CrudUpdateServtecComponent, CrudServtecComponent, ConfirmationDialogComponent],
})
export class RoutesModule { }

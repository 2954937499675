<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <input
         type="file"
         #fileDropRef
         id="fileDropRef"
         [multiple]="multipleFiles"

         (change)="fileBrowseHandler($event)" />

  <div style="margin-top: 40px  ;"><label for="fileDropRef">Procurar arquivo</label></div>


  <h3>ou arraste e solte os arquivos aqui</h3>
  <p>Máximo de 90Mb por arquivo</p>

</div>
<div class="files-list">
  <div *ngFor="let file of localStorageService.satFiles; let i = index">
    <div class="single-file"
         *ngIf="file.tpaxDk === tpaxDk && file.saaxInObrigatorio === saaxInObrigatorio">
      <mat-list style="width: 100%">
        <mat-list-item style="height: auto">
          <mat-icon style="color: rgb(95, 95, 99)" mat-list-icon>attach_file</mat-icon>
          <div
               mat-line
               [matTooltip]="file?.name"
               matTooltipPosition="above"
               matTooltipClass="docs-tooltip">
            {{ file?.name }}
          </div>
          <div mat-line class="size">{{ formatBytes(file?.size, 2) }}</div>
          <button mat-icon-button (click)="deleteFile(i)">
            <mat-icon style="color: rgb(95, 95, 99)">delete</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
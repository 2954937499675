import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  // BehaviorSubject to store the state of logadoIsAdmin
  private logadoIsAdminSubject = new BehaviorSubject<boolean>(false);

  // Observable to expose the admin status to other components
  logadoIsAdmin$: Observable<boolean> = this.logadoIsAdminSubject.asObservable();

  constructor(private authService: AuthService, private router: Router) {
    this.userSubscription();
  }

  // Subscribe to user and determine if the user is admin
  private userSubscription(): void {
    this.authService.user$.subscribe(user => {
      console.log(user.tok);
      let isAdmin = false;
      if (user.tok !== undefined) {
        user.tok.roles.forEach((role: any) => {
          if (role === 'Administrador') {
            isAdmin = true;
          }
        });
      }

      // Update the BehaviorSubject with the new admin status
      this.logadoIsAdminSubject.next(isAdmin);

      // Redirect if the user is not an admin
      if (!isAdmin) {
        this.router.navigate(['/sat-lista']);
      }
    });
  }

  // Generate PDF for the provided data
  generatePDF(data: any[]): void {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height || 297; // A4 page height in mm
    const margin = 10;
    let yOffset = margin;  // Initial y offset for the first element

    // Helper function to check if we need a new page
    const addNewPageIfNeeded = (currentYOffset: number) => {
      if (currentYOffset >= pageHeight - margin) {
        doc.addPage();
        return margin; // Reset to top margin of new page
      }
      return currentYOffset;
    };

    data.forEach(area => {
      // Add Area title
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'bold');
      doc.text(`Área: ${area.ArstNmArea}`, margin, yOffset);
      yOffset += 7;

      area.Temas.forEach((temaWrapper: any) => {
        temaWrapper.forEach((tema: any) => {
          yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

          // Add Tema title
          doc.setFontSize(11);
          doc.setFont('Helvetica', 'bold');
          doc.text(`  Tema: ${tema.tmanNmTema}`, margin + 5, yOffset);
          yOffset += 6;

          tema.servTec.forEach((servicoWrapper: any) => {
            servicoWrapper.forEach((servico: any) => {
              yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

              // Add Serviço Técnico title
              doc.setFontSize(10);
              doc.setFont('Helvetica', 'normal');
              doc.text(`    Serviço Técnico: ${servico.pstcNmServTec}`, margin + 10, yOffset);
              yOffset += 5;

              // Documentos Obrigatórios
              const obrigatorios = servico.doc.filter((d: any) => d.dtstInObrigatorio === 'S');
              if (obrigatorios.length > 0) {
                yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

                doc.setFontSize(9);
                doc.text('      Documentos Obrigatórios:', margin + 15, yOffset);
                yOffset += 4;

                obrigatorios.forEach((docObrigatorio: any, idx: any) => {
                  yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

                  doc.text(`        ${idx + 1}. ${docObrigatorio.dostNmDocumento}`, margin + 20, yOffset);
                  yOffset += 4;
                });
              }

              // Documentos Desejáveis
              const desejaveis = servico.doc.filter((d: any) => d.dtstInObrigatorio === 'N');
              if (desejaveis.length > 0) {
                yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

                doc.setFontSize(9);
                doc.text('      Documentos Desejáveis:', margin + 15, yOffset);
                yOffset += 4;

                desejaveis.forEach((docDesejavel: any, idx: any) => {
                  yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

                  doc.text(`        ${idx + 1}. ${docDesejavel.dostNmDocumento}`, margin + 20, yOffset);
                  yOffset += 4;
                });
              }

              yOffset += 4; // Add some spacing after each service
            });
          });

          yOffset += 5; // Add some spacing after each theme
        });
      });

      yOffset += 7; // Add some spacing after each area
    });

    // Save the PDF
    doc.save('servicos_tecnicos.pdf');
  }
  generatePDFWithoutArea(data: any[]): void {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height || 297; // A4 page height in mm
    const margin = 10;
    const lineHeight = 6; // Line height for normal text
    let yOffset = margin;
    const sectionTitleHeight = 7; // Line height for section titles

    // Helper function to check if we need a new page
    const addNewPageIfNeeded = (currentYOffset: number) => {
      if (currentYOffset >= pageHeight - margin) {
        doc.addPage();
        return margin; // Reset to top margin of new page
      }
      return currentYOffset;
    };

    // Add the report title
    doc.setFontSize(18);
    doc.setFont('Helvetica', 'bold');
    doc.text('Relatório de Temas e Serviços Técnicos', margin + 55, yOffset);  // Center title
    yOffset += sectionTitleHeight + 5; // Add extra space after title

    // Remove duplicate temas by tmanDk
    const uniqueTemas = new Map();
    data.forEach(area => {
      area.Temas.forEach((temaWrapper: any) => {
        temaWrapper.forEach((tema: any) => {
          if (!uniqueTemas.has(tema.tmanDk)) {
            uniqueTemas.set(tema.tmanDk, tema);
          }
        });
      });
    });

    // Convert the map back to an array and sort alphabetically by tema name
    const sortedTemas = Array.from(uniqueTemas.values()).sort((a, b) =>
      a.tmanNmTema.localeCompare(b.tmanNmTema)
    );

    // Loop through sorted temas
    sortedTemas.forEach((tema: any) => {
      yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

      // Add Tema title (like a section heading)
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'bold');
      doc.text(`Tema: ${tema.tmanNmTema}`, margin, yOffset);
      yOffset += sectionTitleHeight;

      // Sort services alphabetically by `pstcNmServTec`
      const sortedServicos = tema.servTec.sort((a: any, b: any) =>
        a[0].pstcNmServTec.localeCompare(b[0].pstcNmServTec)
      );

      // Loop through sorted services
      sortedServicos.forEach((servicoWrapper: any) => {
        const servico = servicoWrapper[0];

        yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

        // Add Serviço Técnico title
        doc.setFontSize(11);
        doc.setFont('Helvetica', 'normal');
        doc.text(`  • Serviço Técnico: ${servico.pstcNmServTec}`, margin + 5, yOffset);
        yOffset += lineHeight - 1;

        // Documentos Obrigatórios
        const obrigatorios = servico.doc.filter((d: any) => d.dtstInObrigatorio === 'S');
        if (obrigatorios.length > 0) {
          yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

          doc.setFontSize(10);
          doc.text('    Documentos Obrigatórios:', margin + 10, yOffset);
          yOffset += lineHeight - 2;

          obrigatorios.forEach((docObrigatorio: any, idx: any) => {
            yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

            doc.setFontSize(9);
            const wrappedText = doc.splitTextToSize(`${idx + 1}. ${docObrigatorio.dostNmDocumento}`, 180); // Handle long text wrap
            wrappedText.forEach((line: string) => {
              doc.text(`      ${line}`, margin + 15, yOffset);
              yOffset += lineHeight - 3; // Adjust line height for wrapped text
            });
          });
        }

        // Documentos Desejáveis
        const desejaveis = servico.doc.filter((d: any) => d.dtstInObrigatorio === 'N');
        if (desejaveis.length > 0) {
          yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

          doc.setFontSize(10);
          doc.text('    Documentos Desejáveis:', margin + 10, yOffset);
          yOffset += lineHeight - 2;

          desejaveis.forEach((docDesejavel: any, idx: any) => {
            yOffset = addNewPageIfNeeded(yOffset); // Check if a new page is needed

            doc.setFontSize(9);
            const wrappedText = doc.splitTextToSize(`${idx + 1}. ${docDesejavel.dostNmDocumento}`, 180); // Handle long text wrap
            wrappedText.forEach((line: string) => {
              doc.text(`      ${line}`, margin + 15, yOffset);
              yOffset += lineHeight - 3; // Adjust line height for wrapped text
            });
          });
        }

        yOffset += lineHeight - 1; // Add spacing after each service
      });

      yOffset += sectionTitleHeight; // Add spacing after each tema
    });

    // Save the PDF
    doc.save('temas_servicos_tecnicos_sem_areas.pdf');
  }


}

<div style="padding: 38px; text-align: center;">
  <div mat-dialog-content>
    <!-- <div>
      <mat-icon style="
    color: #AA4139;
    font-size: 85px;
    height: 85px;
    width: 85px; "
                mat-list-icon>priority_high</mat-icon>
    </div> -->
    <h1 mat-dialog-title>Deseja excluir esta SAT?</h1>


    <p>Esta operação não poderá ser desfeita!</p>

  </div>
  <div style="width: 100%; text-align: left; margin-top: 40px;">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button style="float: right;" mat-flat-button color="accent" [mat-dialog-close]="true">Excluir
      SAT</button>
  </div>
</div>
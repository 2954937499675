<!--  DEFINIÇÃO  DO(S) TEMAS(S) ************************************************* -->
<!-- servico-list.component.html -->
<div style="min-height: 165px; display: block">
  <div *ngIf="spinner">
    <div style="margin-bottom: 15px;"><strong>Carregando lista de serviços técnicos</strong></div>
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!spinner">
    <div style="margin-bottom: 15px;"><strong>Selecione todos os serviços técnicos da SAT</strong>
    </div>
    <div>
      <mat-accordion class="mat-elevation-z0">
        <mat-expansion-panel multi="true" class="expand-area mat-elevation-z0"
                             *ngFor="let area of filteredAreas; trackBy: trackByArea" #areaExpd>
          <mat-expansion-panel-header>
            <mat-panel-title class="expand-area-title">
              <mat-icon *ngIf="area.selected" matPrefix>check</mat-icon>
              {{ area.ArstNmArea | lowercaseAndTitlecase }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-accordion class="mat-elevation-z0" multi *ngIf="areaExpd.expanded">
            <mat-expansion-panel multi="true" class="expand-tema mat-elevation-z0"
                                 *ngFor="let tema of area.Temas; trackBy: trackByTema" #temaExpd>
              <mat-expansion-panel-header class="temas">
                <mat-panel-title>
                  <mat-icon *ngIf="tema.selected"
                            style="font-size: 20px; color:#5D899D; font-weight: bold;"
                            matPrefix>check</mat-icon>
                  <mat-icon *ngIf="!tema.selected" class="icon-lista"
                            mat-list-icon>fiber_manual_record</mat-icon>
                  {{ tema[0].tmanNmTema | lowercaseAndTitlecase }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="buscar" *ngIf="temaExpd.expanded">
                <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
                  <mat-icon matPrefix>search</mat-icon>
                  <input type="text" placeholder="Buscar" matInput
                         [(ngModel)]="tema[0].searchServico"
                         (input)="filterServicos(tema[0].searchServico, tema[0])" />
                </mat-form-field>
              </div>
              <div style="max-height: 350px; overflow: auto; overflow-x: hidden"
                   *ngIf="temaExpd.expanded">
                <mat-list role="list">
                  <mat-list-item
                                 *ngFor="let servico of tema[0].servTec"
                                 class="lista "

                                 [ngClass]="{ selected: servico.selected }"
                                 role="listitem">



                    <mat-checkbox (change)="satFormService.selectedServico()"
                                  style="word-wrap: break-word;"
                                  [(ngModel)]="servico.selected"
                                  color="accent"
                                  [disabled]="servico.disabled === true">
                      <div
                           style="white-space: normal;padding-left: 7px;            font-size: 15px;">
                        {{
                        servico[0].pstcNmServTec
                        }}</div>
                    </mat-checkbox>

                  </mat-list-item>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
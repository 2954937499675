import { Component, OnInit, Inject } from '@angular/core';
import { SatDatabaseService } from '../../../shared/services/sat-database.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SatDetalhesService } from '../sat-detalhes/sat-detalhes.service';
import { LocalStorageService } from '@shared';
export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-list-files',
  templateUrl: './list-files.component.html',
  styleUrls: ['./list-files.component.scss']
})


export class ListFilesComponent implements OnInit {
  sat: any
  spinner: boolean = false
  constructor(private satDetalhesService: SatDetalhesService) {
    this.sat = this.satDetalhesService.getSatFormEditar()
    console.log("this.sat", this.sat)
  }

  ngOnInit(): void {
  }

}

<!--  DEFINIÇÃO  UPLOAD DE ARQUIVOS ************************************************* -->
<div style="min-height: 165px; display: block">


  <p>
    Copie e cole o(s) ID(s) dos documentos localizados no Integra.
  </p>
  <p style="    font-size: 14px;
  "
     *ngIf="satFormService.preForm.procedimentoObj.sate_PSSO_DK && satFormService.preForm.procedimentoObj.sate_PSSO_DK === 5">
    * Se o procedimento estiver alocado no Integra Policial, o número da página poderá ser utilizado
    no lugar do ID.
  </p>
  <div *ngFor="let area of areasArr">
    <div *ngFor="let tema of area.temas">
      <div class="subtitle">Tema</div>
      <div [matTooltip]="'Área: ' + area.area" matTooltipPosition="above"
           matTooltipClass="tooltip-info"
           style="  font-size: 18px;             font-weight: bold;">
        {{tema.tema}}
      </div>

      <div *ngFor="let servico of tema.servicos">
        <div style="font-size: 14px; margin-top: 10px;        font-weight: bold;">
          {{servico.servico}}
        </div>
        <div>
          <div class="documentos box-documentos">
            <p><strong>Documentos obrigatórios*</strong></p>
            <div *ngFor="let documento of servico.docsObrigatorios">
              {{ documento.dostNmDocumento }}
              <div class="mat-form-box id-integra-input">
                <mat-form-field *ngFor="let id of documento.idsArr; let i = index; trackBy: trackByFn"
                                appearance="fill"
                                style="width: 100%; margin-bottom: 20px;">
                  <input (input)="satFormService.checkPreFormToNavigate(); checkIsdArr(documento) "
                         matInput
                         placeholder="ID do documento no Integra" maxlength="50"
                         placeholder="Ex. 94105521"

                         [(ngModel)]="documento.idsArr[i]">
                  <mat-hint align="end">{{documento.idsArr[i].length}} / 50</mat-hint>

                </mat-form-field>
                <div *ngIf="documento.idsArr[documento.idsArr.length - 1] !== '' && documento.idsArr[documento.idsArr.length] !== null "
                     style="text-align: right;">
                  <button (click)="addId(documento)" mat-stroked-button color="accent">+ adicionar
                    novo ID</button>
                </div>
              </div>
            </div>


            <div style="margin-top: 15px; padding-top: 15px; border-top: 1px #969595 solid;">
              <p><strong>Documentos Adicionais</strong></p>
              <div *ngFor="let documento of servico.docsDesejaveis">
                {{ documento.dostNmDocumento }}
                <div class="mat-form-box id-integra-input">
                  <mat-form-field *ngFor="let id of documento.idsArr; let i = index; trackBy: trackByFn"
                                  appearance="fill"
                                  style="width: 100%; margin-bottom: 20px;">
                    <input matInput placeholder="ID do documento no Integra" maxlength="50"
                           placeholder="Ex. 94105521"

                           [(ngModel)]="documento.idsArr[i]">
                    <mat-hint align="end">{{documento.idsArr[i].length}} / 50</mat-hint>


                  </mat-form-field>
                  <div *ngIf="documento.idsArr[documento.idsArr.length - 1] !== '' && documento.idsArr[documento.idsArr.length] !== null "
                       style="text-align: right;">
                    <button (click)="addId(documento)" mat-stroked-button color="accent">+ adicionar
                      novo ID</button>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
<div class="page-container" style="max-width: 920px; padding: 24px;">

  <div class="matero-page-header-inner">
    <h2 class="matero-page-title">ADMIN - Serviços técnicos e documentos</h2>


    <div style="margin-bottom: 14px; padding-bottom: 4px; border-bottom: 1px #9c9c9c dotted;">
      <!-- First button: Listar serviços -->
      <button mat-flat-button color="accent"
              (click)="componentToLoad = 'app-crud-view-servtec'"
              [ngClass]="{'active-button': componentToLoad === 'app-crud-view-servtec'}"
              style="margin-right: 20px;">
        Listar serviços
      </button>

      <!-- Second button: Inserir serviços -->
      <button mat-flat-button color="accent"
              (click)="componentToLoad = 'app-crud-insert-servtec'"
              [ngClass]="{'active-button': componentToLoad === 'app-crud-insert-servtec'}"
              style="margin-right: 20px;">
        Inserir serviços
      </button>

      <!-- Third button: Listar documentos -->
      <button mat-flat-button color="accent"
              (click)="componentToLoad = 'app-crud-view-documento'"
              [ngClass]="{'active-button': componentToLoad === 'app-crud-view-documento'}"
              style="margin-right: 20px;">
        Listar documentos
      </button>

      <!-- Fourth button: Inserir documentos -->
      <button mat-flat-button color="accent"
              (click)="componentToLoad = 'app-crud-insert-documento'"
              [ngClass]="{'active-button': componentToLoad === 'app-crud-insert-documento'}"
              style="margin-right: 20px;">
        Inserir documentos
      </button>
      <!--  <button mat-icon-button color="accent" (click)="exportToPDF()" aria-label="Export PDF">
        <mat-icon>picture_as_pdf</mat-icon>
      </button> -->
    </div>

    <div>
      <h3 *ngIf="componentToLoad==='app-crud-view-servtec'">Listar serviços técnicos</h3>
    </div>
    <div>
      <h3 *ngIf="componentToLoad==='app-crud-insert-servtec'">Inserir serviços técnicos</h3>
    </div>
    <div>
      <h3 *ngIf="componentToLoad==='app-crud-view-documento'">Listar documentos</h3>
    </div>
    <div>
      <h3 *ngIf="componentToLoad==='app-crud-insert-documento'">Inserir documentos</h3>
    </div>
    <ng-container [ngSwitch]="componentToLoad">
      <app-crud-view-servtec *ngSwitchCase="'app-crud-view-servtec'"></app-crud-view-servtec>
      <app-crud-insert-servtec *ngSwitchCase="'app-crud-insert-servtec'"></app-crud-insert-servtec>
      <app-crud-view-documento *ngSwitchCase="'app-crud-view-documento'"></app-crud-view-documento>
      <app-crud-insert-documento
                                 *ngSwitchCase="'app-crud-insert-documento'"></app-crud-insert-documento>
    </ng-container>

  </div>
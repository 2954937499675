<div>


  <div class="matero-page-header-inner" style="    margin-top: 30px;">
    <h3 class="matero-page-title criterios-title">Acelerador de Pontos</h3>
  </div>

  <p>
    O acelerador é usado para garantir que solicitações mais antigas, mesmo aquelas inicialmente
    consideradas de menor prioridade, sejam atendidas.
  </p>


  <div class="matero-page-header-inner" style="    margin-top: 46px;">
    <h3 class="matero-page-title criterios-title">Como funciona?</h3>
  </div>


  <div class="blocos">
    <div class="bloco" style="    max-width: 216px;     margin-right: 51px;">
      <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                  style="font-size: 24px; color:#5D899D; position: absolute; top: 18px; "
                  mat-list-icon>timer</mat-icon></span>
      <span style="font-size: 15px;   padding-top: 6px;">Uma SAT está aguardando há 180 dias no
        GATE</span>
    </div>
    <div class="bloco" style="    max-width: 216px;     margin-right: 51px;">
      <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                  style="font-size: 24px; color:#AA4139; position: absolute; top: 18px; "
                  mat-list-icon>note_stack_add</mat-icon></span>
      <span style="font-size: 14px;   padding-top: 6px;">Começa a ganhar pontos adicionais
        diariamente</span>
    </div>
    <div class="bloco" style="    max-width: 216px;     margin-right: 51px;    ">
      <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                  style="font-size: 24px; color:#946F2E; position: absolute; top: 18px; "
                  mat-list-icon>view_array</mat-icon></span>
      <span style="font-size: 14px;   padding-top: 6px;">Sua prioridade vai aumentando até ser
        atendida</span>
    </div>
  </div>
  <div style="clear: both;"></div>

  <p>
    Com isso, promovemos um atendimento mais equilibrado e eficiente das solicitações.
  </p>

  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1C1B1F;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>frame_inspect</mat-icon></span>
    <div>Entenda o Acelerador de Pontos</div>
  </div>


  <p>
    A fila de atendimento é gerada através das notas de cada solicitação.
  </p>


  <p>
    Com o tempo, solicitações com notas menores podem acabar no fim da fila e correm o risco de não
    serem atendidas em um prazo razoável.
  </p>

  <p>
    Para evitar que as solicitações fiquem indefinidamente no GATE, foi criado o acelerador de
    pontos.
  </p>


  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">O acelerador de pontos utiliza uma equação
      matemática para adicionar pontos extras à nota de prioridade do procedimento, baseando-se no
      tempo de permanência no GATE. Conforme o tempo passa, esses pontos se acumulam, aumentando a
      prioridade do procedimento.
    </span>
  </div>

  <p>
    Com isso, é possível garantir que as solicitações mais antigas, mesmo com menor prioridade,
    sejam atendidas.
  </p>

  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1C1B1F;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>news</mat-icon></span>
    <div>Cálculo do Acelerador de Pontos</div>
  </div>
  <p>
    A equação utilizada para calcular os pontos adicionais é a seguinte:
  </p>

  <div class="formula">
    <i> Pontos</i> = 0,00000009 &#xd7;<i> x</i> <sup>3</sup> &#x2b; 0,00002 &#xd7; <i>
      x</i><sup>2</sup> &#x2212;
    0,0013 &#xd7; <i> x</i> &#x2b; 0,6


  </div>
  <p>Onde x representa o número de dias que a solicitação permanece no GATE.</p>


  <div class="barra-destaque-cinza">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#1C1B1F;    margin-right: 5px;height: 21px; width: 27px; position: relative;
                top: 2px;"
                mat-list-icon>page_info</mat-icon></span>
    <div>Diretrizes do Acelerador </div>
  </div>

  <p>
    No contexto atual, foi considerado o prazo de 18 meses como limite máximo de espera para um
    apoio técnico.
  </p>

  <p>
    No entanto, um prazo aceitável para atuação nesse momento é de até um ano, evitando a
    prorrogação de solicitações em razão da demora na entrega das ITs.
  </p>

  <div class="bloco-informacao">
    <span style="min-width: 32px;"> <mat-icon class="material-symbols-outlined"
                style="font-size: 21px; color:#4B555D; position: absolute; top: 20px; "
                mat-list-icon>info</mat-icon></span>
    <span style="font-size: 15px;   padding-top: 6px;">De acordo com o artigo 9º da

      <span (click)="abrirLinkExterno('https://www.cnmp.mp.br/portal/images/Resolucoes/2021/Resoluo-0231.pdf')"
            class="doc-link">
        Resolução CNMP nº 23/07
      </span>
      e com os artigos 25 e 35 da
      <span (click)="abrirLinkExterno('https://www.mprj.mp.br/documents/20184/650298/resolucao_2227.pdf')"
            class="doc-link">
        Resolução GPGJ nº 2.227/18
      </span>
      do MPRJ, o inquérito civil
      (IC) e o procedimento administrativo (PA) terão duração de 1 ano, prorrogável por mais quantas
      vezes forem necessárias.
    </span>
  </div>

  <p>
    O acelerador de pontos entra em ação quando uma solicitação completa 180 dias no GATE.
  </p>

  <p>
    A solicitação começa a ganhar pontos diariamente, elevando sua prioridade até que seja atendida.
  </p>

</div>
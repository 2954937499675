import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SatDatabaseService } from '@shared/services/sat-database.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { AdminDatabaseService } from '../services/admin-database.service';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { AdminService } from '../services/admin-service';

@Component({
  selector: 'app-crud-update-servtec',
  templateUrl: './crud-update-servtec.component.html',
  styleUrls: ['./crud-update-servtec.component.scss']
})
export class CrudUpdateServtecComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: any = MatAccordion;
  @ViewChildren(MatExpansionPanel) expansionPanel: any = QueryList<MatExpansionPanel>;
  @ViewChildren(MatExpansionPanel) panels: any = QueryList<MatExpansionPanel>;
  editingServicoTecnico: any
  nomeDuplicado = false
  filteredAreas: any[] = [];
  areasOriginal: any = [];

  allTemasArr: any = []

  private areasRequestInitiated = false;
  spinnerVinculos: boolean = false;
  searchGlobal = '';
  allServicosOriginal: any = []
  filteredServicos: any = [];
  multiAreas = false
  timeout: any = null;
  timeoutOpenPanel: any = null;
  panelSpinner = false
  areasArr: any = []
  spinner = true;
  reloadArr: boolean = false;
  editVinculo = false
  vinculoTipo = 'temas'
  temasArr: any = []
  filteredTemasArr: any[] = []; // For displaying filtered temas
  selectedFilter: string = 'todos';
  checkedTemas: any[] = [];

  searchTermTema: string = '';

  mustReloadParent: boolean = false

  vinculosDkArr: any = []
  allDocumentosArr: any = []
  filteredDocuments: any = []
  searchDocumentoQuery: any = ''
  searchTermDoc: string = '';
  selectedTemaServTec: any
  selectedFilterDocs: string = 'todos';
  checkedDocuments: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<CrudUpdateServtecComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminDatabaseService: AdminDatabaseService,
    private _snackBar: MatSnackBar,
    private satDatabaseService: SatDatabaseService,
    //  private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.editVinculo = false;
    if (this.data.action === 'vinculo') {
      this.editVinculo = true;
    }
    this.editingServicoTecnico = { ...this.data }
    console.log('Received data:', this.editingServicoTecnico);
    this.compareObjectsByName()
    this.getTemasComp();
    this.getAllDocumentos()
    this.dialogRef.backdropClick().subscribe(() => {
      console.log('Dialog backdrop clicked!');
      this.closeDialog('backdrop_click'); // Send a custom result on backdrop click
    });
  }
  ngOnDestroy(): void {
    console.log('Component is being destroyed, clearing arrays and resetting state.');
    this.filteredAreas = [];
    this.areasOriginal = [];
    this.vinculosDkArr = [];
    this.allServicosOriginal = [];
    this.filteredServicos = [];
    this.areasArr = [];
    this.filteredTemasArr = [];
    this.checkedDocuments = []
    this.checkedTemas = []
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.timeoutOpenPanel) {
      clearTimeout(this.timeoutOpenPanel);
    }
    this.editVinculo = false;
  }



  getTemasComp() {
    this.spinner = true
    this.adminDatabaseService.getAllTemas().subscribe(
      (response) => {
        console.log('allTemasArr ', response);  // Debugging - Log the data to console
        let temasArr: any = response
        temasArr.forEach((tema: any) => {
          tema.checked = false
          tema.disabled = false
        });
        this.temasArr = temasArr
        this.filteredTemasArr = [...this.temasArr];
        this.sortTemasNome(this.filteredTemasArr)
        this.getVinculosTema();
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }
  sortTemasNome(temas: any) {
    console.log(temas);
    temas.sort((a: any, b: any) => {
      const nameA = a.tmanNmTema.toLowerCase();
      const nameB = b.tmanNmTema.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // They are equal
    });
  }

  getVinculosTema(): void {
    const PSTC_DK = this.editingServicoTecnico.pstcDk
    this.spinnerVinculos = true
    this.adminDatabaseService.getVinculosTemaServ(PSTC_DK)
      .subscribe({
        next: data => {
          if (data.length === 0) {
            this.spinnerVinculos = false
            this.vinculosDkArr = []
            this.checkedVinculosTemaServ()
          } else {
            this.spinnerVinculos = true
            this.vinculosDkArr = data;
            console.log(' this.vinculosDkArr', this.vinculosDkArr);
            this.checkedVinculosTemaServ()
          }
        },
        error: error => {
          console.error('Failed to load data', error);
          this.openSnackBar('Failed to load data.')
          this.spinnerVinculos = false
        }
      });
  }

  checkedVinculosTemaServ() {
    this.filteredTemasArr = [...this.temasArr]
    for (const tema of this.filteredTemasArr) {
      let hasVinculo = this.hasCorrespondingTmanDk(tema.tmanDk, this.vinculosDkArr);
      //  console.log(tema.tmanDk, 'this.hasCorrespondingTmanDk', this.hasCorrespondingTmanDk(tema.tmanDk, this.vinculosDkArr))
      if (hasVinculo === true) {
        tema.disabled = true
      } else {
        tema.disabled = false
      }
    }
    this.sortTemasNome(this.filteredTemasArr)
    this.applyFilter(this.selectedFilter)
    this.spinner = false;
    this.spinnerVinculos = false
  }





  filterTemas(): void {
    const term = this.searchTermTema.trim().toLowerCase();

    // Start with the full list each time
    let filteredTemas = [...this.temasArr];

    // Apply search term filter
    if (term) {
      filteredTemas = filteredTemas.filter((tema: any) =>
        tema.tmanNmTema.toLowerCase().includes(term)
      );
    }

    // Apply selected filter ('todos', 'vinculados', 'nao-vinculados')
    if (this.selectedFilter === 'vinculados') {
      filteredTemas = filteredTemas.filter((tema: any) => tema.disabled);
    } else if (this.selectedFilter === 'nao-vinculados') {
      filteredTemas = filteredTemas.filter((tema: any) => !tema.disabled);
    }

    // Map the checked state onto the filtered list
    this.filteredTemasArr = filteredTemas.map((tema: any) => ({
      ...tema,
      checked: this.checkedTemas.some(checkedTema => checkedTema.tmanDk === tema.tmanDk)
    }));


    this.sortTemasNome(this.filteredTemasArr)

  }



  toggleTemaCheck(tema: any): void {
    if (tema.checked) {
      this.checkedTemas.push(tema);
    } else {
      this.checkedTemas = this.checkedTemas.filter(
        checkedTema => checkedTema.tmanDk !== tema.tmanDk
      );
    }
    console.log('this.checkedTemas', this.checkedTemas);
  }

  clearSearch(event: any): void {
    this.searchTermTema = ''; // Reset the search term
    this.filterTemas();   // Reapply filter to show the full list
  }
  sortByPstcNmServTec(array: any) {
    console.log(array)
    return array.sort((a: any, b: any) => {
      if (a.pstcNmServTec < b.pstcNmServTec) {
        return -1;
      }
      if (a.pstcNmServTec > b.pstcNmServTec) {
        return 1;
      }
      return 0;
    });
  }


  removeDuplicates(array: any) {
    const uniqueObjects = new Map();

    array.forEach((item: any) => {
      const key = `${item.dostDk}`;
      if (!uniqueObjects.has(key)) {
        uniqueObjects.set(key, item);
      }
    });

    return Array.from(uniqueObjects.values()).sort((a, b) => {
      return a.dostNmDocumento.localeCompare(b.dostNmDocumento);
    });
  }

  applyFilter(filter: string): void {

    this.selectedFilter = filter;  // Update the selected filter state

    if (filter === 'todos') {
      // Show all items
      this.filteredTemasArr = this.filteredTemasArr;
    } else if (filter === 'vinculados') {
      // Show only items where `tema.checked` is true (linked temas)
      this.filteredTemasArr = this.filteredTemasArr.filter((tema: any) => tema.disabled);
    } else if (filter === 'nao-vinculados') {
      // Show only items where `tema.checked` is false (unlinked temas)
      this.filteredTemasArr = this.filteredTemasArr.filter((tema: any) => !tema.disabled);
    }
  }
  // Close the dialog and return data to the parent component
  closeDialog(action: any): void {
    // You can pass the updated data here. Assuming `this.data` is updated
    this.dialogRef.close(this.mustReloadParent);
  }

  // Cancel and close without returning data
  cancelDialog(): void {
    this.dialogRef.close(this.mustReloadParent);  // Close without returning any data
  }
  resetArrs() {
    this.checkedDocuments = []
    this.checkedTemas = []
    this.filteredDocuments = []
    this.selectedFilterDocs = 'todos'
  }
  openSnackBar(msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  compareObjectsByName() {
    setTimeout(() => {
      const normalizeString = (str: string): string => {
        return str
          .trim()                          // Remove leading/trailing spaces
          .replace(/\s+/g, ' ');            // Replace multiple spaces with a single space
      };

      const nameFromData = normalizeString(this.data.pstcNmServTec);
      const nameFromEditing = normalizeString(this.editingServicoTecnico.pstcNmServTec);
      const descriptionFromData = normalizeString(this.data.pstcDsServTec);
      const descriptionFromEditing = normalizeString(this.editingServicoTecnico.pstcDsServTec);
      if (nameFromData !== nameFromEditing || descriptionFromData !== descriptionFromEditing) {
        this.nomeDuplicado = false; // Names or descriptions differ
      } else {
        this.nomeDuplicado = true;  // Both are the same
      }

      console.log('Nome duplicado:', this.nomeDuplicado);
    }, 300);
  }




  hasCorrespondingTmanDk(value: number, list: any[]): boolean {
    return list.some(item => item.tmanDk === value);
  }

  removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  openAll() {
    console.log("this.openAll()")
    this.panels.forEach((panel: any) => panel.open())
    this.expansionPanel.toArray().forEach((panel: any) => {
      panel.open();
    })
  }
  hasDisabledTema(area: any): boolean {
    return area.temas.some((tema: any) => tema.disabled === true);
  }


  saveEditServicoTecnico(action: any) {
    this.editingServicoTecnico.tmanDk = 0
    this.adminDatabaseService.createServico(this.editingServicoTecnico, action).subscribe(
      (event: any) => {
        console.log('Servico gravado:', event);
        if (action === 'UPDATE') {
          this.editingServicoTecnico = event
          this.openSnackBar('Serviço editado com sucesso.')
          this.mustReloadParent = true
          this.cancelDialog()
        }
        if (action === 'DELETE') {
          this.mustReloadParent = true
          this.cancelDialog()
          this.openSnackBar('Serviço excluído com sucesso.')
        }
      },
      (error) => {
        this.dialogRef.close('error');
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
      }
    );

  }

  saveEditVinculoTemaServ() {
    let vinculosToSaveArr: any = []
    for (const tema of this.checkedTemas) {
      let objToPush =
      {
        "tmanDk": tema.tmanDk,
        "tmanNmTema": "",
        "tmanDsTema": ""
      }
      vinculosToSaveArr.push(objToPush)
    }

    console.log('vinculosToSaveArr', vinculosToSaveArr)
    console.log('this.editingServicoTecnico.pstcDk', this.editingServicoTecnico.pstcDk)
    let PSTC_DK = this.editingServicoTecnico.pstcDk
    this.adminDatabaseService.VinculaServicoTec(PSTC_DK, vinculosToSaveArr).subscribe(
      (event: any) => {
        console.log('Documento gravado:', event);
        this.openSnackBar('Vínculo gravado com sucesso.')
        for (const area of [...this.filteredAreas]) {
          for (const tema of area.temas) {
            tema.checked = false
          }
        }
        this.checkedTemas = []
        this.searchTermTema = ''
        this.selectedFilter = 'vinculados'

        this.getVinculosTema()


      },
      (error) => {
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
      }
    );

  }

  deleteVinculoTemaServ(tmanDk: any) {
    console.log(this.editingServicoTecnico)
    this.adminDatabaseService.DellVinculaServicoTec(this.editingServicoTecnico.pstcDk, tmanDk).subscribe(
      (event: any) => {
        console.log('Vínculo excluído:', event);
        this.openSnackBar('Vínculo excluído com sucesso.')
        this.getVinculosTema()
      },
      (error) => {
        this.reloadArr = false
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
      }
    );

  }



  //DOCUMENTO **********************

  getAllDocumentos() {
    this.spinner = true;
    this.filteredDocuments = [];
    this.adminDatabaseService.getAllDocumento().subscribe(
      (response) => {
        this.allDocumentosArr = response;
        this.allDocumentosArr = this.removeDuplicates(this.allDocumentosArr);  // Use generic sort function
        this.filteredDocuments = [...this.allDocumentosArr];
        console.log('allDocumentosArr from service:', this.allDocumentosArr);  // Debugging - Log the data to console
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }
  filterDocuments(): void {
    this.spinner = true;
    const term = this.searchTermDoc.trim().toLowerCase();
    const isVinculados = this.selectedFilterDocs === 'vinculados';
    const isNaoVinculados = this.selectedFilterDocs === 'nao-vinculados';

    // Combine filtering in a single pass
    this.filteredDocuments = this.allDocumentosArr.filter((doc: any) => {
      // Check search term
      const matchesTerm = !term || doc.dostNmDocumento.toLowerCase().includes(term);

      // Check filter type
      const matchesFilter = (isVinculados && doc.disabled) ||
        (isNaoVinculados && !doc.disabled) ||
        (!isVinculados && !isNaoVinculados);

      // Return documents that satisfy both conditions
      return matchesTerm && matchesFilter;
    }).map((doc: any) => ({
      ...doc,
      checked: this.checkedDocuments.some(selectedDoc => selectedDoc.dostDk === doc.dostDk)
    }));

    // Sort results based on conditions
    if (isVinculados) {
      this.sortDocumentsObrigatorio(this.filteredDocuments);
    } else {
      this.sortDocumentsNome(this.filteredDocuments);
    }

    this.spinner = false;
  }



  toggleDocumentCheck(doc: any): void {
    if (doc.checked) {
      // Add the entire document to the checkedDocuments array if it's selected
      this.checkedDocuments.push(doc);
    } else {
      // Remove the document from checkedDocuments based on its unique property (dostDk)
      this.checkedDocuments = this.checkedDocuments.filter(
        selectedDoc => selectedDoc.dostDk !== doc.dostDk
      );
    }
    console.log('this.checkedDocuments', this.checkedDocuments)
  }

  clearSearchDoc(event: any): void {
    this.searchTermDoc = ''; // Reset the search term
    this.filterDocuments();   // Reapply filter to show the full list
  }

  sortDocumentsObrigatorio(documents: any[]): any[] {
    return documents.sort((a, b) => {
      // First, sort by dtstInObrigatorio ("S" comes before "N")
      if (a.dtstInObrigatorio === 'S' && b.dtstInObrigatorio !== 'S') {
        return -1; // a comes before b
      } else if (a.dtstInObrigatorio !== 'S' && b.dtstInObrigatorio === 'S') {
        return 1;  // b comes before a
      }
      return a.dostNmDocumento.localeCompare(b.dostNmDocumento);
    });
  }
  sortDocumentsNome(documents: any) {
    documents.sort((a: any, b: any) => {
      const nameA = a.dostNmDocumento.toLowerCase();
      const nameB = b.dostNmDocumento.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // They are equal
    });
  }
  selectTemaServTec(tema: any, editingServicoTecnico: any) {
    this.vinculoTipo = "documentos"
    this.editingServicoTecnico.tema = tema
    console.log("this.editingServicoTecnico", this.editingServicoTecnico)
    console.log("this.allDocumentosArr", this.allDocumentosArr)
    console.log("this.allServicosOriginal", this.allServicosOriginal)
    this.selectedTemaServTec = this.editingServicoTecnico
    this.findVinculoServDoc()
  }



  findVinculoServDoc() {
    this.spinner = true;
    this.adminDatabaseService.getVinculosDocServ(this.editingServicoTecnico.pstcDk, this.editingServicoTecnico.tema.tmanDk).subscribe((docsComVinculo: any) => {
      console.log("docsComVinculo", docsComVinculo)
      // Create a set of dostDk from the second array for quick lookup
      const dostDkSet = new Set(docsComVinculo.map((doc: any) => doc.dostDk));
      // Iterate over the first array and update the properties
      this.filteredDocuments = [...this.allDocumentosArr]
      this.filteredDocuments.forEach((doc: any) => {
        doc.disabled = false;
        doc.checked = false;
        doc.dtstInObrigatorio = 'N'
        for (const docVinc of docsComVinculo) {
          if (docVinc.dostDk === doc.dostDk) {
            doc.disabled = true;
            doc.dtstDk = docVinc.dtstDk
            doc.dtstInObrigatorio = docVinc.dtstInObrigatorio
          }
        }
      });
      this.filterDocuments()
    })
  }


  updateObrigatorioVinculo(event: any, doc: any): void {
    doc.dtstInObrigatorio = event.checked ? 'S' : 'N';
    console.log(this.filteredDocuments)
  }

  updateObrigatorioDb(documento: any) {
    console.log("documento", documento)

    documento.dtstInObrigatorio === "S" ? documento.dtstInObrigatorio = "N" : documento.dtstInObrigatorio = "S"

    let objToUpdate: any = {
      "dostDk": documento.dostDk,
      "dostNmDocumento": documento.dostNmDocumento,
      "dostDsDocumento": documento.dostDsDocumento,
      "dtstDk": documento.dtstDk,
      "dtstInObrigatorio": documento.dtstInObrigatorio,
      TMST_DK: this.editingServicoTecnico.tema.tmanDk,
      PSTC_DK: this.selectedTemaServTec.pstcDk
    }

    console.log("objToUpdate", objToUpdate)
    this.saveEditDocumento('UPDATE', objToUpdate)
  }

  saveEditVinculoServDoc() {
    let arrToSave: any = []
    let arrToPushInServ: any = []
    console.log("this.selectedTemaServTec", this.selectedTemaServTec)
    for (const doc of this.checkedDocuments) {
      let objToPush: any = {}
      objToPush.dostDk = doc.dostDk
      objToPush.tmanDk = this.editingServicoTecnico.tema.tmanDk
      objToPush.pstcDk = this.selectedTemaServTec.pstcDk
      objToPush.dtstInObrigatorio = doc.dtstInObrigatorio
      arrToPushInServ.push(doc)
      arrToSave.push(objToPush)
    }
    this.adminDatabaseService.VinculaDocServTec(arrToSave).subscribe(
      (event: any) => {
        this.checkedDocuments = []
        this.searchTermDoc = ''
        this.selectedFilterDocs = 'vinculados'
        this.findVinculoServDoc()
      },
      (error) => {
        this.reloadArr = false
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
      }
    );
  }
  saveEditDocumento(action: any, editingDocumento: any) {
    this.adminDatabaseService.CruDocServTec(editingDocumento, action).subscribe(
      (event: any) => {
        console.log('Servico gravado:', event);
        if (action === 'UPDATE') {
          this.openSnackBar('Documento atualizado  com sucesso.')
          this.selectedFilterDocs = 'vinculados'
          this.findVinculoServDoc()

        }
        if (action === 'DELETE') {
          this.mustReloadParent = true
          this.openSnackBar('Documento excluído com sucesso.')
        }
      },
      (error) => {
        console.error('Error creating Documento:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );

  }
  deleteVinculoServDoc(documento: any) {
    console.log(documento)
    this.adminDatabaseService.DellVinculoDocServTec(documento.dtstDk).subscribe(
      (event: any) => {
        console.log('Servico gravado:', event);
        this.openSnackBar('Vínculo removido  com sucesso.')
        this.selectedFilterDocs = 'vinculados'
        this.findVinculoServDoc()
      },
      (error) => {
        console.error('Error creating Documento:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );
  }
}

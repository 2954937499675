import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SatDatabaseService } from '@shared/services/sat-database.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { take } from 'rxjs';
@Component({
  selector: 'app-catalogo-servicos',
  templateUrl: './catalogo-servicos.component.html',
  styleUrls: ['./catalogo-servicos.component.scss']
})
export class CatalogoServicosComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: any = MatAccordion;
  @ViewChildren(MatExpansionPanel) expansionPanel: any = QueryList<MatExpansionPanel>;
  @ViewChildren(MatExpansionPanel) panels: any = QueryList<MatExpansionPanel>;
  areasOriginal: any = [];
  areasArr: any = []
  filteredAreas: any = [];
  searchTema = '';
  spinner = true;

  searchGlobal = '';
  allServicosOriginal: any = []
  filteredServicos: any = [];
  timeout: any = null;
  timeoutOpenPanel: any = null;
  panelSpinner = false

  multiAreas = false
  constructor(private satDatabaseService: SatDatabaseService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAreasTemas();

  }

  getAreasTemas() {
    this.satDatabaseService.areasLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        this.areasArr = this.satDatabaseService.areasArr;
        this.selectedServico();
      }
    });
    /* this.satDatabaseService.getAreasTemas().pipe(
      take(1)  // Automatically unsubscribes after one emission
    ).subscribe((json: any) => {
      this.areasArr = []
      this.areasArr = json.AreaServTec;

      this.selectedServico();
    }); */
  }
  selectedServico() {
    console.log("comecou")
    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let pushArea
    for (const area of this.areasArr) {
      //  console.log(area)
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: []
        }
        for (const servico of tema[0].servTec) {

          let preServico: any = {
            servico: servico[0].pstcNmServTec,
            DK_PARA_GRAVAR: servico[0].tmsDk,
            tmsDk: servico[0].tmsDk,

            docsObrigatorios: [],
            docsDesejaveis: []
          }

          servico['docObrigatorios'] = []
          servico['docDesejaveis'] = []
          if (servico.selected) {
            areasArrDks.push(area.ArstDk)
            temasArrDks.push(tema[0].tmanDk)
            area['selected'] = true
            tema['selected'] = true
            pushArea = true
            pushTema = true

            for (const documento of servico[0].doc) {

              if (documento.dtstInObrigatorio === "S") {
                preServico.docsObrigatorios.push(documento)
                servico['docObrigatorios'].push(documento);
              }
              if (documento.dtstInObrigatorio === "N") {
                preServico.docsDesejaveis.push(documento)
                servico['docDesejaveis'].push(documento);
              }
            }
            preTema.servicos.push(preServico)
          }


        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

      }

      if (pushArea === true) {
        areaTotal.push(preArea)
      }

    }

    this.getTemas()
    console.log("acabou")

  }

  getTemas() {
    this.spinner = true;
    let original = this.areasArr;
    console.log(original)
    for (const area of original) {
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      for (const tema of area.Temas) {
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          tmanDk: tema[0].tmanDk,
          servicos: []
        }
        for (const servTec of tema[0].servTec) {
          servTec[0].tmanDk = tema[0].tmanDk
          this.allServicosOriginal.push(servTec[0])
          preTema.servicos.push(servTec[0])
        }
        preArea.temas.push(preTema)
      }
      this.areasOriginal.push(preArea)
    }
    if (this.filteredServicos.length === 0) {
      this.filteredServicos = this.allServicosOriginal;
    }
    console.log("this.areasOriginalOriginal", this.areasOriginal)

    if (this.filteredAreas.length === 0) {
      this.filteredAreas = this.areasOriginal;
    }
    this.spinner = false;
  }



  filterServicos(value: string, tema: any): void {
    if (tema['servTecOriginal'] === undefined) {
      tema['servTecOriginal'] = tema.servTec
    } else {
      tema.servTec = tema['servTecOriginal']
    }
    const filterValue = value.toLowerCase();
    tema.servTec = tema.servTec.filter((serv: any) =>
      this.removeDiacritics(serv[0].pstcNmServTec.toLowerCase()).includes(filterValue)
    );
  }

  filterServicosGlobal(event: any): void {
    this.panelSpinner = true
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutOpenPanel);
    const filterValue = this.searchGlobal.toLowerCase();

    const searchTerm = this.searchGlobal.toLowerCase();
    let data = this.areasOriginal;

    let _self = this
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        // Filter areas with temas containing servicos matching the search term
        const filteredAreas = data.map((area: any) => {
          const temasWithFilteredServicos = area.temas.map((tema: any) => {
            const filteredServicos = tema.servicos.filter((servico: any) =>
              servico.pstcNmServTec.toLowerCase().includes(searchTerm)
            );
            return { ...tema, servicos: filteredServicos };
          }).filter((tema: any) => tema.servicos.length > 0); // Exclude temas with empty servicos
          return { ...area, temas: temasWithFilteredServicos };
        }).filter((area: any) => area.temas.length > 0); // Exclude areas with empty temas

        _self.filteredAreas = filteredAreas;
        _self.panelSpinner = false
        _self.multiAreas = true
        _self.timeout = setTimeout(function () {
          if (searchTerm !== '') {

            _self.openAll();
          } else {
            _self.multiAreas = false
          }
        }, 1000);
      }
    }, 1000);


  }


  filterServicosGlobalooo(): void {
    const filterValue = this.searchGlobal.toLowerCase();
    function filter(array: any, fn: any) {
      return array.reduce((r: any, o: any) => {
        var children = filter(o.children || [], fn);
        if (fn(o) || children.length) r.push(Object.assign({}, o, children.length && { children }));
        return r;
      }, []);
    }


    var data: any = this.areasOriginal,
      result: any = filter(data, (pstcNmServTec: any) => {
        return pstcNmServTec.toLowerCase() === 'cultural';
      });

    console.log(result);
  }

  filterGlobal() {
    const filterValue = this.searchGlobal.toLowerCase();
    const filteredAreasCopy = JSON.parse(JSON.stringify(this.areasOriginal)); // Deep copy
    console.log("filterValue", filterValue)
    filteredAreasCopy.forEach((area: any) => {
      area.Temas.forEach((tema: any) => {
        //  console.log(" tema[0].servTec", tema[0].servTec)
        tema[0].servTec = tema[0].servTec.filter((serv: any) =>
          this.removeDiacritics(serv[0].pstcNmServTec.toLowerCase()).includes(filterValue)
        );
      });
    });

    setTimeout(() => {
      this.openAll()
      console.log("this.openAll()")
    }, 1000);

    this.filteredAreas = filteredAreasCopy

  }

  removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  openAll() {
    console.log("this.openAll()")
    this.panels.forEach((panel: any) => panel.open())
    this.expansionPanel.toArray().forEach((panel: any) => {
      panel.open();
    })
  }

  openDialog(servico: any): void {
    console.log(servico)

    let docsObrigatorios: any = []
    let docsDesejaveis: any = []

    for (const doc of servico.doc) {
      console.log(doc)
      if (doc.dtstInObrigatorio === 'S') {
        docsObrigatorios.push(doc)
      }
      if (doc.dtstInObrigatorio === 'N') {
        docsDesejaveis.push(doc)
      }
    }


    const dialogRef = this.dialog.open(DetalhesServicoDialog, {
      data: { servico, docsObrigatorios, docsDesejaveis },
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



  selectedServicoOld() {
    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let pushArea
    for (const area of this.areasOriginal) {
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: []
        }
        for (const servico of tema[0].servTec) {

          let preServico: any = {
            servico: servico[0].pstcNmServTec,
            DK_PARA_GRAVAR: servico[0].tmsDk,
            tmsDk: servico[0].tmsDk,
            docsObrigatorios: [],
            docsDesejaveis: []
          }

          servico['docObrigatorios'] = []
          servico['docDesejaveis'] = []

          if (servico.selected) {
            areasArrDks.push(area.ArstDk)
            temasArrDks.push(tema[0].tmanDk)
            area['selected'] = true
            tema['selected'] = true
            pushArea = true
            pushTema = true
            for (const documento of servico[0].doc) {
              documento['idsArr'] = ['']
              if (documento.dtstInObrigatorio === "S") {
                preServico.docsObrigatorios.push(documento)
                servico['docObrigatorios'].push(documento);
              }
              if (documento.dtstInObrigatorio === "N") {
                preServico.docsDesejaveis.push(documento)
                servico['docDesejaveis'].push(documento);
              }
            }
            preTema.servicos.push(preServico)
          }


        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

      }

      if (pushArea === true) {
        areaTotal.push(preArea)
      }

    }
    //  let idsAreasArr = [...new Set(areasArrDks)];
    //  let idsTemasArr = [...new Set(temasArrDks)];
    //  this.satFormService.preForm.areasArrDks = idsAreasArr
    // this.satFormService.preForm.temasArrDks = idsTemasArr
    // this.satFormService.preForm.areasArr = areaTotal
    //  this.satFormService.checkPreFormToNavigate()
  }

}

@Component({
  selector: 'detalhes-servico',
  templateUrl: 'detalhes-servico.html',
})
export class DetalhesServicoDialog {
  constructor(
    public dialogRef: MatDialogRef<DetalhesServicoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

<!--  Enviar SAT   ************************************************* -->
<div style="min-height: 165px; display: block">
  <mat-card style="box-shadow: none !important"
            *ngIf="satFormService.satState.status === 'enviando'" @fade>
    <mat-card-title>Enviando SAT...</mat-card-title>
    <mat-card-content>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
    <mat-card-actions> </mat-card-actions>
    <mat-card-footer>
      <div style="font-size: 20px; color: #818181; text-align: center;">{{
        satFormService.uploadProgress }}%</div>
      <div style=" padding: 12px;" *ngIf="totalMb > 10">Você está enviando um total
        de {{totalMb}}MB em arquivos anexados.
        Esta operação pode
        levar alguns minutos. Por favor, não feche esta página.</div>

    </mat-card-footer>
  </mat-card>



  <div style="padding: 20px; margin-top: 50px; text-align: center;"
       *ngIf="satFormService.satState.status === 'enviada'"
       @fade>
    <div>
      <mat-icon class="icon-enviado"
                mat-list-icon>pending_actions</mat-icon>
    </div>
    <div
         style="text-align: center; color: #1F2022; font-size: 25px; font-weight: 600;margin-top: 15px;">
      SAT aguardando aprovação</div>
    <!--  <div
         style="text-align: center; color: #5D899D; font-size: 34px; font-weight: 400;">
      aprovação</div> -->
    <p style="max-width: 396px;
    margin: auto;
    margin-top: 30px;
">A SAT foi criada. Agora, o Membro solicitante deve aprovar e enviar ao GATE para análise.</p>

  </div>

  <div style="padding: 20px; margin-top: 50px; text-align: center;"
       *ngIf="satFormService.satState.status === 'submetendo'"
       @fade>
    <div style="text-align: center;">
      <mat-spinner [diameter]="50"
                   style="margin: auto !important; width: 50px; height: 50px; color: #5D899D;"></mat-spinner>
    </div>
    <div
         style="text-align: center; color: #1F2022; font-size: 25px; font-weight: 600;">
      Enviando para o GATE </div>
    <!-- <div
         style="text-align: center; color: #5D899D; font-size: 34px; font-weight: 400;">
      ao GATE</div> -->
    <p>Por favor, aguarde nesta página.</p>
  </div>

  <div style="padding: 20px; margin-top: 50px; text-align: center;"
       *ngIf="satFormService.satState.status === 'submetida'"
       @fade>
    <div>
      <mat-icon class="icon-enviado"
                mat-list-icon>done</mat-icon>
    </div>
    <div
         style="text-align: center; color: #1F2022; font-size: 25px; font-weight: 600;">
      SAT enviada para o GATE </div>


  </div>

  <div style="padding: 20px; margin-top: 50px; text-align: center;"
       *ngIf="satFormService.satState.status === 'editada'"
       @fade>
    <div>
      <mat-icon class="icon-enviado"
                mat-list-icon>pending_actions</mat-icon>
    </div>
    <div
         style="text-align: center; color: #1F2022; font-size: 34px; font-weight: 600;">
      SAT editada </div>
    <div
         style="text-align: center; color: #5D899D; font-size: 34px; font-weight: 400;">
      com sucesso</div>
    <p>A SAT foi editada no sistema. Para que
      seja analisada, a SAT ainda deve ser aprovada e
      enviada para o GATE pelo Membro solicitante.</p>

  </div>

  <div
       *ngIf="satFormService.stepToLoad === 'enviarSat' && (satFormService.satState.status === 'submetida' || satFormService.satState.status === 'enviada'  || satFormService.satState.status === 'editada')"

       style="margin-top: 40px;margin-bottom: 40px;text-align: center;">
    <!-- <button *ngIf="satFormService.satState.sateDk !== null"

              (click)="openDialog(satFormService.satState.sateDk)"
              mat-flat-button
              color="accent"
              style="max-width: 290px; margin-right: 12px;">
        Ver SAT
      </button> -->
    <button

            [routerLink]="'/sat-lista'"
            mat-flat-button
            color="accent"
            style="max-width: 290px">
      Lista de SAT's
    </button>
  </div>











  <div style="padding: 20px; margin-top: 50px; text-align: center;"
       *ngIf="satFormService.satState.status === 'error'" @fade>
    <div>
      <mat-icon class="icon-enviado error"
                mat-list-icon>error</mat-icon>
    </div>
    <div
         style="text-align: center; color: #ad4747; font-size: 34px; font-weight: 600;">
      SAT não enviada </div>
    <div
         style="text-align: center; color: #5a5a5a; font-size: 12px; font-weight: 400;">
      erro: {{satFormService.satState.error}}</div>
  </div>

</div>
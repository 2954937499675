import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, merge, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { LoginService } from './login.service';
import { filterObject, isEmptyObject } from './helpers';
import { User } from './interface';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router';
import { SatDatabaseService } from '@shared/services/sat-database.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user$ = new BehaviorSubject<User>({});
  usrUsername: any;
  usrPassword: any;
  counter: number = 500;
  counterInterval: any;
  errorLogin = null

  private change$ = merge(
    this.tokenService.change(),
    this.tokenService.refresh().pipe(switchMap(() => this.refresh()))
  ).pipe(
    switchMap(() => this.assignUser()),
    share()
  );

  tokenStr = null

  login_token = null

  constructor(private loginService: LoginService, private tokenService: TokenService, private router: Router, private satDatabaseService: SatDatabaseService) { }

  init() {
    // return new Promise<void>(resolve => this.change$.subscribe(() => resolve()));
  }

  change() {
    return this.change$;
  }

  check() {


    return this.tokenService.valid();
  }



  login(username: string, password: string, rememberMe = false) {
    this.errorLogin = null
    this.user$.next({})
    this.usrUsername = username
    this.usrPassword = password
    return this.loginService.login(this.usrUsername, this.usrPassword, rememberMe).subscribe(
      (login: any) => {
        this.check()
        if (login.body.data !== undefined && login.body.data !== null) {
          let tk = login.body.data.token.toString()
          this.tokenStr = tk
          localStorage.setItem("login_token", login.body.data.token)

          if (login.body.error === null) {
            this.getAuth(login.body.data.token)

          }
        } else {
          this.router.navigateByUrl('/auth/login')
        }

        if (login.body.error !== null) {
          this.router.navigateByUrl('/auth/login')
          this.errorLogin = login.body.error.message
          this.user$.next({ errorLogin: true })
        }
      }
    );
  }

  getAuth(token: any) {
    let lg_tk = localStorage.getItem("login_token")
    this.loginService.getAuth(lg_tk).subscribe((response: any) => {
      console.log(response)
      if (response.success === true) {

        let tkObj = { access_token: response.data.token, token_type: 'bearer' }
        this.tokenService.set(tkObj)
        this.satDatabaseService.initializeService()
        this.router.navigateByUrl('/sat-lista');
        return this.user()
      } else {
        return this.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
      }

    })
  }


  startCounter() {
    const interval = 30 * 60 * 1000; // 30 minutes in milliseconds
    this.counter = interval / 1000; // Convert milliseconds to seconds

    this.counterInterval = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        clearInterval(this.counterInterval); // Stop the timer when it reaches 0
        this.executeAfter30Minutes();
      }
    }, 1000); // Update the counter every second
  }
  stopCounter() {
    clearInterval(this.counterInterval); // Clear the interval using the stored interval ID
  }

  executeAfter30Minutes() {
    // this.logout()
    this.gotoLogin()
    // Add your logic to execute after 30 minutes here
  }
  gotoLogin() {
    this.login(this.usrUsername, this.usrPassword, false)
    // this.router.navigateByUrl('/auth/login')
  }
  formatCounter(): string {
    const minutes = Math.floor(this.counter / 60);
    const seconds = this.counter % 60;
    return `${minutes}:${seconds}`;
  }

  refresh() {
    return this.loginService
      .refresh(filterObject({ refresh_token: this.tokenService.getRefreshToken() }))
      .pipe(
        catchError(() => of(undefined)),
        tap(token => this.tokenService.set(token)),
        map(() => this.check())
      );
  }

  logout() {
    // this.usrUsername = null
    // this.usrPassword = null
    this.stopCounter()
    this.user$.next({}); // Reset user state
    return this.loginService.logout().pipe(
      tap(() => this.tokenService.clear()),
      map(() => !this.check())
    );
  }

  user() {

    const tok = jwt_decode(this.tokenService.getBearerToken())
    this.user$.next({ tok })

    return this.user$.pipe(share());
  }



  menu() {
    return iif(() => this.check(), this.loginService.menu(), of([]));
  }

  async assignUser() {

    if (!this.check()) {
      return of({}).pipe(tap(user => this.user$.next(user)));
    }

    if (!isEmptyObject(this.user$.getValue())) {
      return of(this.user$.getValue());
    }

    return this.loginService.me().pipe(tap(user => this.user$.next(user)));





  }
}



<div
     style="margin-bottom: 30px;">
  <!-- First button aligned to the start (left) -->


  <!-- Second button aligned to the end (right) -->
  <mat-chip-list>
    <!-- Chip for "Todos os serviços" -->
    <mat-chip color="accent"
              selectable="false"
              [disabled]="listView==='all'"
              (click)="getAllServicosTecnicos()"
              [ngClass]="{ 'active-chip': listView === 'all' }"
              style="margin-right: 20px;">
      Todos os serviços
    </mat-chip>

    <!-- Chip for "Serviços sem vínculos com temas" -->
    <mat-chip color="accent"
              selectable="false"
              [disabled]="listView==='orphan'"
              (click)="getOrphanServicosTecnicos()"
              [ngClass]="{ 'active-chip': listView === 'orphan' }"
              style="margin-right: 20px;">
      Serviços sem vínculos com temas
    </mat-chip>

    <!-- Chip for "Serviços deletáveis" -->
    <mat-chip color="accent"
              selectable="false"
              [disabled]="listView==='deleteable'"
              (click)="getDeleteableServicosTecnicos()"
              [ngClass]="{ 'active-chip': listView === 'deleteable' }"
              style="margin-right: 20px;">
      Serviços deletáveis
    </mat-chip>
  </mat-chip-list>
</div>
<div *ngIf="spinner === true">
  <div style="margin-bottom: 15px;">Carregando lista de serviços técnicos
  </div>
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>



<div *ngIf="spinner === false  && editar===false">
  <div *ngIf="listView === 'all'">
    <div><strong>Todos os serviços</strong></div>
    <div style="font-size: 14px;">Lista de todos os serviços cadastrados, não importando vínculos
      com
      Temas ou Documentos.</div>
  </div>
  <div *ngIf="listView === 'orphan'">
    <div><strong>Serviços sem vínculos com temas</strong></div>
    <div style="font-size: 14px;">Esses serviços não
      estão
      disponíveis para o preenchimento da SAT.</div>
  </div>
  <div *ngIf="listView === 'deleteable'">
    <div><strong>Serviços deletáveis</strong></div>
    <div style="font-size: 14px;">Os serviços deletáveis são aqueles que nunca foram utilizados em
      preenchimentos de SAT's.
      Se deletados, serão permanentemente excluídos do banco de dados.</div>
  </div>
  <!-- Search bar -->
  <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 10px;">
    <mat-label>Procurar serviços</mat-label>
    <input matInput placeholder="Procurar pelo nome do serviço" [(ngModel)]="searchText"
           (ngModelChange)="applyFilter()">
    <!-- Clear button -->
    <button mat-icon-button matSuffix *ngIf="searchText" (click)="clearSearch()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>

  <!-- Filtered List -->
  <!-- Filtered List -->
  <div class="list-view">
    <ul style="margin: 0; padding: 0;">
      <li *ngFor="let item of filteredServtec; let i = index"
          [ngStyle]="{ 'background-color': hoverIndex === i ? '#f0f0f0' : 'transparent' }"
          (mouseover)="hoverIndex = i" (mouseout)="hoverIndex = -1"
          style="display: flex; justify-content: space-between; align-items: center; padding: 4px 0; font-size: 14px;">

        <!-- Left Section: Index and Service Name -->
        <div style="display: flex; align-items: center;">
          <!-- Index -->
          <span style="width: 22px; text-align: left; flex-shrink: 0;">
            <strong>{{ i + 1 }}.</strong>
          </span>

          <!-- Service Name -->
          <span style="margin-left: 10px; word-wrap: break-word;">
            {{ item.pstcNmServTec }}
          </span>
        </div>

        <!-- Right Section: Buttons -->
        <div style="display: flex; align-items: center; gap: 8px; flex-shrink: 0;">
          <!-- Edit Button -->
          <button mat-stroked-button color="accent"
                  (click)="openUpdateDialog(item, 'servtec')">
            Editar
          </button>

          <!-- Vinculos Button -->
          <button mat-stroked-button color="accent" (click)="openUpdateDialog(item, 'vinculo')">
            Vínculos
          </button>

          <!-- Delete Button -->
          <button *ngIf="listView === 'deleteable'" mat-icon-button color="warn"
                  aria-label="Delete Service" (click)="deleteService(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </li>
    </ul>
  </div>



  <!-- No results message -->
  <div *ngIf="filteredServtec.length === 0">
    <p>Nenhum serviço encontrado "{{ searchText }}"</p>
  </div>
</div>
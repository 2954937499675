import { Component, OnInit, Inject } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-temas-servicos',
  templateUrl: './temas-servicos.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class TemasServicosComponent implements OnInit {
  areas: any = [];
  filteredAreas: any = [];
  searchTema = '';

  spinner = true

  lastRandomNumber: any;

  uploadAll = false

  AreaPanelOpenState = false;

  tmsDkArray: any = []
  constructor(
    public satFormService: SatFormService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.tmsDkArray = this.satFormService.tmsDkArray
    this.getTemas();
  }


  getTemas() {
    if (this.satFormService.areasArr.length === 0) {
      this.spinner = true;
    }
    this.satFormService.temasServicosLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        console.log("loaded - component", this.satFormService.areasArr)
        this.areas = this.satFormService.areasArr;
        if (this.filteredAreas.length === 0) {
          this.filteredAreas = this.areas;
          //   this.selectedServico()
        }
        this.spinner = false;
      }
    }).unsubscribe();
  }

  filterServicos(value: string, tema: any): void {
    if (tema['servTecOriginal'] === undefined) {
      tema['servTecOriginal'] = tema.servTec
    } else {
      tema.servTec = tema['servTecOriginal']
    }
    const filterValue = value.toLowerCase();
    tema.servTec = tema.servTec.filter((serv: any) =>
      this.removeDiacritics(serv[0].pstcNmServTec.toLowerCase()).includes(filterValue)
    );
  }

  removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }



  checkServicoSelected(serviLista: any) {
    console.log(serviLista)
    for (const area of this.satFormService.preForm.areasArr) {
      for (const tema of area.temas) {
        for (const servico of tema.servicos) {
          if (servico.tmsDk === serviLista.tmsDk) {
            return true
          }
        }
      }
    }
    return false
  }


  trackByArea(index: number, area: any): any {
    return area.id;  // Ensure each area has a unique identifier
  }

  trackByTema(index: number, tema: any): any {
    return tema.id;  // Ensure each tema has a unique identifier
  }

  trackByServico(index: number, servico: any): any {
    return servico.id;  // Ensure each servico has a unique identifier
  }


}

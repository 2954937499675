<!--  DEFINIÇÃO  PRIORIDADE************************************************* -->
<div style="min-height: 165px; display: block">


  <h3>Selecione a hipótese legal, se houver.</h3>
  <mat-radio-group class="radio-group"
                   [(ngModel)]="satFormService.preForm.prefLegalObj">
    <mat-radio-button class="radio-button"
                      (click)="prefChange(pref.plegDk)"
                      *ngFor="let pref of optPrefLegaisArr"
                      [value]="pref"
                      [checked]="pref.checked === true">
      {{ pref.plegNmPreferenciaLegal }}
    </mat-radio-button>
  </mat-radio-group>



  <h3>Há necessidade de atendimento prioritário ou com data limite para a solicitação?</h3>
  <!--   <mat-button-toggle-group
                           aria-label="Font Style"
                           [(ngModel)]="satFormService.preForm.atendPrioritarioObj.isPrioritario">
    <mat-button-toggle [value]="false">Não</mat-button-toggle>
    <mat-button-toggle [value]="true">Sim</mat-button-toggle>
  </mat-button-toggle-group> -->

  <div>
    <button mat-stroked-button class="bt-opcoes"
            (click)="satFormService.preForm.atendPrioritarioObj.isPrioritario = 'N'; resetPrior()"
            [ngClass]="{'selected-button': satFormService.preForm.atendPrioritarioObj.isPrioritario === 'N'}">
      Não
    </button>

    <button mat-stroked-button class="bt-opcoes"
            (click)="satFormService.preForm.atendPrioritarioObj.isPrioritario = 'S' ;satFormService.checkPreFormToNavigate()"
            [ngClass]="{'selected-button': satFormService.preForm.atendPrioritarioObj.isPrioritario === 'S'}">
      Sim
    </button>
  </div>

  <div *ngIf="satFormService.preForm.atendPrioritarioObj.isPrioritario === 'S'">




    <div
         *ngIf=" spinnerPrioridades === false">
      <p>Informe o tipo de prazo aplicável</p>
      <mat-radio-group class="radio-group"
                       [(ngModel)]="satFormService.preForm.atendPrioritarioObj.tipoPrazo">
        <mat-radio-button class="radio-button"
                          *ngFor="let prioridade of optPrioridadesArr"
                          [value]="prioridade"
                          (click)="priorChange(prioridade.tpplDk);satFormService.checkPreFormToNavigate()">
          {{ prioridade.tpplNmTpPrioridade }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="mat-form-box"
           *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk === 4">
        <p>Informe a situação excepcional</p>
        <mat-form-field style="width: 100%" appearance="fill">
          <textarea
                    [(ngModel)]="satFormService.preForm.atendPrioritarioObj.exceptPrioridade"
                    matInput
                    (input)="satFormService.checkPreFormToNavigate()"
                    rows="7" maxlength="600"
                    placeholder="situação excepcional"></textarea>
          <mat-hint
                    align="end">{{
            satFormService.preForm.atendPrioritarioObj?.exceptPrioridade === null ? 0 :
            satFormService.preForm.atendPrioritarioObj?.exceptPrioridade.length}}
            / 600</mat-hint>
        </mat-form-field>
      </div>

      <!-- <div *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk === 3">
        <p>Informe a data do <strong>Prazo prescricional </strong></p>

        <mat-form-field appearance="fill">
          <mat-label>Selecione uma data</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="satFormService.preForm.atendPrioritarioObj.dtPrazoPrescr"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div> -->
      <!-- UPLOAD DOC********************* -->
      <div *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk !== null">
        <h4>
          Anexe documento comprobatório
          <span style="font-weight: bold">
            <span *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk == 1">
              | Intimação</span>
            <span *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk == 2">
              | Portaria de IC</span>
            <span *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk == 3">
              que indique a data do fato</span>
            <span *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk == 4">
              (opcional)</span>
          </span>
        </h4>
        <app-file-dnd [saaxInObrigatorio]="false" [tpaxDk]="1" [multipleFiles]="true"
                      (newItemEvent)="satFormService.checkPreFormToNavigate()" [isSATFile]="true"
                      saaxDsObservacao="Prioridade"></app-file-dnd>
      </div>

      <div class="mat-form-box"
           *ngIf="satFormService.preForm.atendPrioritarioObj.tipoPrazo?.tpplDk !== null">
        <p>Informe a data limite pretendida para atendimento <mat-icon class="info"
                    (click)="satFormService.abrirProtocGestao()"
                    matTooltipClass="tooltip-info"
                    matTooltip="Não serão aceitos prazos inferiores a 10 dias úteis, segundo artigo 21, inciso I, da OS 01/23.  Clique no ícone para abrir a OS."
                    aria-label="Info">info</mat-icon></p>

        <mat-form-field appearance="fill" style="width: 100%;" id="data-prioridade">
          <mat-label>Selecione uma data</mat-label>
          <input
                 matInput
                 (dateChange)="satFormService.checkPreFormToNavigate()"
                 [matDatepicker]="picker"
                 [min]="minDate"
                 [(ngModel)]="satFormService.preForm.atendPrioritarioObj.dtPrazoPrior"
                 disabled />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>


  </div>
</div>
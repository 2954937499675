import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';

@Component({
  selector: 'app-complementacao',
  templateUrl: './complementacao.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class ComplementacaoComponent implements OnInit {
  constructor(public satFormService: SatFormService) { }

  ngOnInit(): void { }
}

<!--  DEFINIÇÃO  UPLOAD DE ARQUIVOS ************************************************* -->
<div style="min-height: 165px; display: block">

  <p>
    Realize o upload dos arquivos obrigatórios e adicionais aos serviços selecionados para esta SAT
    <mat-icon class="info"
              (click)="satFormService.abrirProtocGestao()"
              matTooltipClass="tooltip-info"
              matTooltip="A anexação de documentos deve seguir rigorosamente o Protocolo de Gestão nº 01/23.  Clique no ícone para abrir a OS."
              aria-label="Info">info</mat-icon>
  </p>
  <div style="margin-bottom: 20px;">
    <h3>Documentos obrigatórios*</h3>
    <div *ngFor="let area of satFormService.preForm.areasArr">
      <div *ngFor="let tema of area.temas">
        <div class="subtitle">Tema</div>
        <div [matTooltip]="'Área: ' + area.area" matTooltipPosition="above"
             matTooltipClass="tooltip-info"
             style="  font-size: 18px;             font-weight: bold;">
          {{tema.tema|
          lowercaseAndTitlecase}}
        </div>

        <div *ngFor="let servico of tema.servicos">
          <div style="font-size: 14px; ">
            {{servico.servico|
            lowercaseAndTitlecase}}
          </div>
          <div>
            <ul>
              <li style="color: #7E8990;  font-size: 14px;"
                  *ngFor="let documento of servico.docsObrigatorios">
                {{ documento.dostNmDocumento }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <app-file-dnd (newItemEvent)="satFormService.checkPreFormToNavigate()"
                  [saaxInObrigatorio]="true" [tpaxDk]="3"
                  [isSATFile]="true"
                  saaxDsObservacao="Documento obrigatório"></app-file-dnd>


  </div>
  <h3 style="margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #d3d1d1;">
    Documentos Adicionais
  </h3>
  <div *ngFor="let area of satFormService.preForm.areasArr">
    <div *ngFor="let tema of area.temas ">
      <div class="subtitle">Tema</div>
      <div [matTooltip]="'Área: ' + area.area" matTooltipPosition="above"
           matTooltipClass="tooltip-info"
           style="  font-size: 18px;             font-weight: bold;">
        {{tema.tema|
        lowercaseAndTitlecase}}
      </div>
      <div *ngFor="let servico of tema.servicos">
        <div style="font-size: 14px; ">
          {{servico.servico|
          lowercaseAndTitlecase}}
        </div>
        <div>
          <ul>
            <li style="color: #7E8990;  font-size: 15px;"
                *ngFor="let documento of servico.docsDesejaveis">
              {{ documento.dostNmDocumento }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <app-file-dnd [saaxInObrigatorio]="false" [isSATFile]="true" [tpaxDk]="3" [isSATFile]="true"
                [multipleFiles]="true"
                (newItemEvent)="satFormService.checkPreFormToNavigate()"
                saaxDsObservacao="Documento desejável">
  </app-file-dnd>















  <div
       *ngIf="false"
       class="upload-parent"
       style="height: 450px; overflow: auto; overflow-x: hidden">
    <div *ngFor="let tema of satFormService.preForm.temasArr">
      <h3>{{ tema.TMAN_NM_TEMA }}</h3>
      <div *ngFor="let servico of tema.servObjs">
        <h4>{{ servico.NM_SERVICO }}</h4>
        <mat-list>
          <mat-list-item
                         class="lista clickable-item"
                         (click)="fileInput.click()"
                         *ngFor="let documento of servico.documentosArr">
            <mat-icon mat-list-icon>insert_drive_file</mat-icon>
            <div mat-line>{{ documento.dostNmDocumento }}</div>
            <div mat-line>
              <input
                     #fileInput
                     type="file"
                     style="display: none"
                     (change)="onFileChange($event, documento)"
                     accept=".pdf, .zip" />
              <span>{{ documento.fileName }}</span>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '@shared/services/storage.service';
import { Observable, BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-file-dnd',
  templateUrl: './file-dnd.component.html',
  styleUrls: ['./file-dnd.component.scss'],
})
export class FileDndComponent implements OnInit {
  @Input() multipleFiles: boolean = true; // Default value is true
  @Input() saaxInObrigatorio: boolean = false; // Default value is false
  @Input() tpaxDk: number = 1; // Declare an Input property for tpaxDk
  @Input() saaxDsObservacao: string = ""; // Declare an Input property for saaxDsObservacao
  @Input() isSATFile: boolean = true; // Declare an Input property for saaxDsObservacao
  @Output() newItemEvent = new EventEmitter<boolean>(false);

  public chechPreform: any = new BehaviorSubject<boolean>(false);


  files: any[] = [];

  constructor(public localStorageService: LocalStorageService) { }

  ngOnInit(): void {
  }



  /**
   * on file drop handler
   */
  onFileDropped(event: any) {
    if (!this.multipleFiles) {
      // If multipleFiles is set to false, handle only the first file
      const file = event[0];
      const reader = new FileReader();

      // Set up an event handler for when the file is loaded
      reader.onload = function (event) {
        // 'event.target.result' contains the Base64 data
        const base64String = event;
      }
      if (file) {
        this.prepareFilesList([file]);
      }
    } else {
      // If multipleFiles is true, handle all files
      this.prepareFilesList(event);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    const inputElement = event.target;
    const files = inputElement.files;

    // Check if files were selected
    if (files && files.length > 0) {
      // Iterate through each file
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];

        // Check if filename length exceeds 200 characters
        if (file.name.length > 200) {
          alert('O nome do arquivo não pode exceder 200 caracteres.');
          continue; // Skip this file and proceed to the next one
        }

        // Check if filename contains special characters
        const specialCharactersRegex = /[!@#$%&*,?"{}|<>\/\\]/;
        if (specialCharactersRegex.test(file.name)) {
          alert('O nome do arquivo não pode conter caracteres especiais. Por favor, renomeie o arquivo antes de anexá-lo.');
          continue; // Skip this file and proceed to the next one
        }

        // Check if filename contains one and only one dot before the file extension
        /*  const dotCount = file.name.split('.').length - 1;
         if (dotCount !== 1) {
           alert('O nome do arquivo deve conter apenas um ponto antes da extensão. Por favor, renomeie o arquivo antes de anexá-lo.');
           continue;
         } */

        // Proceed with further checks or processing if needed
        // For example, check file extension, file size, etc.

        const fileNameParts: string[] = file.name.split('.');


        if (fileNameParts.length < 2 || fileNameParts.some(part => part.trim() === '')) {
          alert('O nome do arquivo deve conter uma extensão válida. Por favor, renomeie o arquivo antes de anexá-lo.');
          continue; // Skip this file and proceed to the next one
        }


        const fileExtension: string = fileNameParts[fileNameParts.length - 1].toLowerCase();
        if (fileExtension === 'exe' || fileExtension === 'bat') {
          // Reject files with .exe or .bat extension
          alert('Por favor, selecione arquivos com extensões válidas.');
          continue; // Skip this file and proceed to the next one
        }

        // Check file size
        const maxFileSizeInBytes = 100 * 1024 * 1024; // 100MB in bytes
        const fileSizeInMB = file.size / (1000 * 1024); // Convert file size to megabytes
        const fileSizeRounded = Math.round(fileSizeInMB * 100) / 100; // Round to two decimal places

        if (fileSizeInMB > 90) {
          // Reject files larger than 100MB
          alert('O arquivo deve ser menor que 90MB. O tamanho do arquivo selecionado é de ' + fileSizeRounded + ' MB.');
          continue; // Skip this file and proceed to the next one
        }

        // Proceed with further processing if all checks pass
        let _self = this
        const reader = new FileReader();
        reader.onload = function (event: any) {
          // 'event.target.result' contains the Base64 data
          const base64String = event.target.result;
          const base64FileSizeInMB = base64String.length * 3 / (4 * 1000 * 1024); // Convert base64 size to megabytes
          if (base64FileSizeInMB > 90) {
            alert('O arquivo deve ser menor que 90MB. O tamanho do arquivo  é de: ' + Math.round(base64FileSizeInMB * 100) / 100 + ' MB.');
            return; // Skip this file and proceed to the next one
          }
          _self.prepareFilesList([file]);
        };
        reader.readAsDataURL(file);

        // Prepare files list

      }
    }
  }


  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {

    this.localStorageService.satFiles.splice(index, 1);
    this.files.splice(index, 1);
    this.newItemEvent.emit(true);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        /*  const progressInterval = setInterval(() => {
           if (this.localStorageService.satFiles[index].progress === 100) {
             clearInterval(progressInterval);
             this.uploadFilesSimulator(index + 1);
           } else {
             this.localStorageService.satFiles[index].progress += 5;
           }
         }, 200); */
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>) {

    for (const item of files) {
      item.progress = 0;
      try {
        const base64String = await this.fileToBase64(item);
        item.base64 = base64String
        item.tpaxDk = this.tpaxDk
        item.saaxInObrigatorio = this.saaxInObrigatorio
        item.saaxDsObservacao = this.saaxDsObservacao
        this.localStorageService.satFiles.push(item);
        this.files.push(item);
      } catch (error) {
        console.error(`Error converting file to Base64: `);
      }

      // console.log(this.satAnexosService.filesArr)
    }
    this.newItemEvent.emit(true);
    this.uploadFilesSimulator(0);
  }

  fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

<div style="min-height: 165px; display: block">

  <!-- DADOS DA SAT -->
  <div>


    <!-- ROW Detalhes cards SOLICITANTE &  PROCEDIMENTO-->
    <div class="matero-row " style="padding:6px;" fxLayout="row wrap">
      <!-- Detalhes cards -->
      <div class="text-valid" *ngIf="satFormService.navegacao.textToContinue !== ''">
        * {{satFormService.navegacao.textToContinue}}
      </div>

      <!--  DEFINIÇÃO  SOLICITANTE************************************************* -->
      <div class="confirmacao" (click)="satFormService.navigateToStepByStepName('solicitante')">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div class="title">
          Membro solicitante
        </div>
        <div class="text">
          {{ satFormService.preForm.solicitanteObj.nome }} -
          Mat. {{ satFormService.preForm.solicitanteObj.matricula }}
        </div>
      </div>




      <!--  DEFINIÇÃO  PROCEDIMENTO  ************************************************* -->


      <div class="confirmacao" (click)="satFormService.navigateToStepByStepName('procedimento')">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div class="title">Procedimento</div>
        <div class="text"><strong>Procedimento N.: </strong>{{
          satFormService.preForm.procedimentoObj.num_DOC }} </div>
        <div class="text"><strong>Órgão de Execução: </strong>{{
          satFormService.preForm.procedimentoObj.orgao }} </div>
        <div class="text"><strong>Origem: </strong>{{
          satFormService.preForm.procedimentoObj.origem }}
          ( <span>{{
            satFormService.preForm.procedimentoObj.sate_PSSO_DK !== 4
            ? 'Extrajudicial'
            : 'Judicial'
            }}
          </span>) </div>
        <div class="text"><strong>Assunto: </strong>{{
          satFormService.preForm.procedimentoObj.assunto_PROCESSO }} </div>


        <div class="text">
          <strong>E-mail do Órgão: </strong>{{ satFormService.preForm.procedimentoObj.email }}
        </div>
        <div class="text">
          <strong>Município(s) do Objeto: </strong>
          <span *ngIf="satFormService.preForm.municipiosArr.length > 0">
            <span *ngFor="let municipio of satFormService.preForm.municipiosArr; let isLast = last">
              {{municipio.CIDA_NM_CIDADE }}{{isLast ? "" : ", "}}</span></span>
          <span *ngIf="satFormService.preForm.municipiosArr.length === 0">Estado do Rio de
            Janeiro</span>
        </div>


        <!--  DEFINIÇÃO  COMPLEMENTACAO************************************************* -->


        <div class="subtitle">Complementação</div>
        <div class="text" *ngIf="satFormService.preForm.complementacaoObj.isComplementacao === 'N'">
          Esta SAT <strong>não </strong> é complementação de IT anterior
        </div>

        <div class="text" *ngIf="satFormService.preForm.complementacaoObj.isComplementacao === 'S'">
          Esta SAT é complementação da IT:
          <strong>{{satFormService.preForm.complementacaoObj.docTecAnteriorObj?.num_IT}}
          </strong>
        </div>
      </div>







      <!--  DEFINIÇÃO  TEMA************************************************* -->
      <div class="confirmacao" (click)="satFormService.navigateToStepByStepName('temas-servicos')">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div class="title">Tema(s) e Serviço(s) Técnico(s)</div>

        <div class="text" *ngFor="let area of satFormService.preForm.areasArr">
          <div class="area">Área: {{area.area}}</div>
          <div
               *ngFor="let tema of area.temas; let isLast = last"
               [ngClass]="isLast === true ? ' last' : ''">
            <strong>{{ tema.tema }}</strong>
            <ul
                *ngIf="tema.servicos?.length > 0">
              <li *ngFor="let servico of tema.servicos">
                {{ servico.servico }}

                <!--  <span
                      style="position: relative; top: 10px"
                      *ngFor="let documento of servico.docsObrigatorios">
                  <mat-icon
                            style="font-size: 16px; color: #666"
                            *ngIf="documento.fileName !== null"
                            [matTooltip]="documento.fileName"
                            mat-list-icon>insert_drive_file</mat-icon>
                </span> -->
              </li>
            </ul>
          </div>
        </div>

      </div>


      <!--  DEFINIÇÃO  DOCUMENTOS ************************************************* -->
      <div class="confirmacao"
           (click)="
           satFormService.isIntegra === false ?
           satFormService.navigateToStepByStepName('documentos-upload') :
           satFormService.navigateToStepByStepName('documentos-integra')
           ">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div *ngIf="satFormService.isIntegra === false">
          <div class="title">Upload de documentos</div>
          <div class="files-list">
            <div class="subtitle">Obrigatórios</div>
            <div *ngFor="let file of localStorageService.satFiles; let i = index">
              <div class="single-file"
                   *ngIf="file.tpaxDk === 3 && file.saaxInObrigatorio === true">
                <mat-list style="width: 100%">
                  <mat-list-item class="text" style="height: auto">
                    <mat-icon style="color: rgb(95, 95, 99);padding: 0px;"
                              mat-list-icon>attach_file</mat-icon>
                    <div>
                      {{ file?.name }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
          <div class="files-list">
            <div class="subtitle">Adicionais</div>
            <div *ngFor="let file of localStorageService.satFiles; let i = index">
              <div class="single-file"
                   *ngIf="file.tpaxDk === 3 && file.saaxInObrigatorio === false">
                <mat-list style="width: 100%">
                  <mat-list-item class="text" style="height: auto">
                    <mat-icon style="color: rgb(95, 95, 99);padding: 0px;"
                              mat-list-icon>attach_file</mat-icon>
                    <div>
                      {{ file?.name }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="satFormService.isIntegra === true">
          <div class="title">Documentos - Integra (*obrigatórios)</div>

          <div class="text" *ngFor="let area of satFormService.preForm.areasArr">
            <div
                 *ngFor="let tema of area.temas; let isLast = last"
                 [ngClass]="isLast === true ? ' last' : ''">
              <div
                   *ngIf="tema.servicos?.length > 0">
                <div *ngFor="let servico of tema.servicos">
                  <div
                       style="position: relative; top: 10px"
                       *ngFor="let documento of servico.docsObrigatorios">
                    <div *ngIf="documento.idsArr[0] !== ''">
                      <strong>{{documento.dostNmDocumento}}*: </strong>
                      <ul>
                        <li *ngFor="let idIntegra of documento.idsArr; let isLast= last">
                          {{idIntegra}}{{isLast ? '' : ','}}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                       style="position: relative; top: 10px"
                       *ngFor="let documento of servico.docsDesejaveis">
                    <div *ngIf="documento.idsArr[0] !== ''">
                      <strong>{{documento.dostNmDocumento}}: </strong>
                      <ul>
                        <li *ngFor="let idIntegra of documento.idsArr; let isLast= last">
                          {{idIntegra}}{{isLast ? '' : ','}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

      </div>






      <!--  DEFINIÇÃO  EXCEPCIONALIDADE************************************************* -->
      <div class="confirmacao"
           (click)="satFormService.navigateToStepByStepName('complementaridade')">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div class="title">Complementaridade</div>
        <div *ngIf="satFormService.preForm.excepcionalidadeObj.inManifTecAnterior=== 'S'">
          Esta SAT <strong>não </strong> possui excepcionalidade
          <div>Laudo a ser complementado: </div>
          <div *ngFor="let file of localStorageService.satFiles; let i = index">
            <div class="single-file"
                 *ngIf="file.tpaxDk === 2">
              <mat-list style="width: 100%">
                <mat-list-item class="text" style="height: auto">
                  <mat-icon style="color: rgb(95, 95, 99);padding: 0px;"
                            mat-list-icon>attach_file</mat-icon>
                  <div>
                    {{ file?.name }}
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
        <div *ngIf="satFormService.preForm.excepcionalidadeObj.inManifTecAnterior === 'N'">
          <strong> Esta SAT possui excepcionalidade: </strong>
          {{ satFormService.preForm.excepcionalidadeObj.dsJustifAusenciaCompl }}
        </div>
      </div>




      <!--  DEFINIÇÃO  PREFERENCIA LEGAL************************************************* -->
      <div class="confirmacao" (click)="satFormService.navigateToStepByStepName('prioridade')">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div class="title">Prioridade</div>

        <div class="text" *ngIf="satFormService.preForm.prefLegalObj.plegDk === null">
          Esta SAT <strong>não </strong> possui preferência legal
        </div>
        <div class="text" style="margin-bottom: 15px;"
             *ngIf="satFormService.preForm.prefLegalObj.plegDk !== null">
          <strong>Preferência legal: </strong>
          {{satFormService.preForm.prefLegalObj.plegNmPreferenciaLegal
          }}
        </div>


        <!--  DEFINIÇÃO  PRIORIDADE************************************************* -->

        <div *ngIf="satFormService.preForm.atendPrioritarioObj.isPrioritario === 'N'">
          Esta SAT <strong>não </strong> necessita de atendimento prioritário
        </div>
        <div class="text" *ngIf="satFormService.preForm.atendPrioritarioObj.isPrioritario === 'S'">
          <strong>Atendimento prioritário: </strong>
          {{ satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplNmTpPrioridade }}
          {{
          satFormService.preForm.atendPrioritarioObj.tipoPrazo.tpplDk === 4 ? ' - ' +
          satFormService.preForm.atendPrioritarioObj.exceptPrioridade
          : null }}

          <div>Documento comprobatório: </div>
          <div *ngFor="let file of localStorageService.satFiles; let i = index">
            <div class="single-file"
                 *ngIf="file.tpaxDk === 1">
              <mat-list style="width: 100%">
                <mat-list-item class="text" style="height: auto">
                  <mat-icon style="color: rgb(95, 95, 99);padding: 0px;"
                            mat-list-icon>attach_file</mat-icon>
                  <div>
                    {{ file?.name }}
                  </div>
                </mat-list-item>
              </mat-list>
            </div>

          </div>


          <div class="text" style="margin-top: 12px;">
            <strong> Data limite para atendimento: </strong>
            {{ satFormService.preForm.atendPrioritarioObj.dtPrazoPrior | date : 'dd/MM/yyyy' }}
          </div>
        </div>


      </div>


      <!--  DEFINIÇÃO  DUVIDA TECNICA************************************************* -->
      <div class="confirmacao" (click)="satFormService.navigateToStepByStepName('duvida')">
        <div class="editar">
          <mat-icon
                    style="font-size: 21px; width: 25px; height: 21px;"
                    aria-label="Editar">edit_document</mat-icon>Editar

        </div>
        <div class="title">Objetivo da solicitação</div>
        <div class="text">{{ satFormService.preForm.objetivoSolicitacaoStr }}</div>
      </div>




    </div>



  </div>









</div>
<h2 mat-dialog-title><strong>Atenção</strong></h2>
<div mat-dialog-content style="margin-bottom: 22px;">
  Se o procedimento for classificado como sigiloso, favor usar o número MPRJ
  informado no Integra.
</div>
<div mat-dialog-actions>
  <!-- "Não é sigiloso" button, closes the dialog with no consequences -->
  <button mat-stroked-button color="accent" [mat-dialog-close]="false">Não é sigiloso</button>

  <!-- "Sim, é sigiloso" button, we will use dialogRef.close() with a result -->
  <button mat-stroked-button color="accent" [mat-dialog-close]="true">Sim, é sigiloso</button>
</div>
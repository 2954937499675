<!--  DEFINIÇÃO  COMPLEMENTACAO SIM + N IT /NAO ************************************************* -->
<div style="min-height: 165px; display: block">
  <mat-card>
    <mat-card-title>Complementação</mat-card-title>
    <mat-card-content>
      <p>Esta SAT é complementação de IT anterior?</p>
      <mat-button-toggle-group
        aria-label="Font Style"
        [(ngModel)]="satFormService.preForm.complementacaoObj.isComplementacao"
      >
        <mat-button-toggle [value]="false">Não</mat-button-toggle>
        <mat-button-toggle [value]="true">Sim</mat-button-toggle>
      </mat-button-toggle-group>
      <div *ngIf="satFormService.preForm.complementacaoObj.isComplementacao === true">
        <p>Digite o número da IT a ser complementada:</p>
        <mat-form-field>
          <input
            matInput
            placeholder="Número da IT anterior"
            [(ngModel)]="satFormService.preForm.complementacaoObj.nITAnterior"
          />
        </mat-form-field>

        <div style="color: red; margin-top: 15px">
          Na versão final o solicitante irá selecionar uma IT de uma lista fornecida de IT's
          produzidas para o Órgão
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
</div>

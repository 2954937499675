import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';

@Component({
  selector: 'app-duvida',
  templateUrl: './duvida.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class DuvidaComponent implements OnInit {
  constructor(public satFormService: SatFormService) { }

  ngOnInit(): void { }

  checkTextareaContent() {
    this.satFormService.checkPreFormToNavigate()
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User, LoginService } from '@core/authentication';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel" style="margin-left: 16px;">

    <!-- <div

       style=" color:#5D899D; font-size: 13px; display: flex; align-items: center;">
    <mat-icon
              style="font-size: 21px; width: 25px; height: 21px;"
              aria-label="Info">task_alt</mat-icon>{{this.logadoIsMembro === false ? 'Servidor' : 'Membro'}} logado
  </div> -->
  <!--  -->
     <!--  <img class="matero-user-panel-avatar" [src]="this.avatarIMG" alt="avatar" width="64" onError="this.src='./assets/images/generic_user.png';" />
      <div class="matero-user-panel-name">{{ this.userName }}</div>
      <div class="matero-user-panel-orgao">{{ user.nomeOrgao }}</div>
      <div class="matero-user-panel-icons"> -->
        <!--   <button mat-icon-button (click)="avatar()">
          <mat-icon class="icon-18">account_circle</mat-icon>
        </button>
      <button mat-icon-button routerLink="/profile/settings">
          <mat-icon class="icon-18">settings</mat-icon>
        </button>
        <button mat-icon-button (click)="logout()">
          <mat-icon class="icon-18">exit_to_app</mat-icon>
        </button> -->
      </div>
  `,
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit {
  user!: User;
  avatarIMG: any
  userName = ''
  logadoIsMembro = false

  constructor(private router: Router, private auth: AuthService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.auth.user().subscribe(user => {
      this.user = user.tok
      this.userName = user.tok.name.toLowerCase();
      this.logadoIsMembro = false
      user.tok.roles.forEach((role: any) => {
        if (role === "Membro") {
          this.logadoIsMembro = true
        }
      });
      //  this.avatar()
    }).unsubscribe();



  }



  logout() {
    this.auth.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
  }
}

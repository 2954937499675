import { Component, OnInit, Inject } from '@angular/core';
import { SatDatabaseService } from '../../../shared/services/sat-database.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SatDetalhesService } from '../sat-detalhes/sat-detalhes.service';
import { LocalStorageService } from '@shared';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  spinner = false
  sat: any;
  uploadProgress: number = 0;
  totalMb = 0
  enviando = false
  allTemasArr: any = []
  constructor(
    private satDatabaseService: SatDatabaseService,
    public dialogRef: MatDialogRef<UploadFilesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private satDetalhesService: SatDetalhesService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.localStorageService.satFiles = []
    this.sat = this.satDetalhesService.getSatFormEditar()
    console.log("this.sat", this.sat)

    if (this.sat.satePssoDk === 1 || this.sat.satePssoDk === 2) {
      this.selectedServico()
    }
  }

  selectedServico() {

    let temasArr = this.sat.ListTemas[0][0].Temas
    this.allTemasArr = []
    console.log("temasArr", temasArr)
    for (const tema of temasArr) {
      let preTema: any = {
        tema: tema.tmanNmTema,
        servicos: []
      }
      for (const servico of tema.servTec) {
        let preServico: any = {
          servico: servico[0].pstcNmServTec,
          tmsDk: servico[0].tmsDk,
          docsObrigatorios: [],
          docsDesejaveis: []
        }
        servico['docObrigatorios'] = []
        servico['docDesejaveis'] = []
        for (const documento of servico[0].doc) {
          if (documento.dtstInObrigatorio === "S") {
            preServico.docsObrigatorios.push(documento)
            servico['docObrigatorios'].push(documento);
          }
          if (documento.dtstInObrigatorio === "N") {
            preServico.docsDesejaveis.push(documento)
            servico['docDesejaveis'].push(documento);
          }
        }
        preTema.servicos.push(preServico)


      }
      this.allTemasArr.push(preTema)
    }
    console.log("allTemasArr", this.allTemasArr)
  }



  onNewFileEvent(event: boolean) {
    console.log(event)
    console.log(this.localStorageService.satFiles)
    // Do something when files are added or deleted in FileDndComponent
    // For example, update other parts of your UI or perform any necessary actions
  }

  getMaxOrdem() {
    // Get the maximum value of "saaxNrOrdem"
    let anexos = this.sat.solAnaliseTecnicaAnexoList
    const maxSaaxNrOrdem = anexos.reduce((max: any, obj: any) => {
      return obj.saaxNrOrdem > max ? obj.saaxNrOrdem : max;
    }, -Infinity);

    console.log('Maximum saaxNrOrdem:', maxSaaxNrOrdem);
    console.log('anexos:', anexos);
    return maxSaaxNrOrdem
  }

  enviarArquivos() {
    console.log(this.getMaxOrdem())
    //ANEXOS
    this.spinner = true
    let anexos = this.localStorageService.satFiles
    this.enviando = true
    //  console.log(anexos)
    //let anexos:any =[]
    let anexosArrToUpload: any = []
    let hasTpaxDk5 = false
    let ordem = this.getMaxOrdem()
    console.log(ordem)
    let totalSize = 0
    for (const file of anexos) {
      totalSize += file.size;
      const parts = file.base64.split(",");
      // Get the second part (index 1)
      const base64Data = parts[1];
      let anexoObj = {

        "saaxNmArquivo": file.name,
        "saaxNmExtensaoArquivo": this.getCharactersAfterLastDot(file.name),
        "saaxNrOrdem": ordem + 1,
        "saaxArquivoAnexo": base64Data,
        "saaxSateDk": "",
        "saaxInObrigatorio": file.saaxInObrigatorio === true ? "S" : "N",
        "saaxDsObservacao": file.saaxDsObservacao,
        "saaxTpaxDk": file.tpaxDk,
        "saaxTpaxDkList": {
          "tpaxDk": file.tpaxDk,
          "tpaxNmTipoSatAnexo": ""
        },
        "saaxNmMimeType": file.type


      }
      if (file.tpaxDk === 5) {
        hasTpaxDk5 = true
      }
      anexosArrToUpload.push(anexoObj)
      ordem++
    }

    if (totalSize > 10 * 1000 * 1024) { // Convert 10MB to bytes
      // Call your function here
      // triggerAnotherFunction();
      this.totalMb = Math.round(totalSize / 1000 / 1024)
    } else {
      this.totalMb = 10
    }

    console.log("anexosArrToUpload", anexosArrToUpload)

    this.satDatabaseService.uploadAnexos(anexosArrToUpload, this.sat.sateDk).subscribe(
      (event: any) => {

        if (event.type === HttpEventType.UploadProgress) {
          // Calculate and update upload progress
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          // Handle successful response
          console.log('Arquivos enviados com sucesso:', event.body);

          setTimeout(() => {
            this.localStorageService.satFiles = [];
            this.dialogRef.close({ event: 'reload', solAnaliseTecnicaAnexoList: event.body.solAnaliseTecnicaAnexoList });
          }, 2000);
          // this.satState = { status: 'enviada', error: null, sateDk: event.body.sateDk }
        }
      },
      (error: any) => {
        console.error('Error uploading files:', error);
        this.localStorageService.satFiles = [];
        this.dialogRef.close({ event: 'reload', solAnaliseTecnicaAnexoList: null });
        this.spinner = false
        // Handle the error, display an error message, etc.
      }
    );
  }
  getCharactersAfterLastDot(inputString: any) {
    const lastDotIndex = inputString.lastIndexOf('.');
    if (lastDotIndex !== -1 && lastDotIndex < inputString.length - 1) {
      return inputString.substring(lastDotIndex + 1);
    }
    return null; // Return null if there is no dot or it's the last character.
  }

}

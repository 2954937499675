import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SatDetalhesService } from '../sat-detalhes/sat-detalhes.service';
import { SatDatabaseService } from '@shared/services/sat-database.service';
import { Router } from '@angular/router';
import { AuthService } from '@core';
import { MatDialog } from '@angular/material/dialog';
import { SatDetalhesComponent } from '../sat-detalhes/sat-detalhes.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Component({
  selector: 'app-sat-sat-lista',
  templateUrl: './sat-lista.component.html',
  styleUrls: ['./sat-lista.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SatSatListaComponent implements OnInit {
  baseURL = "prodata/"
  satsArr: any = []
  spinner = true
  orgaosSpinner = false

  public logadoIsMembro = false
  public logadoIsSercretaria = false
  public logadoIsAdmin = false
  public logadoIsServidor = false;
  public logado: any
  usrLogado: any
  matriculaUsuario: number = 0
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  userSubscription: any = Subscription;
  satAlteradaDk: any;
  orgiArr: any = []

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private http: HttpClient,
    public authService: AuthService,
    public satDatabaseService: SatDatabaseService, public satDetalhesService: SatDetalhesService, private router: Router) {

  }

  ngOnInit() {

    this.userSubscription = this.authService.user$.subscribe(user => {

      this.usrLogado = user.tok
      this.logadoIsMembro = false
      this.matriculaUsuario = user.tok.matricula



      user.tok.roles.forEach((role: any) => {
        console.log(role)
        if (role === "Membro") {
          this.logadoIsMembro = true
          this.logadoIsServidor = true;
          this.logadoIsSercretaria = false;
          this.logadoIsAdmin = false

        }
        if (role === "Administrador") {
          this.logadoIsServidor = false;
          this.logadoIsSercretaria = false;
          this.logadoIsAdmin = true;
          this.logadoIsMembro = false;
        }
        /*
        if (role === "Secretaria" && !this.logadoIsAdmin && !this.logadoIsMembro) {
          this.logadoIsServidor = false;
          this.logadoIsSercretaria = true;
          this.logadoIsAdmin = false
          this.logadoIsMembro = false;
          this.logado = role
        }
          */
        if (role === "Servidor" && !this.logadoIsAdmin && !this.logadoIsSercretaria && !this.logadoIsMembro) {
          this.logadoIsServidor = true;
          this.logadoIsSercretaria = false;
          this.logadoIsAdmin = false
          this.logadoIsMembro = false;
          this.logado = role;
        }
      });

      if (this.logadoIsMembro !== false) {
        //  this.getListSats(0)
        this.orgaosSpinner = true
        this.orgiArr = []
        this.satDatabaseService.getOrgaoAtuacao(this.usrLogado.ticket).subscribe((resp: any) => {
          console.log(resp)
          if (resp && resp !== null && resp.length > 0) {
            this.orgiArr = resp
          }
          this.orgiArr.unshift(
            {
              abrev: "Minhas SAT's",
              nome: "Minhas SAT's",
              id: 0,
              selected: true
            }
          );
          if (this.orgiArr.length > 1) {
            //  this.getListSats(this.orgiArr[1].id)
            this.getListSats(0)
          } else {
            this.getListSats(0)
          }
          this.orgaosSpinner = false
        },
          (error: any) => {
            // Handle errors here
            console.error('Error fetching orgao atuacao:', error);
            // You can also rethrow the error to continue handling it in the caller
            return throwError(error);
          }
        )

      } else {
        this.getListSats(0)
      }


    }).unsubscribe();
  }
  getEmailPrefix(email: string): string {
    return email.split('@')[0];
  }

  ngOnDestroy(): void {
    // Unsubscribe from the user$ BehaviorSubject to avoid memory leaks
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  getListSats(orgi_DK: any) {
    setTimeout(() => {
      if (orgi_DK === 0) {
        console.log("logado", this.logado)
        if (this.logadoIsMembro === true) {
          this.getSatsByMembro()
        }
        if(this.logadoIsAdmin){
          this.getAllSats()
        }
        if (this.logadoIsServidor && !this.logadoIsAdmin && !this.logadoIsSercretaria && !this.logadoIsMembro) {

          this.getSatsByServidor()
        }

      } else {
        if (this.logadoIsMembro === true) {
          this.getSatsByOrgi(orgi_DK)


        }
      }
      this.orgiArr.forEach((orgi: any) => {
        if (orgi_DK === orgi.id) {
          orgi.selected = true
        } else {
          orgi.selected = false
        }
      });

    }, 200);
  }



  getSatsByServidor() {
    this.spinner = true
    this.satDatabaseService.listAllSatsByServidorMat(this.matriculaUsuario).subscribe(json => {
      this.satsArr = json.sort((a: any, b: any) => b.sateDk - a.sateDk);;
      this.spinner = false
      console.log("listAllSatsByServidorMat", this.satsArr)
    });
  }
  getSatsByMembro() {
    this.spinner = true
    this.satDatabaseService.listAllSatsByMembroMat(this.matriculaUsuario).subscribe(json => {
      this.satsArr = json.sort((a: any, b: any) => b.sateDk - a.sateDk);;
      this.spinner = false
      this.satAlteradaDk = null
      console.log("listAllSatsByMembroMat", this.satsArr)
    });
  }

  getSatsByOrgi(orgi_DK: any) {
    this.spinner = true
    this.satDatabaseService.listAllSatsByOrgi(orgi_DK).subscribe(json => {
      this.satsArr = json.sort((a: any, b: any) => b.sateDk - a.sateDk);;
      this.spinner = false
      this.satAlteradaDk = null
      console.log("listAllSatsByOrgi", this.satsArr)
    })
  }
  getAllSats() {
    this.spinner = true
    this.satDatabaseService.listAllSats().subscribe(json => {
      this.satsArr = json.sort((a: any, b: any) => b.sateDk - a.sateDk);;
      this.spinner = false
      this.satAlteradaDk = null
      console.log("listAllSatsByOrgi", this.satsArr)
    })
  }


  abrePDF(arquivo: any) {
    var byteCharacters = atob(arquivo);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  openDialog(selectedSat: any) {
    if (this.logadoIsMembro === true) {
      selectedSat.logadoIsSolicitante = true
    } else {
      selectedSat.logadoIsSolicitante = false
    }
    this.satDetalhesService.setSelectedSat(selectedSat);
    const dialogRef = this.dialog.open(SatDetalhesComponent, {
      autoFocus: false,
      panelClass: 'custom-dialog-class',
      maxHeight: '90vh',//you can adjust the value as per your view
      data: { sat: selectedSat },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.event === 'reload') {
        console.log('resulta sat aprovada', result)
        this.satAlteradaDk = selectedSat.sateDk
        this.openSnackBar(selectedSat.sateDk, 'SAT enviada para o GATE com sucesso.')
        // this.getListSats()

        this.replaceObjectInArray(this.satsArr, result.novaSAT);
        setTimeout(() => {
          this.satAlteradaDk = null
        }, 1300);

      }
      if (result !== undefined && result.event === 'delete') {
        this.satAlteradaDk = selectedSat.sateDk
        this.openSnackBar(selectedSat.sateDk, 'SAT excluída com sucesso.')
        // this.getListSats()
        this.removeObjectFromArray(this.satsArr, result.sateDk);
        setTimeout(() => {
          this.satAlteradaDk = null
        }, 1300);

      }

    });
  }

  replaceObjectInArray(arr: any[], newObj: any): void {
    const index = arr.findIndex(item => item.sateDk === newObj.sateDk);
    if (index !== -1) {
      arr[index] = newObj;
    }
  }
  removeObjectFromArray(arr: any[], sateDk: number): void {
    const index = arr.findIndex(item => item.sateDk === sateDk);
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }


  openSnackBar(sateDk: any, msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
    });
  }




  listAllSats() {
    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnica/ListaSolAnaliseTecnica', { responseType: 'json' });
  }



  listSatByMatriculaSolic() {

    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnica/ListSatByMatriculaSolic?matricula=' + { responseType: 'json' });
  }


}



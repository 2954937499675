import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
import { LocalStorageService } from '@shared';
@Component({
  selector: 'app-resumo',
  templateUrl: './resumo.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class ResumoComponent implements OnInit {

  constructor(public satFormService: SatFormService, public localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.satFormService.checkPreFormToNavigate();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  abreBase64(arquivo: any, extension: string) {

    let contentType: string;

    switch (extension.toLowerCase()) {
      case 'pdf':
        contentType = 'application/pdf';
        break;
      case 'png':
        contentType = 'image/png';
        break;
      case 'jpg':
      case 'jpeg':
        contentType = 'image/jpeg';
        break;
      case 'gif':
        contentType = 'image/gif';
        break;
      case 'zip':
        contentType = 'application/zip';
        break;
      case 'doc':
        contentType = 'application/msword';
        break;
      case 'docx':
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'xls':
        contentType = 'application/vnd.ms-excel';
        break;
      case 'xlsx':
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      default:
        console.error(`Unsupported file extension: ${extension}`);
        return;
    }

    // Now you can use the contentType

    if (contentType) {
      // Now you can use the contentType

      // Add your logic to open the file here
    } else {
      console.error(`Unsupported file extension: ${extension}`);
    }

    var byteCharacters = atob(arquivo);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: extension + ';base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

}

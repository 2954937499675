<nav aria-label="breadcrumb">
  <ol class="matero-breadcrumb">
    <li class="matero-breadcrumb-item"
        *ngFor="let navLink of nav; trackBy: trackByNavlink; first as isFirst;">
      <a href="#" class="link" *ngIf="isFirst">{{navLink}}</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron">chevron_right</mat-icon>
        <span class="link">{{navLink }}</span>
      </ng-container>
    </li>
  </ol>
</nav>
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@shared';
import { ToastrModule, ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class SatValidarService {
  textToValidate = ''
  invalidStepArr: any = []
  constructor(private localStorageService: LocalStorageService, private toastr: ToastrService) {

  }

  checkPreFormToNavigate(stepToLoad: any, preForm: any, isEdicao: any) {
    this.textToValidate = ''
    this.invalidStepArr = []
    let isValid = false
    let isIntegra = false
    let checkAll = false
    if (isEdicao === true || stepToLoad === 'resumo') {
      checkAll = true
    }
    if (preForm.procedimentoObj !== null && preForm.procedimentoObj.sate_PSSO_DK !== undefined) {
      switch (preForm.procedimentoObj.sate_PSSO_DK) {
        case 3:
        case 4:
        case 5:
          isIntegra = true;
          break;
        case 1:
        case 2:
          isIntegra = false;
          break;
        default:
          // Handle other cases if needed
          break;
      }
    }

    if (stepToLoad === 'resumo' || stepToLoad === 'solicitante' || checkAll === true) {
      console.log(preForm.solicitanteObj)
      if (preForm.solicitanteObj !== null) {
        isValid = true
      }
    }

    if (stepToLoad === 'resumo' || checkAll === true || stepToLoad === 'procedimento') {
      //  navegacao.continuarDisabled = true
      let procedAndMunicChecked = false
      if (
        preForm.procedimentoObj !== null &&
        preForm.municipioObj !== null
      ) {
        procedAndMunicChecked = true
      }
      let itComplChecked = false
      if (preForm.complementacaoObj.isComplementacao === "N") {
        itComplChecked = true
      } else {
        if (preForm.complementacaoObj.docTecAnteriorObj !== null) {
          itComplChecked = true
        }
      }
      if (procedAndMunicChecked === true && itComplChecked === true) {
        isValid = true

      } else {
        isValid = false

      }

      if (isValid === false) {
        if (preForm.procedimentoObj === null) {
          this.textToValidate = ''
          this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'procedimento');
        } else if (preForm.municipioObj === null) {
          this.textToValidate = 'Selecione o(s) município(s)'
          this.invalidStepArr.push('procedimento')
          return false
        } else if (preForm.complementacaoObj.isComplementacao === "S" && preForm.complementacaoObj.docTecAnteriorObj === null) {
          this.textToValidate = 'Selecione IT a ser complementada'
          this.invalidStepArr.push('procedimento')
          return false
        }
      } else {
        this.textToValidate = ''
      }

      //  this.toastr.error('Hello world!', 'Toastr fun!', {positionClass:'toast-bottom-right'});

    }

    if (stepToLoad === 'resumo' || checkAll === true || stepToLoad === 'temas-servicos') {
      console.log(preForm)
      if (preForm.areasArr.length > 0) {
        console.log(preForm.areasArr.length)
        this.textToValidate = ''
        this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'temas-servicos');
        isValid = true

      } else {
        console.log(preForm.areasArr.length)
        this.textToValidate = 'Selecione ao menos um serviço técnico'
        this.invalidStepArr.push('temas-servicos')
        return false
      }
    }

    if (((stepToLoad === 'resumo' || checkAll === true) && isIntegra === false) || stepToLoad === 'documentos-upload') {
      this.textToValidate = ''
      isValid = true
      this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'documentos-upload');
      let hasTpaxDk3 = false
      for (const file of this.localStorageService.satFiles) {
        if (file.tpaxDk === 3 && file.saaxInObrigatorio === true) {
          hasTpaxDk3 = true

        }
      }

      for (const file of preForm.satFilesInEdicao) {

        if (file.saaxTpaxDk === 3 && file.saaxInObrigatorio === "S") {
          hasTpaxDk3 = true
          console.log("file", file)
        }
      }
      console.log("hasTpaxDk3", hasTpaxDk3)
      if (hasTpaxDk3 === false) {
        this.textToValidate = 'Selecione arquivos para documentos obrigatórios'
        this.invalidStepArr.push('documentos-upload')
        return false
      }


    }

    if (((stepToLoad === 'resumo' || checkAll === true) && isIntegra !== false) || stepToLoad === 'documentos-integra') {
      this.textToValidate = ''
      isValid = true
      this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'documentos-integra');
      if (preForm.areasArr.length > 0) {
        for (const area of preForm.areasArr) {
          for (const tema of area.temas) {
            for (const servico of tema.servicos) {
              for (const docObg of servico.docsObrigatorios) {
                for (const idIntg of docObg.idsArr) {
                  let x = idIntg.split(" ").join("");

                  if (idIntg === null || idIntg === '') {
                    this.textToValidate = 'Os documentos obrigatórios devem conter ao menos um ID referente a documentos no Integra'
                    this.invalidStepArr.push('documentos-integra')
                    return false
                  }
                  if (x === "" || x === null) {
                    this.textToValidate = 'Os IDs do Integra devem conter números e caracteres válidos'
                    this.invalidStepArr.push('documentos-integra')
                    return false;
                  }
                }
              }
            }
          }
        }
      } else {
        this.invalidStepArr.push('temas-servicos')
        this.textToValidate = 'Selecione ao menos um serviço técnico'
        return false
      }

    }


    if (stepToLoad === 'resumo' || checkAll === true || stepToLoad === 'complementaridade') {
      this.textToValidate = ''
      isValid = true
      this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'complementaridade');


      if (preForm.excepcionalidadeObj.inManifTecAnterior === 'S') {
        let hasTpaxDk2 = false
        for (const file of this.localStorageService.satFiles) {
          if (file.tpaxDk === 2) {
            hasTpaxDk2 = true
            this.localStorageService.satFiles = this.localStorageService.satFiles.filter((obj: any) => obj.tpaxDk !== 5);
          }
        }

        for (const file of preForm.satFilesInEdicao) {
          if (file.saaxTpaxDk === 2) {
            hasTpaxDk2 = true
          }
        }

        if (hasTpaxDk2 === false) {
          this.textToValidate = 'Selecione arquivo(s) de manifestação técnica ou laudo pericial a ser complementado '
          this.invalidStepArr.push('complementaridade')
          return false
        }
      } else { //inManifTecAnterior === 'N'


        let hasTpaxDk5 = false
        for (const file of this.localStorageService.satFiles) {
          if (file.tpaxDk === 5) {
            hasTpaxDk5 = true
            this.localStorageService.satFiles = this.localStorageService.satFiles.filter((obj: any) => obj.tpaxDk !== 2);
          }
        }

        for (const file of preForm.satFilesInEdicao) {
          if (file.saaxTpaxDk === 5) {
            hasTpaxDk5 = true
          }
        }

        let hasDsJustifAusenciaCompl = false
        let dsJustifAusenciaComplLength = 0


        preForm.excepcionalidadeObj.dsJustifAusenciaCompl === null ? null : dsJustifAusenciaComplLength = preForm.excepcionalidadeObj.dsJustifAusenciaCompl.length

        if (dsJustifAusenciaComplLength > 0) {
          hasDsJustifAusenciaCompl = true
        }

        if (hasTpaxDk5 === false && hasDsJustifAusenciaCompl === false) {
          this.invalidStepArr.push('complementaridade')
          this.textToValidate = 'Selecione arquivo(s) para upload e/ou informe a excepcionalidade '
          return false
        }

        if (hasTpaxDk5 === true || hasDsJustifAusenciaCompl === true) {
          this.textToValidate = ''
        }

      }







    }


    if (stepToLoad === 'resumo' || checkAll === true || stepToLoad === 'prioridade') {
      this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'prioridade');
      if (preForm.atendPrioritarioObj.isPrioritario === 'S') {

        if (preForm.atendPrioritarioObj.tipoPrazo === null) {
          this.invalidStepArr.push('prioridade')
          this.textToValidate = 'Selecione o tipo de prazo aplicável'
          return false
        }


        if (preForm.atendPrioritarioObj.tipoPrazo !== null) {
          if (preForm.atendPrioritarioObj.tipoPrazo.tpplInJustificativa === 'S') {
            if (preForm.atendPrioritarioObj.exceptPrioridade === null || preForm.atendPrioritarioObj.exceptPrioridade.length < 10) {
              this.textToValidate = 'Informe a situação excepcional'
              this.invalidStepArr.push('prioridade')
              return false
            } else {
              this.textToValidate = ''
            }

          }

          let hasFile = false
          if (preForm.atendPrioritarioObj.tipoPrazo.tpplInJustificativa === 'N') {
            for (const file of this.localStorageService.satFiles) {
              if (file.tpaxDk === 1) {
                hasFile = true
              }
            }

            for (const file of preForm.satFilesInEdicao) {
              if (file.saaxTpaxDk === 1) {
                hasFile = true
              }
            }
            if (hasFile === true) {
              this.textToValidate = ''
            } else {
              this.invalidStepArr.push('prioridade')
              this.textToValidate = 'Anexe documento comprobatório'
              return false
            }

          }

          if (preForm.atendPrioritarioObj.dtPrazoPrior === null) {
            this.invalidStepArr.push('prioridade')
            this.textToValidate = 'Informe a data limite pretendida para atendimento'
            return false
          } else {
            this.textToValidate = ''

          }

        }





      }

      isValid = true
    }


    if (stepToLoad === 'resumo' || checkAll === true || stepToLoad === 'duvida') {

      if (preForm.objetivoSolicitacaoStr.length > 15) {
        this.invalidStepArr = this.invalidStepArr.filter((step: any) => step !== 'duvida');
        isValid = true
      } else {
        this.textToValidate = 'Informe o objetivo da solicitação'
        this.invalidStepArr.push('duvida')
        return false
      }
    }

    if (stepToLoad === 'resumo') {
      isValid = true
    }

    if (stepToLoad === 'resumo' || stepToLoad === 'enviarSat') {
      // isValid = true
    }

    return isValid

  }
}

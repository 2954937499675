import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sat-sat-instrucoes',
  templateUrl: './sat-instrucoes.component.html',
  styleUrls: ['./sat-instrucoes.component.scss']
})
export class SatSatInstrucoesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

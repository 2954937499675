import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  dir = 'assets/images/pixabay/';
  images: any[] = [];
  stats = [
    {
      title: 'Aguardando atendimento',
      amount: '56',
      progress: {
        value: 50,
      },
      color: 'bg-blue-400' ,
    },
    {
      title: 'Total de SATs',
      amount: '122',
      progress: {
        value: 100,
      },
      color: 'bg-teal-500',
    },
  ];
  constructor(private cdr: ChangeDetectorRef) {
    for (let i = 1; i <= 20; i++) {
      this.images.push({
        title: i,
        src: this.dir + i + '.jpg',
      });
    }
  }

  ngOnInit() {}
}

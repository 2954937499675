<div *ngIf="data" style="max-width: 720px; padding: 24px; overflow-y: auto;">
  <div style="font-size: 22px; font-weight: 600; margin-bottom: 32px;">
    {{data.servico.pstcNmServTec}}</div>

  <!-- <div style="font-size: 16px; margin-bottom: 24px;">
    <strong>Descrição: </strong> {{data.servico.pstcDsServTec}}
  </div> -->

  <div style="
   margin-top: 24px;">
    <div style="font-size: 18px; font-weight: 500; margin-bottom: 10px;">Documentos Obrigatórios
    </div>
    <mat-list role="list">

      <mat-list-item
                     *ngFor="let doc of data.docsObrigatorios" style="margin-bottom: 23px;">
        <span style="min-width: 40px;"> <mat-icon class="material-symbols-outlined"
                    style="font-size: 24px; color:#5D899D; position: absolute; top: 0px;
                      "
                    mat-list-icon>draft</mat-icon></span>

        <span style="font-size: 15px;   padding-top: 6px;"> {{
          doc.dostNmDocumento }}</span>

      </mat-list-item>
    </mat-list>
  </div>
  <div style="clear: both;"></div>

  <div *ngIf="data.docsDesejaveis.length > 0" style="
   margin-top: 24px;">
    <div style="font-size: 18px; font-weight: 500; margin-bottom: 10px;">Documentos Desejáveis
    </div>
    <mat-list role="list">
      <mat-list-item *ngFor="let doc of data.docsDesejaveis" style="margin-bottom: 23px;">
        <span style="min-width: 40px;"> <mat-icon class="material-symbols-outlined"
                    style="font-size: 24px; color:#5D899D; position: absolute; top: 0px;
                        "
                    mat-list-icon>draft</mat-icon></span>
        <span style="font-size: 15px;padding-top: 6px;">{{ doc.dostNmDocumento }}</span>
      </mat-list-item>
    </mat-list>
  </div>




  <div align="end">
    <button mat-button mat-flat-button color="accent" (click)="onNoClick()">Fechar</button>
  </div>
</div>
import { Component, OnInit, ViewChild, PipeTransform, Pipe, } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';



@Component({
  selector: 'criterios-FAQ',
  templateUrl: './criterios-FAQ.html',
  styleUrls: ['./criterios-FAQ.scss']
})


export class CriteriosFAQComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: any = MatAccordion;

  faqArr = [
    { pergunta: 'Como funciona o novo sistema de prioridades?', resposta: 'A fila de atendimento é organizada de acordo com a nota de prioridade de cada Solicitação de Análise Técnica (SAT), ordenada da maior para a menor nota. A nota de prioridade é um índice que avalia, para cada caso e tema, fatores e pesos associados ao custo de análise, à urgência e à gravidade da situação.' },
    { pergunta: 'Mas o GATE já não tem critérios de prioridade? Por que mudar?', resposta: 'Anteriormente, o GATE atendia solicitações em ordem cronológica, priorizando casos urgentes e ações judiciais. Havia nisso um claro incentivo à judicialização e uma atuação ineficiente: casos mais graves e com custo menor de análise eram preteridos por análises mais custosas e menos relevantes, apenas porque foram formuladas em primeiro lugar. Somado à falta de controle do passivo e a um cenário de demanda superior à produção, a tendência era cada vez maior que o sistema de prioridades existentes levasse à inutilidade de análises relativas a casos de alto impacto. Ou seja, os critérios antigos não contribuíam para a resolutividade eficiente do MP.' },
    { pergunta: 'O critério cronológico não é mais justo, porque trata todas as promotorias com igualdade? ', resposta: 'Não. O critério cronológico, que organiza as solicitações por ordem de chegada, atrasa a resposta do GATE igualmente em todos os procedimentos, independentemente da importância de cada caso. Isso prejudica a atuação do GATE, especialmente com o acúmulo de solicitações antigas. Por exemplo, uma solicitação sobre as condições de atendimento em um hospital com superlotação e mortes recentes, mesmo que enviada agora, poderia ser atrasada em até 200 dias devido a procedimentos mais antigos. Quando finalmente analisada, a situação pode ter mudado, diminuindo o valor do trabalho do GATE e da Promotoria de Justiça. O objeto do tratamento isonômico não deve ser a promotoria, mas sim as circunstâncias de cada caso.' },
    { pergunta: 'Haverá nota de corte no novo sistema? Por quê?', resposta: 'Sim. A demanda pelos serviços do GATE historicamente vem superando a capacidade produtiva, mesmo com aumento na produtividade ou no quadro de funcionários. Atualmente, o tempo de resposta em determinados temas pode chegar a 800 a 900 dias, o que é inaceitável e prejudica todas as investigações. Para isso no sistema novo haverá um passivo máximo definido para cada tema, levando em conta o tempo máximo tolerável para espera. Se a fila do tema superar a demora máxima aceitável, os casos menos prioritários não serão admitidos.' },
    { pergunta: 'Isso não vai fazer com que promotorias sejam atendidas em proporção diferente? É justo?', resposta: 'As promotorias já recebem atendimento em proporções diferentes. Em 2023, aproximadamente 70% das promotorias receberam até 10 Informações Técnicas (ITs), enquanto apenas 30% receberam mais de 10 ITs. Além disso, 25% das ITs produzidas foram destinadas às 10 promotorias mais atendidas. Portanto, o critério cronológico nunca proporcionou um atendimento equitativo. Os critérios de prioridade combinados com o Protocolo 4 tendem a equilibrar melhor a proporção de atendimento entre as promotorias. A lógica é olhar para cada caso, independente da promotoria, e classificar as prioridades em funções das circunstâncias de cada caso. A promotoria que demandar análises menos custosas em casos de maior impacto será mais atendida.' },
    { pergunta: 'Não é o promotor que tem independência para definir as prioridades da sua promotoria?', resposta: 'Sim. O GATE não está apontando para tema ou método de atuação de forma vinculativa às promotorias. Está apenas gerenciando o seu recurso de trabalho levando em conta o dever constitucional de alocação eficiente - que necessariamente precisa olhar para custo e valor. Admitir que a promotoria pudesse definir os critérios de prioridade de forma vinculativa ao GATE criaria um conflito insuperável entre as promotorias - já que todas tenderiam a crer que os seus procedimentos são mais prioritários.' },
    { pergunta: 'Usando esses critérios, as promotorias da capital/região metropolitana não seriam sempre privilegiadas, na medida em que geralmente possuem casos mais relevantes?)', resposta: 'Não necessariamente. Os critérios de priorização foram desenvolvidos para avaliar a importância relativa de cada SAT. No caso de análises de economicidade, existem indicadores que buscam corrigir um possível viés relacionado ao valor do contrato fiscalizado. Por isso, os critérios consideram o valor do contrato de forma relativa ao orçamento do ente contratante. E, além disso, esse fator dem um peso na nota final de prioridade menor do que o IDH do ente (um IDH menor indica prioridade maior). A combinação de fatores desta maneira tende a minimizar o viés.' },
    { pergunta: 'Mas os critérios são objetivos? Se não forem, isso não os faz incontroláveis?', resposta: 'No caso de análises de economicidade, todos os critérios são objetivos e quantificáveis. Por exemplo, o valor do contrato referente a uma SAT de superfaturamento. Há outros fatores que são qualitativos, como a gravidade da análise. Para esses, há uma avaliação e tradução em escala numérica pelo grupo de técnicos especialistas no tema. A partir daí, os fatores são ponderados, de acordo com pesos quantitativos e pré-estabelecidos de forma geral, para todo e qualquer tema, pela Coordenação. O resultado é a nota de prioridade que, dessa forma, pode ser auditada, apesar de parcialmente composta de fatores qualitativos.' },
    { pergunta: 'E se uma SAT entrar na fila na frente de outra, porque foi considerada mais prioritária? Ela vai passar a frente, que está aguardando mais tempo?', resposta: 'Sim. Nesses casos, as SATs que chegarem antes e tiverem prioridade serão atendidas em primeiro lugar. É o que já acontecia com o sistema antigo, quanto às SAT urgentes e relativas a processos judiciais com prazo. Desde, é claro, que atendidas as regras do Protocolo 5.' },
    { pergunta: 'Se isso correr, não vai fazer com que as SAT próximas à nota de corte fiquem eternamente no GATE, sem atendimento?', resposta: 'Não, por conta das regras combinadas dos Protocolos 4 e 5.' },
    { pergunta: 'E se eu discordar dos critérios de prioridade?', resposta: 'O GATE valoriza imensamente as críticas e sugestões para a melhoria de todos os seus processos e regras de trabalho. Reconhecemos que há sempre espaço para evolução e acreditamos que as críticas construtivas são o meio fundamental para alcançarmos melhorias contínuas. Qualquer crítica construtiva e proposta de melhoria é bem-vinda.' },
    { pergunta: 'Então vou usar os critérios de prioridade e a nota de corte para indeferir notícias de fato e arquivar inquéritos civis. O GATE vai me apoiar?', resposta: 'É uma ideia que, para o GATE, faz todo sentido. Com poucos ajustes, acredita-se que isso seja possível (e até recomendável, na mera opinião da Coordenação do GATE). Claro, o GATE não pode falar pelas instâncias revisoras de decisões de arquivamento e indeferimento.' },
    { pergunta: 'Os critérios vão mudar? Se mudar, isso vai mudar a posição na fila?', resposta: 'Novas versões dos critérios podem surgir sempre que os resultados práticos não estiverem alinhados com o que foi projetado. As críticas construtivas são uma maneira valiosa de aprimorá-los. Nesse processo de constante evolução, é natural que haja alterações na ordem dos procedimentos. Pos isso, a contribuição de todos é fundamental para esse processo de melhoria contínua.' },
    { pergunta: 'E as SAT que já estavam no GATE além da nota de corte, antes da entrada e vigor do critério de prioridade? Serão devolvidas?', resposta: 'Não. Serão atendidas de acordo com as regras do Protocolo 5 para as SAT já admitidas e que ultrapassem o período máximo tolerável de demora.' },
    { pergunta: 'Os critérios de prioridade vão tornar o Protocolo 4 desnecessário?', resposta: 'Não. Faz parte da visão e valores do GATE não admitir análises quando não puder entregá-las prontas no prazo máximo tolerável e útil para uma atuação efetiva das promotorias de justiça.' },
    { pergunta: 'Posso alterar a ordem de prioridade apenas das SAT da minha promotoria?', resposta: 'Por enquanto, não, por conta de limitações operacionais. A mudança na posição da fila pode interferir com a prioridade de todos as SAT de outros solicitantes que estejam entre as posições das SAT cuja posição se pretenderia alterar.' },
    { pergunta: 'Vou ter que preencher a SAT com alguma informação específica para a aplicação dos critérios de prioridade?', resposta: 'Por enquanto, não. A médio prazo, a SAT terá campos de preenchimento que automatizarão o processo.' },
    { pergunta: 'Como posso saber, antes de enviar uma SAT, qual será a prioridade?', resposta: 'Pretendemos, em versões futuras do sistema, automatizar a classificação de prioridades. Com isso, a mesma ferramenta poderá realizar simulações. Por ora, não será possível.' },
    { pergunta: 'Se as minhas SAT forem sempre classificadas como mais prioritárias, isso significa que eu vou ser mais atendido, de forma mais rápida, que as demais promotorias?', resposta: 'Sim. O importante são as circunstâncias de cada SAT que chega ao GATE, e não qual a promotoria que a formula. Salvo quanto às SAT já admitidas e antigas no GATE. Todas essas serão atendidas de acordo com as regras do Protocolo 5 e as demais regras de atendimento do GATE. ' },
  ]

  filteredFAQArr = this.faqArr;
  searchTerm: string = '';


  constructor() {

  }

  ngOnInit(): void {
  }

  filterFAQ(): void {
    if (this.searchTerm.trim() !== '') {
      const searchTermLC = this.searchTerm.toLowerCase().trim();
      this.filteredFAQArr = this.faqArr.filter(item =>
        item.pergunta.toLowerCase().includes(searchTermLC) ||
        item.resposta.toLowerCase().includes(searchTermLC)
      );
    } else {
      this.filteredFAQArr = this.faqArr;
    }
  }

  clearSearch(): void {
    this.searchTerm = '';
    this.filterFAQ();
  }

}

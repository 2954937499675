<div class="page-container">
  <div class="matero-page-header-inner">
    <h1 class="matero-page-title">Catálogo de serviços</h1>

  </div>

  <p>Catálogo de serviços técnicos oferecidos pelo GATE
  </p>


  <div style="min-height: 165px; display: block; max-width:70%;">

    <div class="buscar" *ngIf="spinner === false">
      <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
        <mat-icon matPrefix>search</mat-icon>
        <input type="text"
               placeholder="Busque pelo serviço"
               matInput
               [(ngModel)]="searchGlobal"
               (keyup)="filterServicosGlobal($event)" />
        <button color="accent"
                *ngIf="searchGlobal"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchGlobal = '' ; filterServicosGlobal($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngIf="spinner === true || panelSpinner === true">
      <div style="margin-bottom: 15px;"><strong>Carregando lista de serviços técnicos</strong></div>
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>

    <div *ngIf="spinner === false  && panelSpinner === false">
      <!-- <div class="example-action-buttons">
        <button mat-button (click)="openAll()">Expand All</button>
        <button mat-button (click)="accordion.closeAll()">Collapse All</button>
      </div>
 -->
      <div>
        <mat-accordion [multi]="multiAreas" class=" mat-elevation-z0 catalogo-expansions">
          <mat-expansion-panel class="expand-area mat-elevation-z0"
                               *ngFor="let area of filteredAreas">
            <mat-expansion-panel-header>
              <mat-panel-title class="expand-area-title">
                <mat-icon *ngIf="area.selected" matPrefix>check</mat-icon>
                {{ area.area | lowercaseAndTitlecase }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-accordion class="  mat-elevation-z0" multi>
              <mat-expansion-panel multi="true" class="expand-tema mat-elevation-z0"
                                   *ngFor="let tema of area.temas">
                <mat-expansion-panel-header class="temas">
                  <mat-panel-title>
                    <mat-list role="list">
                      <mat-list-item>

                        <mat-icon class="material-icons-outlined"
                                  style="font-size: 20px; color:#5D899D;    margin-right: 5px;
                              "
                                  mat-list-icon>folder</mat-icon>

                        <span class="tema"> {{tema.tema | lowercaseAndTitlecase }}</span>
                      </mat-list-item></mat-list>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div>



                  <div style="max-height: 350px; overflow: auto; overflow-x: hidden">
                    <mat-list role="list" dense>
                      <mat-list-item (click)="openDialog(servico)"
                                     *ngFor="let servico of tema.servicos"
                                     class="lista " style="cursor: pointer;">
                        <span style="min-width: 40px;"> <mat-icon class="material-symbols-outlined"
                                    style="font-size: 17px; color:#AD8847; position: absolute; top: 0px;
                                "
                                    mat-list-icon>content_paste</mat-icon></span>


                        <span class="servico"> {{
                          servico.pstcNmServTec
                          }}</span>
                        <!--  </mat-checkbox> -->
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { AdminDatabaseService } from '../services/admin-database.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-crud-update-documento',
  templateUrl: './crud-update-documento.component.html',
  styleUrls: ['./crud-update-documento.component.scss']
})
export class CrudUpdateDocumentoComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: any = MatAccordion;
  @ViewChildren(MatExpansionPanel) expansionPanel: any = QueryList<MatExpansionPanel>;
  @ViewChildren(MatExpansionPanel) panels: any = QueryList<MatExpansionPanel>;
  editingDocumento: any
  nomeDuplicado = false
  spinner = true;
  reloadArr: boolean = false;
  mustReloadParent: boolean = false
  constructor(
    public dialogRef: MatDialogRef<CrudUpdateDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminDatabaseService: AdminDatabaseService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.editingDocumento = { ...this.data }
    console.log('Received data:', this.editingDocumento);
    this.compareObjectsByName()
    // this.getAreasTemas();
    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog(); // Send a custom result on backdrop click
    });
  }
  ngOnDestroy(): void {
    console.log('Component is being destroyed, clearing arrays and resetting state.');

  }



  // Close the dialog and return data to the parent component
  closeDialog(): void {
    // You can pass the updated data here. Assuming `this.data` is updated
    this.dialogRef.close(this.mustReloadParent);
  }

  // Cancel and close without returning data
  cancelDialog(): void {
    this.dialogRef.close(this.mustReloadParent);
  }
  openSnackBar(msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }
  compareObjectsByName() {

    setTimeout(() => {
      const normalizeString = (str: string): string => {
        return str
          .trim()                          // Remove leading/trailing spaces
          .replace(/\s+/g, ' ');            // Replace multiple spaces with a single space
      };

      // Normalize and compare dostNmDocumento (name)
      const nameFromData = normalizeString(this.data.dostNmDocumento);
      const nameFromEditing = normalizeString(this.editingDocumento.dostNmDocumento);

      // Normalize and compare dostDsDocumento (description)
      const descriptionFromData = normalizeString(this.data.dostDsDocumento);
      const descriptionFromEditing = normalizeString(this.editingDocumento.dostDsDocumento);

      // Check if either the name or the description is different
      if (nameFromData !== nameFromEditing || descriptionFromData !== descriptionFromEditing) {
        this.nomeDuplicado = false; // Names or descriptions differ
      } else {
        this.nomeDuplicado = true;  // Both are the same
      }

      console.log('Nome duplicado:', this.nomeDuplicado);
    }, 300);

  }




  saveEditDocumento(action: any) {
    this.editingDocumento.TMST_DK = 0
    this.editingDocumento.PSTC_DK = 0
    this.adminDatabaseService.CruDocServTec(this.editingDocumento, action).subscribe(
      (event: any) => {
        console.log('Servico gravado:', event);
        if (action === 'UPDATE') {
          this.editingDocumento = event
          this.openSnackBar('Serviço editado com sucesso.')
          this.mustReloadParent = true
          this.cancelDialog()
        }
        if (action === 'DELETE') {
          this.mustReloadParent = true
          this.cancelDialog()
          this.openSnackBar('Serviço excluído com sucesso.')
        }
      },
      (error) => {
        this.dialogRef.close('error');
        console.error('Error creating Servico:', error);
        this.openSnackBar('Erro.' + error.error.errTxt)
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );

  }


}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private toast: ToastrService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('access_token');
    console.log("ROTA.. default",this.router.url)
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    // Define the maximum number of retry attempts
    const maxRetries = 0;
    let retries = 0;

    return next.handle(req).pipe(

      // Retry the request if it times out
      retryWhen(errors => errors.pipe(
        switchMap(error => {

          if (error.status === 504 && retries < maxRetries) {
            retries++;
            // Delay before retrying the request
            return timer(10000); // Adjust the delay as needed
          }

          if (error.status === 401) {
            if (!req.url.includes('/auth/login')) {
              this.toast.error('Realize seu login novamente');
              this.router.navigateByUrl('/auth/login');
            }
            return throwError(error);
          }
          

          return throwError(error);
        })
      )),

      // Handle other errors
      catchError(error => {
        if (error.status === 504) {
          this.toast.error('Request timed out');
        } else if (error.status !== 401) {
          // this.toast.error('Ocorreu um erro');
        }
        return throwError(error);
      })
    );
  }
}

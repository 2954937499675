import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowercaseAndTitlecase'
})
export class LowercaseAndTitlecasePipe implements PipeTransform {
  transform(value: string): string {
    // List of words that should remain lowercase
    const lowercaseWords = [
      'e',
      'de',
      'do',
      'da',
      'dos',
      'das',
      'no',
      'na',
      'nos',
      'nas',
      'à',
      'em',
      'f.'
    ];

    // Words inside parentheses to be converted to uppercase
    const uppercaseWordsInParentheses = [
      'oca',
      'opd',
      'oci',
      'oi',
      'opi'
    ];

    // Split the string into words
    const words = value.split(' ');

    // Capitalize the first letter of each word except for the specified lowercase words
    const result = words.map(word => {
      // Check if the word contains parentheses
      if (word.includes('(')) {
        const [beforeParentheses, afterParentheses] = word.split('(');
        // Capitalize the first letter of the word before the parentheses
        const capitalizedBeforeParentheses = beforeParentheses.charAt(0).toUpperCase() + beforeParentheses.slice(1).toLowerCase();
        // Capitalize the first letter of the word after the parentheses
        let capitalizedAfterParentheses = afterParentheses.charAt(0).toUpperCase() + afterParentheses.slice(1).toLowerCase();
        if (uppercaseWordsInParentheses.includes(capitalizedAfterParentheses.toLowerCase())) {
          capitalizedAfterParentheses = capitalizedAfterParentheses.toUpperCase();
        }
        return capitalizedBeforeParentheses + '(' + capitalizedAfterParentheses;
      } else if (lowercaseWords.includes(word.toLowerCase())) {
        // Check if the word is in the lowercase list
        return word.toLowerCase();
      } else {
        // Capitalize the first letter of the word
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });

    // Join the words back into a string
    return result.join(' ');
  }
}

import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { MenuService } from '@core';
import { AuthService } from '@core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent implements OnInit {
  // Note: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  menu$ = this.menu.getAll();
  counterN: any
  logadoIsMembro = false
  logadoIsAdmin = false
  logadoIsSercretaria = false
  logado = ""
  buildRoute = this.menu.buildRoute;

  constructor(private menu: MenuService, public authService: AuthService, private router: Router) {

  }

  ngOnInit(): void {
    this.authService.user().subscribe(user => {
      // console.log("useruseruseruseruser", user.tok)
      //this.logadoIsMembro = false
      user.tok.roles.forEach((role: any) => {
        console.log("role", role)
        //debugger
        if (role === "Membro" && !this.logadoIsAdmin ) {
          this.logado = ""
          this.logadoIsMembro = true
          this.logado = role
        }
        if (role === "Administrador") {
          this.logado = ""
          this.logado = role
          this.logadoIsAdmin = true

        }
        if (role === "Secretaria" && !this.logadoIsAdmin && !this.logadoIsMembro) {
          this.logado = ""
          this.logado = role
        }
        if (role === "Servidor" && !this.logadoIsAdmin && !this.logadoIsSercretaria && !this.logadoIsMembro) {
          this.logado = ""
          this.logado = role
        }
      });

      //  this.avatar()
    }).unsubscribe();


    console.log("logado", this.logado)
  }
  logout() {
    this.authService.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
  }

  counter() {
    this.counterN = this.authService.formatCounter()

  }

}

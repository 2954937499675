import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
@Component({
  selector: 'app-solicitante',
  templateUrl: './solicitante.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
})
export class SolicitanteComponent implements OnInit {

  constructor(public satFormService: SatFormService) { }

  ngOnInit() {
    this.satFormService.checkPreFormToNavigate()
    // this.satFormService.solicitanteMat = 8621

  }
}

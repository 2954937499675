import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';  // Import HttpClient for server request
import { MatSnackBar } from '@angular/material/snack-bar';  // Optional for notifications
import { AdminDatabaseService } from '../services/admin-database.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';  // Import MatDialog
import { CrudUpdateServtecComponent } from '../crud-update-servtec/crud-update-servtec.component';  // Import your update component
import { AdminService } from '../services/admin-service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-crud-view-servtec',
  templateUrl: './crud-view-servtec.component.html',
  styleUrls: ['./crud-view-servtec.component.scss']
})
export class CrudViewServtecComponent implements OnInit {
  spinner: any = false;
  editar: any = false;
  listView: any = 'all';
  orphanServtec: Array<any> = [];
  allServtec: Array<any> = [];
  filteredServtec: Array<{ pstcNmServTec: string;[key: string]: any }> = [];  // Filtered list
  searchText: string = '';  // Holds the search text
  orphanServices: Array<any> = [];
  deleteableServices: Array<any> = [];
  hoverIndex: number = -1;

  constructor(
    private adminDatabaseService: AdminDatabaseService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.getAllServicosTecnicos();
  }

  voltar() {
    this.router.navigateByUrl('/admin');
  }

  openSnackBar(msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  // Generic case-insensitive sort function
  sortServices(list: Array<any>): Array<any> {
    return list.sort((a, b) =>
      a.pstcNmServTec.toLowerCase().localeCompare(b.pstcNmServTec.toLowerCase())
    );
  }

  getAllServicosTecnicos() {
    this.spinner = true;
    this.listView = 'all';
    this.filteredServtec = [];
    this.adminDatabaseService.getAllServTec({}).subscribe(
      (response) => {
        this.allServtec = response;
        this.allServtec = this.sortServices(this.allServtec);  // Use generic sort function
        this.filteredServtec = [...this.allServtec];
        console.log('Data from service:', this.allServtec);  // Debugging - Log the data to console
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }

  getOrphanServicosTecnicos() {
    this.spinner = true;
    this.listView = 'orphan';
    this.filteredServtec = [];
    this.adminDatabaseService.getOrphanServTec({}).subscribe(
      (response) => {
        console.log('Data from service:', response);
        this.orphanServices = response;
        this.orphanServices = this.sortServices(this.orphanServices);  // Use generic sort function
        this.filteredServtec = [...this.orphanServices];
        console.log(' this.filteredServtec:', this.filteredServtec);
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }

  getDeleteableServicosTecnicos() {
    this.spinner = true;
    this.listView = 'deleteable';
    this.filteredServtec = [];
    this.adminDatabaseService.getDeleteableServTec({}).subscribe(
      (response) => {
        console.log('Data from service:', response);
        this.deleteableServices = response;
        this.deleteableServices = this.sortServices(this.deleteableServices);  // Use generic sort function
        this.filteredServtec = [...this.deleteableServices];
        console.log(' this.filteredServtec:', this.filteredServtec);
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }
  // Delete service with confirmation dialog
  deleteService(item: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: item
    });

    // Subscribe to the dialog's result and act accordingly
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // If confirmed, proceed with the deletion
        console.log("Confirmed deletion for item", item);
        this.adminDatabaseService.DeleteServTec(item.pstcDk).subscribe(
          (event: any) => {
            console.log('Serviço excluído:', event);
            this.getDeleteableServicosTecnicos();
            this.openSnackBar('Serviço excluído com sucesso.');
          },
          (error) => {
            console.error('Error deleting Servico:', error);
            this.openSnackBar('Erro.' + error.error.errTxt);
          }
        );
      } else {
        // If canceled, do nothing
        console.log("Deletion canceled");
      }
    });
  }


  // Method to filter the list based on search text
  applyFilter(): void {
    const search = this.searchText.trim().toLowerCase();
    if (search) {
      if (this.listView === 'all') {
        this.filteredServtec = this.allServtec.filter(service =>
          service.pstcNmServTec.toLowerCase().includes(search)
        );
      }
      if (this.listView === 'orphan') {
        this.filteredServtec = this.orphanServices.filter(service =>
          service.pstcNmServTec.toLowerCase().includes(search)
        );
      }
      if (this.listView === 'deleteable') {
        this.filteredServtec = this.deleteableServices.filter(service =>
          service.pstcNmServTec.toLowerCase().includes(search)
        );
      }
    } else {
      if (this.listView === 'all') {
        this.filteredServtec = [...this.allServtec];
      }
      if (this.listView === 'orphan') {
        this.filteredServtec = [...this.orphanServices];
      }
      if (this.listView === 'deleteable') {
        this.filteredServtec = [...this.deleteableServices];
      }
    }
  }

  // Clear the search field and reset the list
  clearSearch(): void {
    this.searchText = '';
    if (this.listView === 'all') {
      this.filteredServtec = [...this.allServtec];
    }
    if (this.listView === 'orphan') {
      this.filteredServtec = [...this.orphanServices];
    }
    if (this.listView === 'deleteable') {
      this.filteredServtec = [...this.deleteableServices];
    }
  }

  // Open the dialog for updating a service
  openUpdateDialog(serviceData: any, action: any): void {
    serviceData.action = action;
    const dialogRef = this.dialog.open(CrudUpdateServtecComponent, {
      width: '80%',
      data: serviceData
    });

    dialogRef.afterClosed().subscribe(reload => {
      console.log('Dialog reload:', reload);
      if (reload) {
        if (reload === true) {
          if (this.listView === 'all') {
            this.getAllServicosTecnicos();
          }
          if (this.listView === 'orphan') {
            this.getOrphanServicosTecnicos();
          }
          if (this.listView === 'deleteable') {
            this.getDeleteableServicosTecnicos();
          }
        }
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';  // Import HttpClient for server request
import { MatSnackBar } from '@angular/material/snack-bar';  // Optional for notifications
import { AdminDatabaseService } from '../services/admin-database.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';  // Import MatDialog
import { CrudUpdateServtecComponent } from '../crud-update-servtec/crud-update-servtec.component';  // Import your update component
import { AdminService } from '../services/admin-service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CrudUpdateDocumentoComponent } from '../crud-update-documento/crud-update-documento.component';

@Component({
  selector: 'app-crud-view-documento',
  templateUrl: './crud-view-documento.component.html',
  styleUrls: ['./crud-view-documento.component.scss']
})
export class CrudViewDocumentoComponent implements OnInit {
  spinner: any = false;
  editar: any = false;
  listView: any = 'all';
  allDocumentos: Array<any> = [];
  filteredDocumentos: Array<{ dostNmDocumento: string;[key: string]: any }> = [];  // Filtered list
  searchText: string = '';  // Holds the search text
  orphanDocuments: Array<any> = [];
  deleteableDocumentos: Array<any> = [];
  hoverIndex: number = -1;

  constructor(
    private adminDatabaseService: AdminDatabaseService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.getAllDocumentos();
  }

  voltar() {
    this.router.navigateByUrl('/admin');
  }

  openSnackBar(msg: any) {
    this._snackBar.open(msg, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  // Generic case-insensitive sort function
  sortServices(list: Array<any>): Array<any> {
    return list.sort((a, b) =>
      a.dostNmDocumento.toLowerCase().localeCompare(b.dostNmDocumento.toLowerCase())
    );
  }

  getAllDocumentos() {
    this.spinner = true;
    this.listView = 'all';
    this.filteredDocumentos = [];
    this.adminDatabaseService.getAllDocumento().subscribe(
      (response) => {
        this.allDocumentos = response;
        this.allDocumentos = this.sortServices(this.allDocumentos);  // Use generic sort function
        this.filteredDocumentos = [...this.allDocumentos];
        console.log('Data from service:', this.allDocumentos);  // Debugging - Log the data to console
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }

  getOrphanDocumentos() {
    this.spinner = true;
    this.listView = 'orphan';
    this.filteredDocumentos = [];
    this.adminDatabaseService.getOrphanDocumento().subscribe(
      (response) => {
        console.log('Data from service:', response);
        this.orphanDocuments = response;
        this.orphanDocuments = this.sortServices(this.orphanDocuments);  // Use generic sort function
        this.filteredDocumentos = [...this.orphanDocuments];
        console.log(' this.filteredDocumentos:', this.filteredDocumentos);
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }

  getDeleteableDocumentos() {
    this.spinner = true;
    this.listView = 'deleteable';
    this.filteredDocumentos = [];
    this.adminDatabaseService.getDeleteableDocumento().subscribe(
      (response) => {
        console.log('Data from service:', response);
        this.deleteableDocumentos = response;
        this.deleteableDocumentos = this.sortServices(this.deleteableDocumentos);  // Use generic sort function
        this.filteredDocumentos = [...this.deleteableDocumentos];
        console.log(' this.filteredDocumentos:', this.filteredDocumentos);
        this.spinner = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false;
      }
    );
  }
  // Delete service with confirmation dialog
  deleteDocumento(item: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: item
    });

    // Subscribe to the dialog's result and act accordingly
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // If confirmed, proceed with the deletion
        console.log("Confirmed deletion for item", item);
        this.adminDatabaseService.DeleteDoc(item.dostDk).subscribe(
          (event: any) => {
            console.log('Serviço excluído:', event);
            this.getDeleteableDocumentos();
            this.openSnackBar('Serviço excluído com sucesso.');
          },
          (error) => {
            console.error('Error deleting Servico:', error);
            this.openSnackBar('Erro.' + error.error.errTxt);
          }
        );
      } else {
        // If canceled, do nothing
        console.log("Deletion canceled");
      }
    });
  }


  // Method to filter the list based on search text
  applyFilter(): void {
    const search = this.searchText.trim().toLowerCase();
    if (search) {
      if (this.listView === 'all') {
        this.filteredDocumentos = this.allDocumentos.filter(service =>
          service.dostNmDocumento.toLowerCase().includes(search)
        );
      }
      if (this.listView === 'orphan') {
        this.filteredDocumentos = this.orphanDocuments.filter(service =>
          service.dostNmDocumento.toLowerCase().includes(search)
        );
      }
      if (this.listView === 'deleteable') {
        this.filteredDocumentos = this.deleteableDocumentos.filter(service =>
          service.dostNmDocumento.toLowerCase().includes(search)
        );
      }
    } else {
      if (this.listView === 'all') {
        this.filteredDocumentos = [...this.allDocumentos];
      }
      if (this.listView === 'orphan') {
        this.filteredDocumentos = [...this.orphanDocuments];
      }
      if (this.listView === 'deleteable') {
        this.filteredDocumentos = [...this.deleteableDocumentos];
      }
    }
  }

  // Clear the search field and reset the list
  clearSearch(): void {
    this.searchText = '';
    if (this.listView === 'all') {
      this.filteredDocumentos = [...this.allDocumentos];
    }
    if (this.listView === 'orphan') {
      this.filteredDocumentos = [...this.orphanDocuments];
    }
    if (this.listView === 'deleteable') {
      this.filteredDocumentos = [...this.deleteableDocumentos];
    }
  }

  // Open the dialog for updating a service
  openUpdateDialog(serviceData: any, action: any): void {
    serviceData.action = action;
    const dialogRef = this.dialog.open(CrudUpdateDocumentoComponent, {
      width: '80%',
      data: serviceData
    });

    dialogRef.afterClosed().subscribe(reload => {
      console.log('Dialog reload:', reload);
      if (reload) {
        if (reload === true) {
          if (this.listView === 'all') {
            this.getAllDocumentos();
          }
          if (this.listView === 'orphan') {
            this.getOrphanDocumentos();
          }
          if (this.listView === 'deleteable') {
            this.getDeleteableDocumentos();
          }
        }
      }
    });
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TokenService } from '@core/authentication';
import { BASE_URL } from './base-url-interceptor';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService,
    private router: Router,
    @Optional() @Inject(BASE_URL) private baseUrl?: string
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the token needs refreshing
    if (this.tokenService.valid() && this.tokenService.getRefreshToken()) {
      // Refresh the token silently
      this.tokenService.refresh().subscribe(
        (token) => {
          // Token refreshed successfully, proceed with the original request
          return next.handle(this.addTokenToRequest(request));
        },
        (error) => {
          // Token refresh failed, handle the error (e.g., redirect to login page)
          this.handleTokenRefreshError(error);
          return throwError(error);
        }
      );
    }

    // Add token to the request
    request = this.addTokenToRequest(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Unauthorized error, handle it (e.g., redirect to login page)
          this.handleUnauthorizedError(error);
        }
        return throwError(error);
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.tokenService.getBearerToken();
    if (token) {
      // Clone the request and add the token to the headers
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }

  private handleUnauthorizedError(error: HttpErrorResponse): void {
    // Handle unauthorized error (e.g., redirect to login page)
    this.router.navigateByUrl('/auth/login');
  }

  private handleTokenRefreshError(error: any): void {
    // Handle token refresh error (e.g., redirect to login page)
    this.router.navigateByUrl('/auth/login');
  }
}

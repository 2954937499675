import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Token, User } from './interface';
import { catchError } from 'rxjs/operators';
import { admin, Menu } from '@core';
import { map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import jwt_decode from "jwt-decode";
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public token_autorize: any;
  public token: any;
  baseUrl = 'gate/api/';
  baseUrlLogin = 'prodata/';

  constructor(protected http: HttpClient) { }

  public login(username: string, password: string, rememberMe = false) {
    const loginPayload = {
      username: username,
      password: password,
    };
    return this.http.post(this.baseUrlLogin + 'user/login', loginPayload, { observe: 'response' });
  }

  public getAuth(token: any): Observable<any> {
    //const login = { "username": "anderson.valgas", "password": "bx11ts" }
    this.token_autorize = jwt_decode(token);
    console.log('Token Decode',this.token_autorize)
    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Authorization', 'Bearer ' + token);
    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append("Accept", "*/*")
    // localStorage.setItem('BearerToken', token)
    var reqHeader = new HttpHeaders({
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
      "Accept": "*/*"
    });

    let body = { "application": this.token_autorize.appId, "role": this.token_autorize.roles[0] } //{"application": "GATE", "role": "Gestor"}//

    let options2 = { headers: httpHeaders };
    return this.http.post(this.baseUrlLogin + "user/authorizeRole", body, options2)
  }

  public getAvatar() {
    return this.http.get(this.baseUrlLogin + 'user/base64avatar');
  }


  getUser(): Observable<any> {
    return this.http.get<any>(this.baseUrlLogin + 'utilities/getUser').pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }



  refresh(params: Record<string, any>) {
    return this.http.post<Token>('/auth/refresh', params);
  }

  logout() {
    return of({});
  }

  me() {
    return of(admin);
  }

  menu() {
    return this.http
      .get<{ menu: Menu[] }>('assets/data/menu.json?_t=' + Date.now())
      .pipe(map(res => res.menu));
  }
}



import { Component, OnInit } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { SatDetalhesComponent } from '../../../sat-detalhes/sat-detalhes.component';
@Component({
  selector: 'app-envio',
  templateUrl: './envio.component.html',
  styleUrls: ['./envio.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(20px)' }), // Start transparent and below 20px
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' })), // Fade to full opacity and move to original position
      ]),
    ]),
    trigger('detalhes', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateX(-200px)' }), // Start transparent and outside the viewport
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })), // Fade to full opacity and move to original position
      ]),
    ]),
    trigger('fadeOut', [
      state('void', style({ opacity: 1 })),
      transition('* => void', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class EnvioComponent implements OnInit {
  spinner: boolean = true
  totalMb: number = 10

  constructor(public satFormService: SatFormService, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.sendSAT()
  }

  openDialog(sateDk: any) {
    let selectedSat: any = {
      logadoIsSolicitante: true,
      sateDk: sateDk
    }
    const dialogRef = this.dialog.open(SatDetalhesComponent, {
      autoFocus: false,
      maxHeight: '90vh',//you can adjust the value as per your view
      data: { sat: selectedSat },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.event === 'reload') {


      }

    });
  }

  sendSAT() {
    this.spinner = true;

    let totalSize = 0;
    this.satFormService.preForm.satFiles.forEach((file: any) => {
      totalSize += file.size;
    });
    // Check if total size exceeds 10MB (in bytes)
    if (totalSize > 10 * 1024 * 1024) { // Convert 10MB to bytes
      // Call your function here
      // triggerAnotherFunction();
      this.totalMb = Math.round(totalSize / 1024 / 1024)
    } else {
      this.totalMb = 10
    }




    this.satFormService.enviarSat();
    // this.changeStep('enviar');
    setTimeout(() => {
      this.spinner = false;
    }, 2000);
  }

}

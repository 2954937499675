import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';  // Import HttpClient for server request
import { MatSnackBar } from '@angular/material/snack-bar';  // Optional for notifications
import { AdminDatabaseService } from '../services/admin-database.service';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin-service';
import { SatDatabaseService } from '@shared/services/sat-database.service';


@Component({
  selector: 'app-crud-insert-servtec',
  templateUrl: './crud-insert-servtec.component.html',
  styleUrls: ['./crud-insert-servtec.component.scss']
})
export class CrudInsertServtecComponent implements OnInit {
  pastedData: string = '';  // Holds the raw pasted data
  dataList: Array<any> = [];  // Holds the parsed data as array of objects
  spinner: any = false
  allServtec: Array<any> = [];
  duplicatedServices: Array<any> = [];
  constructor(private adminDatabaseService: AdminDatabaseService, private snackBar: MatSnackBar, private router: Router,
    private satDatabaseService: SatDatabaseService,) { }

  ngOnInit(): void {
    this.getAllServicosTecnicos()
  }

  voltar() {
    this.router.navigateByUrl('/admin');
  }




  limparLista() {
    this.dataList = []
    this.duplicatedServices = []
    this.pastedData = ''
  }
  // Method to remove an item from the list
  removeItem(index: number): void {
    // Get the service name to be removed from dataList
    const serviceName = this.dataList[index].pstcNmServTec.trim().toLowerCase();

    // Remove from dataList
    this.dataList.splice(index, 1);

    // Split pastedData into rows, filter out empty rows
    const rows = this.pastedData.split('\n').filter(row => row.trim() !== '');

    // Find the index of the corresponding service in pastedData and remove it
    const rowIndex = rows.findIndex(row => row.trim().toLowerCase() === serviceName);
    if (rowIndex > -1) {
      rows.splice(rowIndex, 1); // Remove the corresponding line
    }

    // Update pastedData with the remaining rows
    this.pastedData = rows.join('\n');
  }


  onPasteData(): void {
    if (this.pastedData) {
      const replaceSpecialCharacters = (text: string): string => {
        return text
          .replace(/–/g, '-')      // Replace en dash with hyphen
          .replace(/—/g, '-')      // Replace em dash with hyphen
          .replace(/‘|’|‚|‛/g, "'") // Replace various quotes with a single quote
          .replace(/“|”|„|‟/g, '"') // Replace various double quotes with standard double quote
          .replace(/•|●/g, '-')    // Replace bullet points with hyphen (or any preferred character)
          .replace(/…/g, '...');   // Replace ellipsis with three dots
      };

      // Step 2: Split by lines, clean, replace special characters, and filter
      let rows = this.pastedData.split('\n')
        .map(row => row.trim())
        .filter(row => row !== '"')                   // Remove lines that are exactly a double quote
        .map(row => row.replace(/^"/, ''))            // Remove leading quotes from the start of any line
        .filter(row => row !== '')                    // Remove any empty lines
        .map(row => replaceSpecialCharacters(row));   // Apply special character replacement

      // Step 3: Ensure uniqueness while preserving the original case
      const uniqueData = Array.from(new Set(rows));

      // Step 4: Update the text area with cleaned, unique data
      this.pastedData = uniqueData.join('\n');  // Join by newline to preserve separate lines

      // Map the unique, cleaned data back to the format required by dataList
      this.dataList = uniqueData.map(name => ({
        "pstcDk": 0,
        "pstcDsServTec": name,  // Original name is kept
        "pstcNmServTec": name,  // Original name is kept
        "pstcInDuvida": "S",
        "pstcInInfoTec": "S"
      }));

      // After populating dataList, filter duplicates
      this.filterDuplicatedServices();
    }
  }

  filterDuplicatedServices(): void {
    // Reset duplicatedServices before filtering
    this.duplicatedServices = [];

    // Normalize the service names by trimming, converting to lowercase, and removing spaces for comparison purposes
    const existingServiceNames = new Set(
      this.allServtec.map(service => this.normalizeString(service.pstcNmServTec))
    );

    // Filter out duplicates from dataList and separate them into duplicatedServices
    this.dataList = this.dataList.filter(service => {
      const normalizedServiceName = this.normalizeString(service.pstcNmServTec);  // Normalize for comparison

      if (existingServiceNames.has(normalizedServiceName)) {
        // If the normalized service name already exists, add to duplicatedServices
        this.duplicatedServices.push(service);
        return false;  // Exclude from dataList (because it's a duplicate)
      }
      return true;  // Keep the service if it's not a duplicate
    });

    console.log('Filtered dataList (without duplicates):', this.dataList);
    console.log('Duplicated services:', this.duplicatedServices);
  }

  // Helper function to normalize string: trim, lowercase, and remove all spaces
  normalizeString(str: string): string {
    return str.replace(/\s+/g, '').toLowerCase();  // Remove spaces and convert to lowercase
  }




  getAllServicosTecnicos() {
    this.spinner = true
    this.adminDatabaseService.getAllServTec({}).subscribe(
      (response) => {
        this.allServtec = response;  // Assign the response (array) to dataList
        console.log('allServtec:', this.allServtec);  // Debugging - Log the data to console

        this.spinner = false

      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinner = false
      }

    );
  }





  // Method to send the data to the server
  createServicoTecList(action: any): void {
    let tmanDk: number = 0
    if (action === 'INSERT') {
      tmanDk = 0
    }
    if (this.dataList.length > 0) {
      console.log(this.dataList)
      this.adminDatabaseService.createServicoTecList(this.dataList, action, tmanDk).subscribe(
        (event: any) => {
          console.log('Servico gravado:', event);
          if (action === 'INSERT') {
            console.log("event", event)
            this.getAllServicosTecnicos()
            //   this.satDatabaseService.areasLoaded.next(false)
            //  this.satDatabaseService.getAreasTemas();
            this.snackBar.open('Serviço criado com sucesso.', '', { duration: 3000 });
            //    this.router.navigateByUrl('/crud-view-servtec');
            this.limparLista()
          }
        },
        (error) => {
          //   this.voltar()
          console.error('Error creating Servico:', error);
          this.snackBar.open('Erro', error.error.errTxt, { duration: 3000 });
          //this.satState = { status: 'error', error: error.message, sateDk: null };
        }
      );
    } else {
      this.snackBar.open('No data to send!', 'Close', { duration: 3000 });
    }
  }
}


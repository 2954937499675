<div class="page-container" style="max-width: 920px; text-align: justify;
text-justify: inter-word;">
  <div class="matero-page-header-inner">
    <h1 class="matero-page-title">Temas críticos </h1>

  </div>

  <div class="barra-destaque">
    <mat-icon class="material-icons-outlined"
              style="font-size: 20px; color:#1F2022;    margin-right: 5px; height: 20px;
"
              mat-list-icon>info</mat-icon>

    <div>Veja quais são os temas críticos de Julho e Agosto de 2024 na tabela no final desta
      página.</div>
  </div>


  <div class="matero-page-header-inner" style="    margin-top: 46px;">
    <h3 class="matero-page-title">O que são os “temas GATE”?</h3>
  </div>
  <p>
    O GATE possui profissionais de diversas áreas do conhecimento, os quais são divididos nos mais
    diversos núcleos. Há, ainda, a divisão interna dos temas, que correspondem aos assuntos sobre os
    quais os profissionais se especializaram. Assim, temos 29 temas dentro do GATE.
  </p>

  <div>
    <mat-accordion [togglePosition]="'before'">
      <mat-expansion-panel style="box-shadow: none; background-color: transparent;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ver todos os temas do GATE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <div class="table" style="width: 100%; margin-top: 21px;">

          <div class="row">
            <div class="cell" style="padding: 4px;">Assistência Social</div>
            <div class="cell " style="padding: 4px;">Engenharia Ambiental</div>
            <div class="cell " style="padding: 4px;">Orçamento</div>
          </div>
          <div class="row">
            <div class="cell" style="padding: 4px;">Atenção Primária</div>
            <div class="cell " style="padding: 4px;">Engenharia Civil</div>
            <div class="cell " style="padding: 4px;">Patrimônio Histórico e Cultural</div>
          </div>
          <div class="row">
            <div class="cell" style="padding: 4px;">Atenção Secundária/Terciária</div>
            <div class="cell " style="padding: 4px;">Engenharia Florestal</div>
            <div class="cell " style="padding: 4px;">Pedagogia</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Avaliação de Imóveis</div>
            <div class="cell " style="padding: 4px;">Engenharia Química</div>
            <div class="cell " style="padding: 4px;">Políticas Públicas Saúde</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Biologia</div>
            <div class="cell " style="padding: 4px;">Farmácia</div>
            <div class="cell " style="padding: 4px;">Psicologia</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Condições Higiênico-Sanitárias</div>
            <div class="cell " style="padding: 4px;">Fenômenos de Energia e Informação</div>
            <div class="cell " style="padding: 4px;">Recursos Hídricos</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Economia</div>
            <div class="cell " style="padding: 4px;">Geologia</div>
            <div class="cell " style="padding: 4px;">Resíduos Sólidos Urbanos</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Economicidade Contábil</div>
            <div class="cell " style="padding: 4px;">Geotecnia</div>
            <div class="cell " style="padding: 4px;">Saúde Mental</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Economicidade de Obras</div>
            <div class="cell " style="padding: 4px;">Medicina Legal</div>
            <div class="cell " style="padding: 4px;">Urbanismo</div>
          </div>
          <div class="row">
            <div class="cell " style="padding: 4px;">Edificações</div>
            <div class="cell " style="padding: 4px;">Mobilidade e Transportes</div>
            <div class="cell "></div>
          </div>

        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>


  <div class="matero-page-header-inner">
    <h3 class="matero-page-title" style="    margin-top: 46px;">O que são temas críticos?</h3>
  </div>
  <p>
    O <span (click)="abrirProtocGestao()" class="doc-link">
      Protocolo de Gestão nº 4/23
    </span> trouxe regras de controle do passivo do GATE, em razão da alta
    demanda existente. Com base nele, novas SATs relacionadas aos chamados “temas críticos” se
    submetem a uma regra específica.
  </p>
  <p>
    Para saber se um tema é ou não considerado crítico, o GATE estabeleceu um parâmetro: aqueles
    temas cujo tempo de espera para o início da análise da SAT, caso nenhuma outra fosse remetida ao
    GATE, supere 240 dias.
    Até que consigamos gerar, nesta página, uma visualização em tempo real dos indicadores do GATE,
    a equipe publicará bimestralmente a relação de temas críticos e o prazo (em dias) para início da
    análise de uma SAT a eles relativa.
  </p>


  <div class="matero-page-header-inner">
    <h3 class="matero-page-title" style="    margin-top: 46px;">Temas críticos - Novembro e Dezembro
      -
      2024</h3>
  </div>

  <div class="table">
    <div class="row header">
      <div class="cell left-column">Tema</div>
      <div class="cell right-column">Dias</div>
    </div>
    <div class="row">
      <div class="cell left-column">Orçamento</div>
      <div class="cell right-column">445 dias</div>
    </div>
    <!--  <div class="row">
      <div class="cell left-column">Pedagogia</div>
      <div class="cell right-column">332 dias</div>
    </div> -->
    <div class="row">
      <div class="cell left-column">Medicina Legal</div>
      <div class="cell right-column">263 dias</div>
    </div>
    <div class="row">
      <div class="cell left-column">Economicidade de Obras</div>
      <div class="cell right-column">255 dias</div>
    </div>
     <div class="row">
      <div class="cell left-column">Engenharia Civil</div>
      <div class="cell right-column">274 dias</div>
    </div>
  </div>
</div>

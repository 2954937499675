<ng-container [ngTemplateOutlet]="menuListTpl"
              [ngTemplateOutletContext]="{menuList: menu$ | async, parentRoute: [], level: 0}">
</ng-container>

<ng-template #menuListTpl let-menuList="menuList" let-parentRoute="parentRoute" let-level="level">
  <ul navAccordion class="matero-sidemenu level-{{level}}" [class.submenu]="level>0">


    <div class="counter">
      <div

           style=" color:#5D899D; font-size: 13px; display: flex; align-items: center;    margin-left: 45px;
           margin-bottom: 20px;">
        <mat-icon
                  style="font-size: 21px; width: 25px; height: 21px;"
                  aria-label="Info">task_alt</mat-icon>{{this.logado}} logado
      </div>
    </div>


    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         [routerLink]="['/sat-nova']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon class="menu-icon">post_add</mat-icon>
        <span class="menu-name">Nova SAT</span>
        <span fxFlex></span>
      </a>
    </li>

    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         [routerLink]="['/sat-lista']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon class="menu-icon">list_alt</mat-icon>
        <span class="menu-name">Lista de SAT's</span>
        <span fxFlex></span>
      </a>
    </li>


    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         [routerLink]="['/catalogo-servicos']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon
                  class="menu-icon outline material-symbols-outlined">import_contacts</mat-icon>
        <span class="menu-name">Catálogo de serviços</span>
        <span fxFlex></span>
      </a>
    </li>



    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         [routerLink]="['/criterios-prioridade']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon
                  class="menu-icon outline material-symbols-outlined">rule</mat-icon>
        <span class="menu-name">Critérios de prioridade</span>
        <span fxFlex></span>
      </a>
    </li>

    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         [routerLink]="['/temas-criticos']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon
                  class="menu-icon outline material-symbols-outlined">report</mat-icon>
        <span class="menu-name">Temas críticos</span>
        <span fxFlex></span>
      </a>
    </li>

    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         href="https://calculei.mprj.mp.br/" target="_blank" rel="noopener noreferrer"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon class="menu-icon outline material-icons-outlined">calculate_outline</mat-icon>
        <span class="menu-name">Calculei</span>
        <span fxFlex></span>
      </a>
    </li>


    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         [routerLink]="['/sat-duvidas']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon class="menu-icon">question_mark</mat-icon>
        <span class="menu-name">Dúvidas</span>
        <span fxFlex></span>
      </a>
    </li>

    <li navAccordionItem class="menu-item" routerLinkActive="active" *ngIf="this.logadoIsAdmin">
      <a class="menu-heading"
         [routerLink]="['/admin']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon class="menu-icon">settings</mat-icon>
        <span class="menu-name">Administração</span>
        <span fxFlex></span>
      </a>
    </li>

    <!-- <li navAccordionItem class="menu-item" routerLinkActive="active" *ngIf="this.logadoIsAdmin">
      <a>
        <mat-icon
                  class="menu-icon outline material-symbols-outlined">edit</mat-icon>
        <span class="menu-name">Administração</span>
      </a>
      <ul>
        <li>
          <a class="menu-heading"
         [routerLink]="['/admin']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon
                  class="menu-icon outline material-symbols-outlined">settings</mat-icon>
        <span class="menu-name">Serviços</span>
          </a>
        </li>
      </ul> -->
    <!--<a class="menu-heading"
         [routerLink]="['/admin']"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon
                  class="menu-icon outline material-symbols-outlined">edit</mat-icon>
        <span class="menu-name">Admin</span>
        <span fxFlex></span>
      </a>
    </li>-->

    <li navAccordionItem class="menu-item" routerLinkActive="active">
      <a class="menu-heading"
         (click)="logout()"
         matRipple [matRippleDisabled]="!ripple">
        <mat-icon class="menu-icon">logout</mat-icon>
        <span class="menu-name">Logout</span>

        <span fxFlex></span>
      </a>
    </li>

  </ul>

</ng-template>

<ng-template #linkTypeTpl let-item="item" let-level="level">
  <mat-icon class="menu-icon" *ngIf="level===0">{{item.icon}}</mat-icon>
  <span class="menu-name">{{item.name }}</span>
  <span class="menu-label bg-{{item.label.color}}" *ngIf="item.label">
    {{item.label.value}}
  </span>
  <span fxFlex></span>
  <span class="menu-badge bg-{{item.badge.color}}" *ngIf="item.badge">
    {{item.badge.value}}
  </span>
  <mat-icon class="menu-caret" *ngIf="item.type!=='link'">
    {{item.type==='sub' ? 'arrow_drop_down' : 'launch'}}
  </mat-icon>
</ng-template>
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  NgZone,
  Input
} from '@angular/core';
import { SettingsService } from '@core';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { DashboardService } from './dashboard.srevice';
import { SatFormService } from '../../services/sat-form.service';
import { AuthService } from '@core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrModule, ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sat-sat-nova',
  templateUrl: './sat-nova.component.html',
  styleUrls: ['./sat-nova.component.scss'],

  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(20px)' }), // Start transparent and below 20px
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' })), // Fade to full opacity and move to original position
      ]),
    ]),
    trigger('detalhes', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateX(-200px)' }), // Start transparent and outside the viewport
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })), // Fade to full opacity and move to original position
      ]),
    ]),
    trigger('fadeOut', [
      state('void', style({ opacity: 1 })),
      transition('* => void', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],

  providers: [DashboardService],
})
export class SatSatNovaComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  @Input() isEdicao: boolean = false;
  isRoot = true;
  showTemplate = true;
  inTransition = false;
  stepToLoad: any = 'solicitante';
  stepsLoaded: any = [];
  spinner: boolean = false;
  usuarioSCA: any = null;

  // solicitanteMat: any = null;

  preForm: any = null;

  membrosAtivos: any = [];

  procedimentosUsuSolicit: any = [];

  json: any;

  detalhesHeight = '0px'; // Initial height is 0



  // hard code to some form option



  /*  usuarioLogadoFake = {
     name: 'Charles Patrick Kaufmann Robbs',
     matricula: '8621',
   }; */

  showError: boolean = false;
  private unsubscriber: Subject<void> = new Subject<void>();

  stats = this.dashboardSrv.getStats();
  notifySubscription!: Subscription;
  private beforeUnloadListener: EventListener;
  constructor(
    private http: HttpClient,
    private dashboardSrv: DashboardService,
    private settings: SettingsService,
    public satFormService: SatFormService,
    public authService: AuthService,
    private toastr: ToastrService
  ) {
    this.beforeUnloadListener = (event: BeforeUnloadEvent) => {
      // Cancel the event to prevent the browser from navigating away
      event.preventDefault();
      // Optionally, you can display a custom message to the user
      const message = 'Deseja mesmo deixar esta página.  As alterações não serão salvas.';
      event.returnValue = message;
      return message;
    };
    // Add the event listener to the window
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  }

  ngOnInit(): void {
    this.notifySubscription = this.settings.notify.subscribe(res => {
      //  console.log(res);
    });
    this.showTemplate = true;
    if (this.satFormService.isEdicao === false) {
      console.log('this.isEdicao', this.isEdicao);

      this.satFormService.resetPreForm('all');
    }

    this.getUser();

    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
        this.showError = true;
        //     console.log("popstate")
        this.toastr.warning('Ao sair desta página você perderá os dados cadastrados até o momento.  Utilize o menu lateral ou a navegação da SAT à direita.', 'Atenção!', { positionClass: 'toast-bottom-center' });
      });


  }

  ngOnDestroy(): void {
    console.log('this.isEdicao', this.isEdicao);

    this.satFormService.resetPreForm('all');
    this.unsubscriber.next();
    this.unsubscriber.complete();
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }


  toggleDetalhes() {
    this.detalhesHeight = this.detalhesHeight === '0px' ? '100%' : '0px';
    //  console.log(this.detalhesHeight)
  }

  getCurrentStepIndex(): number {
    // Find the index of the current step based on the step name
    const currentStep = this.satFormService.stepsSequence.find(
      step => step.step_NM === this.satFormService.stepToLoad
    );

    if (currentStep) {
      return this.satFormService.stepsSequence.indexOf(currentStep);
    }

    return 0; // Default to the first step if the step name is not found
  }

  onStepSelectionChange(event: any): void {
    if (event && event.selectedStep) {
      this.satFormService.navigateToStepByStepName(event.selectedStep.label);
    }
  }

  getUser() {
    this.authService.user().subscribe(user => {
      this.satFormService.logadoIsMembro = false
      user.tok.roles.forEach((role: any) => {
        if (role === "Membro") {
          this.satFormService.logadoIsMembro = true
        }
      });


      setTimeout(() => {

        this.satFormService.matriculaSCA = user.tok.matricula;
        if (this.satFormService.logadoIsMembro === true) {
          this.satFormService.getMembroByMatricula(this.satFormService.matriculaSCA)
        }
        this.stepToLoad = 'solicitante';
        this.stepsLoaded.push('solicitante');
      }, 200);
    }).unsubscribe();
  }

  // NAVIGATION THROUGH STEPS *******************************



  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // FILTROS E BUSCAS EM TABELAS  ***************************************

  getMembroByMatricula(matricula: string) {
    if (!matricula) {
      // If the matricula is empty, reset the filtered array
      this.preForm.solicitanteObj = null;
      return;
    }
    matricula = '0000000' + matricula;
    matricula = matricula.substring(matricula.length - 8);
    this.spinner = true;
    let membro = this.membrosAtivos.filter((membro: any) => membro.MATRICULA === matricula)[0];
    if (membro !== undefined && membro !== null) {
      this.preForm.solicitanteObj = {
        nome: membro.NOME,
        matricula: membro.MATRICULA,
      };
    } else {
      let data = {
        title: 'Membro não encontrado',
        content: 'Não foi possível encontrar o Membro com o número de matrícula: ' + matricula,
        subcontent: ' Feche esta caixa, confira o número da matrícula e tente novamente.',
      };
    }
    this.spinner = false;
  }

  navigateBack() { }

  goHome() {
    window.location.href = '/sat';
  }

  changeStep(teste: any) { }



}

import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { AuthService } from '@core/authentication';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  isSubmitting = false;
  hide = true;

  loginForm = this.fb.nonNullable.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    rememberMe: [false],
  });
  private userSubscription: any = Subscription;
  constructor(private fb: FormBuilder, private router: Router, public auth: AuthService) { }
  ngOnDestroy(): void {
    if (this.userSubscription) {
      //   this.userSubscription.unsubscribe();
    }
  }
  get username() {
    return this.loginForm.get('username')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  get rememberMe() {
    return this.loginForm.get('rememberMe')!;
  }

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }

  login() {
    let error = false
    //this.auth.user$.next({})
    this.isSubmitting = true;
    this.auth.login(this.username.value, this.password.value, this.rememberMe.value);
    this.auth.user$.subscribe((usr: any) => {
      //  console.log(usr)
      if (usr.errorLogin === true) {
        this.isSubmitting = false;

      }
    })
    setTimeout(() => {
      this.isSubmitting = false;

    }, 15000);
  }
}

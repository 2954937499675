<div style="width: 560px; padding: 24px;">
  <div class="sticky">
    <!-- <div class="voltar">
      voltar
    </div> -->
    <div class="title-bar" *ngIf="sat.sateNrSat !== undefined || sat.sateInAprovada === 'N' "
         [style.background-color]="sat.sateInAprovada === 'S' ? '#5D899D' : '#AA4139'">
      <span style="margin-right: 20px;">

        {{sat.sateInAprovada === 'S' ? 'SAT:' : 'Aguardando aprovação'}} <span
              *ngIf="sat.sateInAprovada === 'S'"> {{sat?.sateNrSat}}</span>
      </span>
      <strong>
        <span style="font-size: 18px">
          Upload de novos arquivos
        </span>
      </strong>
    </div>




  </div>
  <div [class.disabled]="spinner===true">

    <mat-card *ngIf="sat.satePssoDk === 1 || sat.satePssoDk === 2     ">
      <h4 style="
    font-size: 15px;
">Arquivos relacionados aos serviços selecionados para esta SAT</h4>
      <p style="
    font-size: 15px;
"><strong> Documentos obrigatórios</strong>

      </p>



      <p style="
    font-size: 15px;
"><strong> Documentos Adicionais</strong>

      </p>
      <div class="documentos">

      </div>
    </mat-card>


    <mat-card *ngIf="sat.sateInManifTecAnterior=== 'N'">
      <h4 style="
    font-size: 15px;
">Documentos que justifiquem a análise do GATE
        diante da ausência de manifestação técnica prévia do órgão responsável </h4>

    </mat-card>

    <mat-card *ngIf="sat.sateInManifTecAnterior=== 'S'">
      <h4 style="
    font-size: 15px;
">Manifestação técnica ou laudo pericial a ser complementado</h4>

    </mat-card>



    <mat-card *ngIf="sat.sateInPrioridade === 'S'">
      <h4 style="
    font-size: 15px;
">Documentos relacionados ao atendimento prioritário</h4>


    </mat-card>

  </div>



  <div style=" padding: 12px; margin-bottom: 50px;">

    <button
            [disabled]="spinner == true"
            mat-flat-button
            color="accent"
            style="flex-grow: 1; margin-left: 15px;">
      Enviar arquivos
    </button>




  </div>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { SatFormService } from '../../services/sat-form.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BREAKPOINT } from '@angular/flex-layout';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from "@angular/cdk/collections";
import { SatDatabaseService } from '../../../shared/services/sat-database.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { AdminDatabaseService } from '../services/admin-database.service';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-servicos-tecnicos',
  templateUrl: './servicos-tecnicos.component.html',
  styleUrls: ['./servicos-tecnicos.component.scss']
})
export class ServicosTecnicosComponent implements OnInit {
  /*
  this.myGroup = new FormGroup({
       firstName: new FormControl()
   });

  */
  public formCadastro = new FormGroup({
    FcAddServico: new FormControl(""),
    FcDsServico: new FormControl(""),
    FcNmServico: new FormControl(""),
    FcInInfotec: new FormControl(""),
    FcInduvida: new FormControl(""),
    FcCheckServico: new FormControl(""),

  });
  areas: any = [];
  filteredAreas: any = [];
  searchTema = '';
  idsIntegraEditar: any = []
  isEdicao = false
  isDelete = false
  isInsert = false
  spinner = true
  selection = new SelectionModel<Element>(true, []);
  lastRandomNumber: any;
  baseURL = "prodata/"
  uploadAll = false
  preServico: any = {
    servico: "",
    DK_PARA_GRAVAR: "",
    tmsDk: "",
    docsObrigatorios: [],
    docsDesejaveis: []
  }
  action = ''
  AreaPanelOpenState = false;
  areasArr: any = [];
  tmsDkArray: any = []
  /*
   preServico: any = {
    servico: "",
    DK_PARA_GRAVAR: "",
    tmsDk: "",
    docsObrigatorios: [],
    docsDesejaveis: []
  }
  */
  Servtec: any = {
    pstcDk: 0,
    pstcDsServTec: "",
    pstcNmServTec: "",
    pstcInDuvida: false,
    pstcInInfoTec: false
  }
  temaID = 0
  constructor(
    private satDatabaseService: SatDatabaseService,
    private adminDatabaseService: AdminDatabaseService,
    public satFormService: SatFormService,
    public dialog: MatDialog,
    private http: HttpClient
  ) { }

  ngOnInit(): void {

    this.tmsDkArray = this.satFormService.tmsDkArray
    this.getTemas()
  }


  getTemas() {

    console.log("this.tmsDkArray", this.tmsDkArray)
    if (this.areasArr.length === 0) {
      /*  this.satDatabaseService.getAreasTemas().pipe(
         //take(1)  // Automatically unsubscribes after one emission
       ).subscribe((json: any) => {
         this.areasArr = json.AreaServTec;
         console.log("carregou", json);
         this.areas = json.AreaServTec
         if (this.filteredAreas.length === 0) {
           this.filteredAreas = this.areas;
 
         }
         if (this.areas.length > 0) {
           this.spinner = false;
         }
         //this.spinner = false;
         //  this.temasServicosLoaded.next(true);
         //this.selectedServico();
       }); */
    }


  }

  filterServicos(value: string, tema: any): void {
    if (tema['servTecOriginal'] === undefined) {
      tema['servTecOriginal'] = tema.servTec
    } else {
      tema.servTec = tema['servTecOriginal']
    }
    const filterValue = value.toLowerCase();
    tema.servTec = tema.servTec.filter((serv: any) =>
      this.removeDiacritics(serv[0].pstcNmServTec.toLowerCase()).includes(filterValue)
    );
  }

  removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  changeStep(str: string) {
    if (str === "Editar") {
      this.isEdicao = true
      this.isInsert = false
      this.isDelete = false
    }
    if (str === "Incluir") {
      this.isEdicao = false
      this.isInsert = true
      this.isDelete = false
    }
    if (str === "Excluir") {
      this.isEdicao = false
      this.isInsert = false
      this.isDelete = true
    }
    if (str === "Salvar") {
      let sss = this.formCadastro.get('FcAddServico')?.value
      console.log('XXXXXX', sss)
    }

  }




  checkServicoSelected(serviLista: any) {
    console.log("serviLista", serviLista)
    for (const area of this.satFormService.preForm.areasArr) {
      for (const tema of area.temas) {
        for (const servico of tema.servicos) {
          if (servico.tmsDk === serviLista.tmsDk) {
            return true
          }
        }
      }
    }
    return false
  }
  setTema(data: any) {
    this.temaID = data.tmanDk;
    console.log('TEMA', data)

  }
  selectedServico(event: any) {
    console.log("comecou------------", event)
    console.log("TEMA ID", this.temaID)
    //console.log ("xxx", this.formCadastro.get('FcCheckServico')?.value)

    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let pushArea
    /*let preServico: any = {
      servico: "",
      DK_PARA_GRAVAR: "",
      tmsDk: "",
      docsObrigatorios: [],
      docsDesejaveis: []
    }
    */

    for (const area of this.areasArr) {
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: []
        }


        for (const servico of tema[0].servTec) {
          if (servico[0].pstcNmServTec == event) {

            this.preServico.pstcDk = servico[0].pstcDk,
              this.preServico.servico = servico[0].pstcNmServTec,
              this.preServico.DK_PARA_GRAVAR = servico[0].tmsDk,
              this.preServico.tmsDk = this.temaID
            this.preServico.pstcInDuvida = servico[0].pstcInDuvida,
              this.preServico.pstcInInfoTec = servico[0].pstcInInfoTec,
              this.preServico.docsObrigatorios = [],
              this.preServico.docsDesejaveis = []
            if (this.isEdicao) {
              this.formCadastro.controls.FcNmServico.setValue(servico[0].pstcNmServTec)
              this.formCadastro.controls.FcDsServico.setValue(servico[0].pstcDsServTec)
              this.formCadastro.controls.FcInInfotec.setValue(servico[0].pstcInInfoTec)
              this.formCadastro.controls.FcInduvida.setValue(servico[0].pstcInDuvida)
            }
          }

          for (const documento of servico[0].doc) {
            if (documento.dtstInObrigatorio === "S") {
              this.preServico.docsObrigatorios.push(documento)
              //servico['docObrigatorios'].push(documento);
            }
            if (documento.dtstInObrigatorio === "N") {
              this.preServico.docsDesejaveis.push(documento)
              //servico['docDesejaveis'].push(documento);
            }
          }
          //servico['docObrigatorios'] = []
          //servico['docDesejaveis'] = []

          /*
          let isInEditar = false
          for (const documento of preServico.servico[0].doc) {
            //console.log("DOC", servico[0].doc)
            if (this.isEdicao !== true) {
              documento['idsArr'] = ['']
            } else {
              let idsArrFromEditar: any = []
              for (const idIntegra of this.idsIntegraEditar) {
                if (documento.dtstDk === idIntegra.dssaDtstDk) {
                  //   console.log("igual obrgatorio")
                  idsArrFromEditar.push(idIntegra.dssaIdDocIntegra)
                }
              }

              if (idsArrFromEditar.length > 0) {
                documento['idsArr'] = idsArrFromEditar
              } else {
                documento['idsArr'] = ['']
              }


            }
           // console.log("DOC", documento)
            if (documento.dtstInObrigatorio === "S") {
              preServico.docsObrigatorios.push(documento)
              preServico.servico['docObrigatorios'].push(documento);
            }
            if (documento.dtstInObrigatorio === "N") {
              preServico.docsDesejaveis.push(documento)
              preServico.servico['docDesejaveis'].push(documento);
            }
          }
          */

          preTema.servicos.push(this.preServico)
        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

        if (pushArea === true) {
          areaTotal.push(preArea)
        }

      }


    }


    console.log('preServico', this.preServico)


  }



  trackByArea(index: number, area: any): any {
    return area.id;  // Ensure each area has a unique identifier
  }

  trackByTema(index: number, tema: any): any {
    this.temaID = tema.id
    return tema.id;  // Ensure each tema has a unique identifier

  }

  trackByServico(index: number, servico: any): any {
    return servico.id;  // Ensure each servico has a unique identifier
  }

  SalvarServTec() {
    this.Servtec.pstcDsServTec = this.formCadastro.get('FcDsServico')?.value
    this.Servtec.pstcNmServTec = this.formCadastro.get('FcNmServico')?.value
    this.Servtec.pstcInDuvida = this.formCadastro.get('FcInduvida')?.value
    this.Servtec.pstcInInfoTec = this.formCadastro.get('FcInInfotec')?.value
    this.formCadastro.get('FcDsServico')?.value
    this.formCadastro.get('FcNmServico')?.value
    this.formCadastro.get('FcInInfotec')?.value
    //console.log('serv',this.formCadastro.get('FcServTec')?.value)
    if (this.Servtec.pstcInDuvida) {
      this.Servtec.pstcInDuvida = "S"
    }
    if (!this.Servtec.pstcInDuvida) {
      this.Servtec.pstcInDuvida = "N"
    }
    if (this.Servtec.pstcInInfoTec) {
      this.Servtec.pstcInInfoTec = "S"
    }
    if (!this.Servtec.pstcInInfoTec) {
      this.Servtec.pstcInInfoTec = "N"
    }
    if (this.preServico.tmsDk != undefined || this.preServico.tmsDk != "") {
      this.Servtec.tmanDk = this.preServico.tmsDk
    }
    this.Servtec.pstcDk = this.preServico.pstcDk;


    if (this.isEdicao) {
      this.action = 'UPDATE'
    }
    if (this.isInsert) {
      this.action = 'INSERT'
    }
    if (this.isDelete) {
      this.action = 'DELETE'
      this.Servtec.pstcDsServTec = this.preServico.pstcDsServTec
      this.Servtec.pstcNmServTec = this.preServico.pstcNmServTec
    }
    console.log('sss', this.Servtec)
    this.adminDatabaseService.createServico(this.Servtec, this.action).subscribe(
      (event: any) => {
        console.log('Servico gravado:', event);
        window.location.reload()
      },
      (error) => {
        // Handle error responses here
        console.error('Error creating Servico:', error);
        //this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );

  }
  /*

  createServico(data: any): Observable<HttpEvent<any>>{

    return this.http.post<any>(this.satDatabaseService.baseURL + 'admin/CreateServicoTec?TMAN_DK=' +data.tmanDk, data, {
      reportProgress: true, // Enable progress events
      observe: 'events' // Receive all types of events
    });
    console.log("base url", this.baseURL )
  }
  */

}
